/* eslint-disable no-unused-vars */
import { useContext, useEffect, useRef, useState } from "react";
import GlobalContext from "../../context/GlobalContext";
import { FunctionFactory, Model, CustomWidgetCollection } from "survey-core";
import { Survey } from "survey-react-ui";
import RefreshBarComponent from "../Etablissements/CreateEpf/RefreshBarComponent";
import mime from "mime";
import "survey-core/defaultV2.min.css";
import { Button, notification, Space, Spin } from "antd";
import { SaveOutlined, UnorderedListOutlined } from "@ant-design/icons";
import classes from "../Etablissements/CreateEpf/CreateEpf.module.css";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
// import "survey-core/i18n/french";
import { checkCitizenStatusByCinDn } from "../../api/extern";
import dayjs from "dayjs";
import axios from "axios";
import {
  addAffaire,
  getSchema,
  getTrainerByNationalityAndIdentity,
  saveAffaire,
  updateAffaireAnnexe,
  updateInfoProfile,
} from "../../api/geoprod";

import {
  onComplete,
  onCurrentPageChanged,
  onCurrentPageChanging,
  onDynamicPanelItemValueChanged,
  onErrorCustomText,
  onShowingPreview,
  onUploadFiles,
  onValidateQuestion,
  onValueChanging,
} from "../Etablissements/CreateEpf/CreateEpfComponents/surveyEvents";
import { themeJson } from "../Etablissements/CreateEpf/CreateEpfComponents/creationJson";
import DeleteBrouillonModal from "../Etablissements/CreateEpf/Modals/DeleteBrouillonModal";
import DatePikckerComponent from "../Etablissements/CreateEpf/DatePIkcer";

import { settings } from "survey-core";

import {
  fetchCodePostalList,
  fetchDelegationList,
  fetchSpetialityList,
  fetchSubSectorList,
  getCommuneByCodeCommune,
} from "../../context/FetchLists";
import SearchInputComponent from "../Etablissements/CreateEpf/SearchInputComponent";
import ButtonComponent from "../Etablissements/CreateEpf/ButtonComponent";
import axiosInstance from "../../utils/axios";

settings.customIcons["icon-clear"] = "icon-delete";
settings.customIcons["icon-choosefile"] = "icon-modernbooleancheckchecked";

import { useAnnexeLoader } from "../../context/AnnexeLoaderContext";
import surveyJson from "./survey.json";

let survey = new Model();
survey.applyTheme(themeJson);

const AnnexesForm = () => {
  const [openModal, setOpenModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const { isLoadingAnnexe, setIsLoading } = useAnnexeLoader();
  const {
    secteurData,
    gouvernoratList,
    nationalityList,
    FormesJuridiquesList,
    listAllDelegations
  } = useContext(GlobalContext);

  const { t } = useTranslation();

  const expressionsMap = {
    legal_entity_reservation_number_commercial: {
      expression: "{legal_entity_reservation_number_commercial} <> ''",
      apiCall: (survey) =>
        getDemandeReservDenomination(
          survey,
          "legal_entity_reservation_number_commercial"
        ),
      maxLength: 9,
      regex: /^\d{9}$/,
      message: t(
        "Le numéro RNE doit être au format 9 chiffres(ex: 111111111)."
      ),
    },
    legal_entity_reservation_number_official: {
      expression: "{legal_entity_reservation_number_official} <> ''",
      apiCall: (survey) =>
        getDemandeReservDenomination(
          survey,
          "legal_entity_reservation_number_official"
        ),
      maxLength: 9,
      regex: /^\d{9}$/,
      message: t(
        "Le numéro RNE doit être au format 9 chiffres(ex: 111111111)."
      ),
    },
    establishment_data_reservation_number_commercial: {
      expression:
        "{establishment_data_reservation_number_commercial} <> '' and {inverstor_type} <> 'شخص معنوي'",
      apiCall: (survey) =>
        getDemandeReservDenomination(
          survey,
          "establishment_data_reservation_number_commercial"
        ),
      maxLength: 9,
      regex: /^\d{9}$/,
      message: t(
        "Le numéro RNE doit être au format 9 chiffres(ex: 111111111)."
      ),
    },
    establishment_data_tax_number: {
      expression: "{establishment_data_tax_number} <> ''",
      apiCall: (survey) =>
        getExtraitRegistreEntrepriseParId(
          survey,
          "establishment_data_tax_number"
        ),
      regex: /^[0-9]{7}[A-Z]$/,
      maxLength: 8,
      message: t(
        "Le numéro RNE doit être au format 7 chiffres suivis d'une lettre majuscule (ex: 1234567A)."
      ),
    },
    legal_entity_tax_number: {
      expression: "{legal_entity_tax_number} <> ''",
      apiCall: (survey) =>
        getExtraitRegistreEntrepriseParId(survey, "legal_entity_tax_number"),
      regex: /^[0-9]{7}[A-Z]$/,
      maxLength: 8,
      message: t(
        "Le numéro RNE doit être au format 7 chiffres suivis d'une lettre majuscule (ex: 1234567A)."
      ),
    },
    establishment_data_reservation_number: {
      expression:
        "{establishment_data_reservation_number} <> '' and {inverstor_type} <> 'شخص معنوي'",
      apiCall: (survey) =>
        getDemandeReservDenomination(
          survey,
          "establishment_data_reservation_number"
        ),
      maxLength: 9,
      regex: /^\d{9}$/,
      message: t(
        "Le numéro RNE doit être au format 9 chiffres(ex: 111111111)."
      ),
    },

    trainer_data_cin_number: {
      apiCall: (survey) => getTrainerWithCin(survey),
      maxLength: 8,
      regex: /^\d{8}$/,
      message: t("Le numéro cin doit être au format 8 chiffres(ex: 11111111)."),
    },
    trainer_data_passport: {
      apiCall: (survey) => getTrainerWithCin(survey),
    },
  };

  const ref = useRef();

  const handleFinish = async () => {
    try {
      const payload = {
        additional_form: "{}",
        json_prod: { ...survey.data },
      };

      await saveAffaire(payload, "soumission", setIsLoading, "annexe");
      const payloadUpdateProfil = {
        adresse: {
          geom: "",
        },
        user: {
          acc_affaire: [],
          acc_opportunite: [],
          acces_avenant: 2,
          acces_communiques: 2,
          acces_courrier_entrant: 0,
          acces_distrib: 0,
          acces_edit_maileva: 2,
          acces_ett: true,
          acces_fusion: 2,
          acces_maileva: 2,
          acces_qualite: 2,
          acces_reclamation_ticket: 1,
          acces_signature: 0,
          act_grp_communication: 2,
          act_grp_negociateur: 2,
          act_grp_pubpostage: 2,
          act_grp_statut: 2,
          act_grp_tags: 2,
          active: 1,
          admin_restreint: 0,
          admin_tpv: 2,
          adresse: survey.data.investor_residence_address,
          adresse_auto: "*",
          api_key: "",
          assistant: 0,
          beta: 0,
          birthplace: survey.data.investor_birth_place,
          bloc_env: 0,
          cin:
            survey.data.investor_nationality === "تونسية"
              ? survey.data.investor_cin
              : "",
          civilite: survey.data.investor_civility,
          code: null,
          comparateur: 0,
          date_integration: null,
          date_naiss_user: dayjs(survey.data.investor_birth_date).format(
            "YYYY-MM-DD"
          ),
          delegation: survey.data.investor_residence_delegation,
          ecrit_comp_aff: 0,
          ecriture_transaction_financiere_client: 2,
          ecriture_transaction_financiere_distribution: 2,
          entreprise_id: import.meta.env.VITE_APP_ID_Entreprise_INVESTISSEUR,
          espace_distrib_access: {
            acces_info_spec: 2,
            changement_statut: 2,
          },
          delivrance_date:
            survey.data.investor_nationality === "تونسية"
              ? dayjs(survey.data.investor_date_delivrance).format("DD/MM/YYYY")
              : "",
          expiration_passeport: "",
          execution_tpv: 2,
          exp_aff: null,
          exp_maileva: 2,
          exp_opp: null,
          export_aff_det: 0,
          export_maileva_det: 2,
          export_opp_det: 0,
          fiche_dec: 0,
          filtre: 0,
          first_login: null,
          goverment: survey.data.investor_residence_governorate,
          groupe_commission: null,
          groupe_user: [],
          groupe_user_alert: [],
          groupe_user_alert_nom: [],
          heure_debut: "0:00:00",
          heure_fin: null,
          hist: 2,
          hist_comm: 2,
          id: 1002234,
          identite_reelle: null,
          lect_comp_aff: 0,
          lecture_tpv: 2,
          lecture_transaction_financiere_client: 2,
          lecture_transaction_financiere_distribution: 2,
          limit_listing_aff: "100",
          limit_listing_maileva: "2",
          limit_listing_opp: "100",
          location: null,
          mail: survey.data.investor_email,
          manager: null,
          manager_de: [],
          manager_de_nom: [],
          manager_list: [],
          manager_nom: [],
          matricule_rh: null,
          mobile: survey.data.investor_phone_number,
          autoriser_dossier: "",
          nationality: survey.data.investor_nationality,
          nom: survey.data.investor_last_name_fr,
          lieu_naissance: survey.data?.investor_birth_place,
          nom_ar: survey.data.investor_last_name_ar,
          nom_groupe_commission: null,
          nom_organisme: "",
          nom_producteur: null,
          nom_role: "",
          nom_zone: null,
          num_interne: null,
          orias_producteur: null,
          passport:
            survey.data.investor_nationality !== "تونسية"
              ? survey.data.investor_passport
              : "",
          password: "",
          photo: null,
          pointage: 0,
          prenom: survey.data.investor_first_name_fr,
          prenom_ar: survey.data.investor_first_name_ar,
          prenom_producteur: null,
          proprietaire: true,
          qualite_producteur: null,
          role: "",
          role_auto: "[]",
          role_auto_nom: [],
          samedi_off: 0,
          signature: null,
          skype: null,
          ssl_mail_data: 0,
          super_admin: true,
          tab_code: [],
          tarificateur: 2,
          tel2: null,
          tel_urgence: null,
          telephone: survey.data.investor_phone_number,
          transaction_financiere: 2,
          type_user: "c81e728d9d4c2f636f067f89cc14862c",
          user_groupe_nom: [],
          user_pwd_data: null,
          validate_paiement: 2,
          domaines_specialite: "",
          code_postal: survey.data.investor_residence_postal_code,
          niveau_scolaire: "",
          besoins_specifiques: "",
          zone: null,
        },
        ville: {},
      };
      const user = JSON.parse(sessionStorage.getItem("-x-token-user"));
      await updateInfoProfile(payloadUpdateProfil, user.id_user);
      notification.success({
        message: "Succès",
        description: t(
          "Votre demande de création d'une nouvelle annexe a été soumise avec succès. Vous recevrez un email vous informant des prochaines étapes à suivre."
        ),
        duration: 0,
      });

      sessionStorage.removeItem("id_affaire_annexe");
      survey.clear();
      navigate("/demandes");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      notification.error({
        message: "Erreur",
        description: "Une erreur est survenue lors de l'enregistrement",
        duration: 0,
      });
    }
  };

  const params = useLocation();

  const searchParams = new URLSearchParams(params.search);
  const id_affaire = searchParams.get("affaire_id");

  const handleClickOutside = async (event) => {
    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      !params?.state?.id_affaire
    ) {
      const payload = {
        current_step: 0,
        json_prod: { ...survey.data },
      };

      sessionStorage.setItem("payload", JSON.stringify(payload));
    }
  };

  const navigate = useNavigate();

  const mergeSurveyData = async () => {
    const id_affaire_annexe = params.state?.id_affaire
      ? params.state?.id_affaire
      : sessionStorage.getItem("id_affaire_annexe");
    if (id_affaire_annexe) {
      const data = await getSchema(id_affaire_annexe);
      if (data) {
        const currentData = {
          investor_passport: survey.getValue("investor_passport"),
          investor_first_name_ar: survey.getValue("investor_first_name_ar"),
          investor_last_name_ar: survey.getValue("investor_last_name_ar"),
          investor_first_name_fr: survey.getValue("investor_first_name_fr"),
          investor_last_name_fr: survey.getValue("investor_last_name_fr"),
          ...survey.data,
          ...data?.response?.data,
        };

        data?.response?.key_urls?.forEach((item) => {
          const fileType = mime.getType(item.name_doc);
          currentData[item.key] = [
            {
              content: item.url,
              name: item.name_doc,
              type: fileType,
            },
          ];
        });
        survey.mergeData(currentData);
        survey.data = currentData;
        survey.render();

        populateDelegationOnLoad();
        displayQuestionsFunction();
      }
    }

    survey.getAllQuestions().forEach((question) => {
      if (question.name.toLowerCase().includes("_nationality")) {
        question.choices = nationalityList;
      }

      if (question.name === "legal_entity_legal_form") {
        question.choices = FormesJuridiquesList;
      }

      if (question.name === "training_nature_data") {
        question.panels.forEach((panel) => {
          panel.questions.forEach((question) => {
            if (question.name === "training_type_data_sector") {
              question.choices = secteurData;
            }
          });
        });
      }

      if (question.name === "trainer_data") {
        question.panels.forEach((panel) => {
          panel.questions.forEach((question) => {
            if (question.name.toLowerCase().includes("_nationality")) {
              question.choices = nationalityList;
            }
            if (question.name.toLowerCase().includes("_governorate")) {
              question.choices = gouvernoratList;
            }
          });
        });
      }

      if (question.name === "management_data") {
        question.panels.forEach((panel) => {
          panel.questions.forEach((question) => {
            if (question.name.toLowerCase().includes("_governorate")) {
              question.choices = gouvernoratList;
            }

            if (question.name.toLowerCase().includes("_nationality")) {
              question.choices = nationalityList;
            }
          });
        });
      }
      if (question.name.toLowerCase().includes("_governorate")) {
        question.choices = gouvernoratList;
      }
    });
  };

  const setFileValues = (value) => {
    const fileType = mime.getType(value.name);
    const file = {
      content: value.content,
      name: value.name,
      type: fileType,
    };
    return file;
  };

  useEffect(() => {
    if (surveyJson !== null) {
      const user = JSON.parse(sessionStorage.getItem("-x-token-user"));

      if (params.state?.id_affaire) {
        sessionStorage.setItem("consulter", true);
        survey.getAllQuestions().map((question) => {
          question.readOnly = true;
          if (question.name.toLocaleLowerCase().includes("_nationality")) {
            question.choices = nationalityList;
          }
        });

        const trainer_data = survey.getQuestionByName("trainer_data");
        trainer_data?.panels.map((panel) => {
          panel.questions.map((question) => {
            if (question.name === "trainer_data_cin_number") {
              question.readOnly = true;
            }
            if (question.name === "trainer_data_nationality") {
              question.choices = nationalityList;
            }
          });
        });

        const management_data = survey.getQuestionByName("management_data");
        management_data?.panels.map((panel) => {
          panel.questions.map((question) => {
            // if (question.name === "management_data_staff_birth_date") {
            question.readOnly = true;
            if (question.name === "management_data_staff_nationality") {
              question.choices = nationalityList;
            }
            // }
          });
        });

        survey.showCompleteButton = false;
        survey.editText = "Consulter - الإطلاع";
      } else {
        sessionStorage.setItem("consulter", false);
      }

      async function fetchData() {
        setIsLoading(true);
        let epf = await getSchema(id_affaire);
        let data;
        if (user?.nationality === "تونسية" && user?.ehouwiya !== 1) {
          data = await checkCitizenStatusByCinDn(
            user?.user?.date_naiss_user,
            user?.cin
          );
        }
        if (data?.prenomAr) {
          survey.setValue("investor_first_name_ar", data?.prenomAr);
          const name_ar = survey.getQuestionByName("investor_first_name_ar");
          name_ar.readOnly = true;
        }
        if (data?.nomAr) {
          survey.setValue("investor_last_name_ar", data?.nomAr);
          const prenom_ar = survey.getQuestionByName("investor_last_name_ar");
          prenom_ar.readOnly = true;
        }
        if (data?.commune) {
          let lieu_naissance = await getCommuneByCodeCommune(data?.commune);

          survey.setValue("investor_birth_place", lieu_naissance);

          survey.getQuestionByName("investor_birth_place").readOnly = true;

          // Object.keys(codes_communes).map((e) => {
          //   Object.keys(codes_communes[e].communes).map((elem) => {
          //     if (codes_communes[e].communes[elem] === data?.commune) {
          //
          //     }
          //   });
          // });
        }
        if (data?.nomFr) {
          survey.setValue("investor_last_name_fr", data?.nomFr || user?.nom);
          const name_fr = survey.getQuestionByName("investor_last_name_fr");
          name_fr.readOnly = true;
        }
        if (data?.prenomFr) {
          survey.setValue(
            "investor_first_name_fr",
            data?.prenomFr || user?.prenom
          );
          const prenom_fr = survey.getQuestionByName("investor_first_name_fr");
          prenom_fr.readOnly = true;
        }
        if (user?.email) {
          survey.setValue("investor_email", user?.email);
          const investor_email = survey.getQuestionByName("investor_email");
          investor_email.readOnly = true;
        }

        survey.setValue("investor_phone_number", user?.mobile);
        survey.setValue("investor_nationality", user?.nationality);
        survey.setValue(
          "investor_civility",
          data?.genre === "1" ? "ذكر" : "أنثى"
        );

        survey.setValue("investor_cin", user?.cin);
        survey.setValue(
          "investor_birth_date",
          dayjs(user?.user?.date_naiss_user).format("YYYY-MM-DD")
        );
        survey.setValue(
          "investor_date_delivrance",
          epf.response.data.investor_date_delivrance
        );
        survey.setValue(
          "investor_eduction_level",
          epf?.response?.data?.investor_eduction_level
        );
        survey.setValue(
          "investor_residence_address",
          epf?.response?.data?.investor_residence_address
        );
        survey.setValue(
          "investor_residence_governorate",
          epf?.response?.data?.investor_residence_governorate
        );
        survey.setValue(
          "investor_residence_delegation",
          epf?.response?.data?.investor_residence_delegation
        );
        survey.setValue(
          "investor_date_expiration_passeport",
          epf?.response?.data?.investor_date_expiration_passeport
        );

        survey.setValue(
          "investor_passport",
          epf?.response?.data?.investor_passport
        );
        survey.setValue(
          "investor_birth_place",
          epf?.response?.data?.investor_birth_place
        );

        survey.setValue(
          "investor_residence_postal_code",
          epf?.response?.data?.investor_residence_postal_code
        );
        survey.setValue(
          "establishment_director_data_passeport",
          epf?.response?.data?.establishment_director_data_passeport
        );
        survey.setValue(
          "investor_passport",
          epf?.response?.data?.investor_passport
        );
        survey.setValue(
          "recap_establishment_data_official_name",
          epf?.response?.data?.establishment_data_official_name
        );
        survey.setValue(
          "recap_establishment_data_commercial_name",
          epf?.response?.data?.establishment_data_commercial_name
        );
        survey.setValue(
          "recap_establishment_data_phone_number_1",
          epf?.response?.data?.establishment_data_phone_number_1
        );
        survey.setValue(
          "recap_establishment_data_phone_number_2",
          epf?.response?.data?.establishment_data_phone_number_2
        );
        survey.setValue(
          "recap_establishment_data_fax_number",
          epf?.response?.data?.establishment_data_fax_number
        );
        survey.setValue(
          "recap_establishment_data_email",
          epf?.response?.data?.establishment_data_email
        );
        survey.setValue(
          "recap_establishment_data_address",
          epf?.response?.data?.establishment_data_address
        );
        survey.setValue(
          "recap_establishment_data_training_type",
          epf?.response?.data?.establishment_data_training_type
        );
        survey.setValue(
          "recap_establishment_data_governorate",
          epf?.response?.data?.establishment_data_governorate
        );
        survey.setValue(
          "recap_establishment_data_delegation",
          epf?.response?.data?.establishment_data_delegation
        );
        survey.setValue(
          "recap_establishment_data_postal_code",
          epf?.response?.data?.establishment_data_postal_code
        );
        survey.setValue("inverstor_type", epf?.response?.data?.inverstor_type);
        survey.setValue(
          "establishment_data_tax_number",
          epf?.response?.data?.establishment_data_tax_number
        );
        survey.setValue(
          "establishment_data_reservation_number_commercial",
          epf?.response?.data?.establishment_data_reservation_number_commercial
        );
        survey.setValue(
          "establishment_data_official_name",
          epf?.response?.data?.establishment_data_official_name
        );
        survey.setValue(
          "establishment_data_tax_number",
          epf?.response?.data?.establishment_data_tax_number
        );
        survey.setValue(
          "establishment_data_commercial_name",
          epf?.response?.data?.establishment_data_commercial_name
        );
        survey.setValue(
          "establishment_data_reservation_commerciale_expiration_date",
          epf?.response?.data
            ?.establishment_data_reservation_commerciale_expiration_date
        );
        survey.setValue(
          "establishment_data_training_type",
          epf?.response?.data?.establishment_data_training_type
        );
        survey.setValue(
          "investor_copie_cin",
          epf?.response?.data?.investor_copie_cin
        );
        survey.setValue(
          "investor_copie_passeport",
          epf?.response?.data?.investor_copie_passeport
        );
        survey.setValue(
          "investor_copie_cv",
          epf?.response?.data?.investor_copie_cv
        );
        survey.setValue(
          "investor_specific_needs",
          epf?.response?.data?.investor_specific_needs
        );
        survey.setValue(
          "training_nature_data",
          epf?.response?.data?.training_nature_data
        );

        setIsLoading(false);
      }

      fetchData();

      const setUserData = (userData, role) => {
        const fieldsToSet = [
          {
            surveyField:
              role === "directeur"
                ? "establishment_director_data_passeport"
                : "investor_passport",
            userDataField: "passport",
          },

          {
            surveyField:
              role === "directeur"
                ? "establishment_director_date_expiration_passeport"
                : "investor_date_expiration_passeport",
            userDataField: "expiration_passeport",
          },
          {
            surveyField:
              role === "directeur"
                ? "establishment_director_data_experience_years"
                : "",
            userDataField: "experience_years",
            readOnly: true,
          },
          {
            surveyField:
              role === "directeur"
                ? "establishment_director_data_first_name"
                : "investor_first_name_ar",
            userDataField: "prenom_ar",
            readOnly: true,
          },
          {
            surveyField:
              role === "directeur"
                ? "establishment_director_data_last_name"
                : "investor_last_name_ar",
            userDataField: "nom_ar",
            readOnly: true,
          },

          {
            surveyField:
              role === "directeur"
                ? "establishment_director_data_first_name_fr"
                : "investor_last_name_fr",
            userDataField: "prenom",
            readOnly: true,
          },
          {
            surveyField:
              role === "directeur"
                ? "establishment_director_data_last_name_fr"
                : "investor_first_name_fr",
            userDataField: "nom",
            readOnly: true,
          },
          {
            surveyField:
              role === "directeur"
                ? "establishment_director_data_email"
                : "investor_email",
            userDataField: "mail",
            readOnly: true,
          },
          {
            surveyField:
              role === "directeur"
                ? "establishment_director_data_birth_place"
                : "investor_birth_date",
            userDataField: "date_naiss_user",
            readOnly: true,
          },
          {
            surveyField:
              role === "directeur"
                ? "establishment_director_data_genre"
                : "investor_civility",
            userDataField: "civilite",
            readOnly: true,
          },

          {
            surveyField:
              role === "directeur"
                ? "establishment_director_data_nationality"
                : "investor_nationality",
            userDataField: "nationality",
            readOnly: true,
          },
          {
            surveyField:
              role === "directeur"
                ? "establishment_director_data_cin_number"
                : "investor_cin",
            userDataField: "cin",
            readOnly: true,
          },

          {
            surveyField:
              role === "directeur"
                ? "establishment_director_data_date_delivrance"
                : "investor_date_delivrance",
            userDataField: "delivrance_date",
          },
          {
            surveyField:
              role === "directeur"
                ? "establishment_director_data_birth_place"
                : "investor_birth_place",
            userDataField: "birthplace",
            readOnly: true,
          },
          {
            surveyField:
              role === "directeur"
                ? "establishment_director_data_education_level"
                : "investor_eduction_level",
            userDataField: "niveau_scolaire",
            readOnly: true,
          },
          {
            surveyField:
              role === "directeur"
                ? "establishment_director_data_phone_number"
                : "investor_phone_number",
            userDataField: "telephone",
            readOnly: true,
          },
          {
            surveyField:
              role === "directeur"
                ? "establishment_director_data_address"
                : "investor_residence_address",
            userDataField: "adresse",
          },
          {
            surveyField:
              role === "directeur"
                ? "establishment_director_data_governorate"
                : "investor_residence_governorate",
            userDataField: "goverment",
          },
          {
            surveyField:
              role === "directeur"
                ? "establishment_director_data_delegation"
                : "investor_residence_delegation",
            userDataField: "delegation",
          },
          {
            surveyField: role === "directeur" ? "" : "investor_residence_postal_code",
            userDataField: "code_postal",
          },
          {
            surveyField:
              role === "directeur"
                ? "establishment_director_data_specific_needs"
                : "investor_specific_needs",
            userDataField: "besoins_specifiques",
          },
          {
            surveyField:
              role === "directeur"
                ? "establishment_director_data_cin_copie"
                : "investor_copie_cin",
            userDataField: "copie_cin",
          },
          {
            surveyField:
              role === "directeur"
                ? "establishment_director_data_passeport_copie"
                : "investor_copie_passeport",
            userDataField: "copie_passeport",
          },
          {
            surveyField:
              role === "directeur"
                ? "establishment_director_data_director_folder"
                : "investor_copie_cv",
            userDataField: "copie_cv",
          },
          {
            surveyField:
              role === "directeur" ? "copie_autorisation_ministre" : "",
            userDataField: "copie_autorisation_ministre",
          },
        ];

        fieldsToSet.forEach((field) => {
          const { surveyField, userDataField, readOnly } = field;
          const value =
            surveyField.includes("copie") && userData[userDataField]
              ? setFileValues(userData[userDataField])
              : surveyField.includes("_date") && userData[userDataField]
              ? dayjs(userData[userDataField]).format("YYYY-MM-DD")
              : userData[userDataField];
          const question = survey.getQuestionByName(surveyField);

          if (!value) {
            question ? (question.readOnly = false) : null;
          } else {
            survey.setValue(surveyField, value);
            if (readOnly) {
              question.readOnly = true;
            }
          }
        });
      };

      if (user) {
        axios
          .get(`${import.meta.env.VITE_APP_GEOPROD_API}/get_user_with_id`, {
            headers: {
              Idsession: user?.Acces_Token,
            },
          })
          .then((data) => {
            setUserData(data?.data?.user);
            if (
              !sessionStorage.getItem("id_affaire_annexe") &&
              !params.state?.id_affaire
            ) {
              const payload = {
                ligne_produit: "9c838d2e45b2ad1094d42f4ef36764f6",
                id_opp: null,
                nom_produit: "THAMM PRODUCT (  )",
                id_produit: "d728587fff2d87201eae0a158507d814",
                cmp: "987654300",
                affaire_parent: id_affaire,
                user_id: user.id_user,
                date_effet: "2024-01-02",
                souscription: "2024-01-02",
                id_fractionnement: 11,
                affected: "1002237",
                data_form_prod: JSON.stringify({
                  ...survey.data,
                }),
              };
              const getIdAffaire = async () => {
                const affaire = await addAffaire(payload);
                sessionStorage.setItem(
                  "id_affaire_annexe",
                  affaire?.id_affaire
                );

                await updateAffaireAnnexe({
                  id_affaire1: affaire?.id_affaire,
                  id_affaire2: id_affaire,
                  type: 2,
                });
              };

              getIdAffaire();
            }
          });
      }

      if (!params?.state?.id_affaire) {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }
    }
  }, [surveyJson]);

  useEffect(() => {
    if (surveyJson !== null) {
      survey.setJsonObject(surveyJson);

      onUploadFiles(survey, true);
      survey.onValidateQuestion.add(async function (sender, options) {
        if (options.name === "establishment_data_governorate") {
          const parentGovernorate = sender.getQuestionByName(
            "recap_establishment_data_governorate"
          ).value;
          const selectedGouvernorat = options.value;
          const question = sender.getQuestionByName(
            "establishment_data_governorate"
          );
          question.clearErrors();
          if (parentGovernorate !== selectedGouvernorat) {
            options.allowChanging = false;
            options.error = t(
              "L’adresse de l’annexe doit être dans le même gouvernorat que l’EPF principal."
            );
            notification.error({
              message: t(
                "L’adresse de l’annexe doit être dans le même gouvernorat que l’EPF principal. Veuillez accéder à la rubrique ‘nouvel EPF’ pour enregistrer un établissement dans un gouvernorat différent."
              ),
            });
          } else {
            options.allowChanging = true;
          }
        }
      });

      survey.onAfterRenderQuestion.add(function (sender, options) {
        if (options.question.name === "training_type_data_sub_sector") {
          displayQuestionsFunction();
        }
        if (options.question.title.includes("(En arabe)")) {
          options.htmlElement.style.direction = "rtl";
        }

        const currentPage = survey.currentPage;

        currentPage.questions.forEach((question) => {
          if (
            question.name.includes("_valid") ||
            question.name.includes("_validate")
          ) {
            question.visible = false;
          }
          if (sender.isShowingPreview) {
            const managementPanel = sender.getQuestionByName("management_data");
            managementPanel?.panels.forEach((panel) => {
              const dateDelivranceQuestion = panel.getQuestionByName(
                "management_data_staff_date_delivrance"
              );
              const birthDateQuestion = panel.getQuestionByName(
                "management_data_staff_birth_date"
              );
              const search_button = panel.getQuestionByName(
                "management_data_search"
              );
              if (dateDelivranceQuestion)
                dateDelivranceQuestion.readOnly = true;
              if (birthDateQuestion) birthDateQuestion.readOnly = true;
              if (search_button) search_button.readOnly = true;
            });

            const trainer_data = sender.getQuestionByName("trainer_data");
            trainer_data?.panels.map((panel) => {
              panel.questions.map((question) => {
                if (question.inputType === "api") {
                  question.readOnly = true;
                }
              });
            });
          }
        });

        const fileInputButton =
          options.htmlElement.querySelector(".sd-file__wrapper");
        if (fileInputButton) {
          const spanElement = fileInputButton.querySelector("span");
          if (spanElement) {
            spanElement.textContent = "Télécharger le fichier - تحميل الملف";
          }
        }
        const fileInputButtonBeforeChoose = options.htmlElement.querySelector(
          ".sd-file__choose-btn"
        );
        if (fileInputButtonBeforeChoose) {
          const spanElement = fileInputButtonBeforeChoose.querySelector("span");
          if (spanElement) {
            spanElement.textContent = "Télécharger le fichier - تحميل الملف";
          }
        }

        const emptyTextDiv = options.htmlElement.querySelector(
          ".sv-list__empty-text"
        );
        if (emptyTextDiv) {
          emptyTextDiv.textContent =
            "Veuillez d'abord remplir le champ précédent !";
        }

        const placeholderNoFile = options.htmlElement.querySelector(
          ".sd-file__decorator"
        );
        if (placeholderNoFile) {
          const placeholder = placeholderNoFile.querySelector(
            ".sd-file__drag-area-placeholder"
          );
          if (placeholder) {
            const spanElement = placeholder.querySelector(".sv-string-viewer");
            if (spanElement && spanElement.textContent === "No file selected") {
              spanElement.textContent =
                "Aucun fichier téléchargé - لم يتم تحميل أي ملف";
            }
          }
        }
      });
      mergeSurveyData();
      onCurrentPageChanged(survey, secteurData);
      onValidateQuestion(survey);
      onValueChanging(survey, gouvernoratList,listAllDelegations);
      onShowingPreview(survey);

      onDynamicPanelItemValueChanged(
        survey,
        secteurData,
        nationalityList,
        gouvernoratList
      );
      onErrorCustomText(survey);
      onCurrentPageChanging(survey);

      const buttonExpressions = {
        director_search: {
          apiCall: (survey_) => checkDNCINDirector(survey_),
        },
        management_data_search: {
          apiCall: (survey_) => getCadre(survey_),
        },
      };

      CustomWidgetCollection.Instance.addCustomWidget({
        name: "refreshbar",
        title: "Refresh Bar",
        isFit: function (question) {
          return (
            question.getType() === "text" &&
            question.jsonObj.inputType === "refreshbar"
          );
        },
        render: function (question, element) {
          return <RefreshBarComponent question={question} />;
        },
      });

      CustomWidgetCollection.Instance.addCustomWidget({
        name: "callApi",
        title: "callApi",
        isFit: function (question) {
          return (
            question.getType() === "text" &&
            question.jsonObj.inputType === "api"
          );
        },
        render: function (question, element) {
          const fieldName = question.name;

          const mapping = expressionsMap[fieldName];

          if (!mapping) {
            return null;
          }

          const { expression, apiCall, minLength, maxLength, regex, message } =
            mapping;

          if (typeof apiCall !== "function") {
            return null;
          }

          const handleClick = () => {
            const survey_ = question.survey;
            return apiCall(survey_);
          };

          return (
            <SearchInputComponent
              question={question}
              expression={expression}
              apiCall={handleClick}
              minLength={minLength}
              maxLength={maxLength}
              regex={regex}
              message={message}
              survey={survey}
            />
          );
        },
      });

      CustomWidgetCollection.Instance.addCustomWidget({
        name: "datepicker",
        title: "Date picker",
        isFit: function (question) {
          return (
            question.getType() === "text" &&
            question.jsonObj.inputType === "date"
          );
        },
        render: function (question, element) {
          return <DatePikckerComponent question={question} survey={survey} />;
        },
      });

      CustomWidgetCollection.Instance.addCustomWidget({
        name: "Button",
        title: "Button",
        isFit: function (question) {
          return (
            question.getType() === "text" &&
            question?.jsonObj?.inputType?.toLowerCase() === "button"
          );
        },
        render: function (question, element) {
          const expression = buttonExpressions[question.name];
          const handleClick = () => {
            const survey_ = question.survey;
            if (expression && expression.apiCall) {
              return expression.apiCall(survey_);
            }
          };

          return (
            <ButtonComponent question={question} handleClick={handleClick} />
          );
        },
      });

      onComplete(survey, setOpenModal, setIsLoading, "annexe", handleFinish);

      const checkDirectorIdentity = (value) => {
        if (value[0] === "المستثمر نفسه") {
          const page = survey.getPageByName(
            "Données de Directeur de l’Établissement"
          );

          let cin = survey.data.investor_cin;
          let passeport = survey.data.investor_passport;
          let nationality = survey.data.investor_nationality;

          let payload = {
            role: import.meta.env.VITE_APP_ID_ROLE_INVESTISSEUR_DIRCETEUR,
          };
          if (nationality === "تونسية") {
            payload.cin = cin;
          } else {
            payload.passport = passeport;
          }

          axios
            .post(
              `${import.meta.env.VITE_APP_GEOPROD_API}/admin/check_cin_role`,
              payload
            )
            .then((res) => {
              if (res.data.existe) {
                notification.error({
                  message: t("Erreur"),
                  description: t(
                    "Le directeur ajouté est fonctionnel dans un autre EPF"
                  ),
                });
                return;
              } else {
                page.questions.forEach((question) => {
                  if (
                    question.name !== "establishment_director_data_identity" &&
                    question.name !==
                      "establishment_director_data_experience_years" &&
                    question.name !==
                      "establishment_director_data_ministerial_auth_date" &&
                    question.name !== "copie_autorisation_ministre"
                  )
                    question.readOnly = true;

                  if (
                    question.name !==
                      "establishment_director_data_test_valid" &&
                    question.name !==
                      "establishment_director_data_passeport_copie" &&
                    question.name !== "copie_autorisation_ministre" &&
                    question.name !== "establishment_director_data_cin_copie" &&
                    question.name !==
                      "establishment_director_data_date_delivrance" &&
                    question.name !==
                      "establishment_director_data_ministerial_auth_date" &&
                    question.name !== "establishment_director_data_passeport" &&
                    question.name !==
                      "establishment_director_data_cin_number" &&
                    question.name !== "establishment_director_data_spinner" &&
                    question.name !== "director_search"
                  ) {
                    question.visible = true;
                  } else {
                    question.visible = false;
                  }
                  if (
                    survey.getQuestionByName("investor_nationality").value ===
                    "تونسية"
                  ) {
                    if (
                      question.name ===
                        "establishment_director_data_cin_copie" ||
                      question.name ===
                        "establishment_director_data_date_delivrance"
                    ) {
                      question.visible = true;
                    }
                  } else if (
                    survey.getQuestionByName("investor_nationality").value !==
                    "تونسية"
                  ) {
                    if (
                      question.name ===
                        "establishment_director_data_test_valid" ||
                      question.name ===
                        "establishment_director_data_passeport_copie" ||
                      question.name === "copie_autorisation_ministre" ||
                      question.name ===
                        "establishment_director_data_ministerial_auth_date"
                    ) {
                      question.visible = true;
                    }
                  }
                });
                survey.setValue("establishment_director_data_test_valid", true);

                survey.setValue(
                  "establishment_director_data_last_name",
                  survey.data.investor_last_name_ar
                );
                survey.setValue(
                  "establishment_director_data_last_name_fr",
                  survey.data.investor_last_name_fr
                );
                survey.setValue(
                  "establishment_director_data_date_delivrance",
                  survey.data.investor_date_delivrance
                );

                survey.setValue(
                  "establishment_director_date_expiration_passeport",
                  survey.data.investor_date_expiration_passeport
                );

                survey.setValue(
                  "establishment_director_data_genre",
                  survey.data.investor_civility
                );
                survey.setValue(
                  "establishment_director_data_passeport",
                  survey.data.investor_passport
                );
                survey.setValue(
                  "establishment_director_data_passeport_copie",
                  survey.data.investor_copie_passeport
                );

                survey.setValue(
                  "establishment_director_data_cin_copie",
                  survey.data.investor_copie_cin
                );
                survey.setValue(
                  "establishment_director_data_director_folder",
                  survey.data.investor_copie_cv
                );
                survey.setValue(
                  "establishment_director_data_first_name",
                  survey.data.investor_first_name_ar
                );

                survey.setValue(
                  "establishment_director_data_first_name_fr",
                  survey.data.investor_first_name_fr
                );

                survey.setValue(
                  "establishment_director_data_email",
                  survey.data.investor_email
                );
                survey.setValue(
                  "establishment_director_data_education_level",
                  survey.data.investor_eduction_level
                );
                survey.setValue(
                  "establishment_director_data_nationality",
                  survey.data.investor_nationality
                );
                survey.setValue(
                  "establishment_director_data_birth_date",
                  survey.data.investor_birth_date
                );
                survey.setValue(
                  "establishment_director_data_birth_place",
                  survey.data.investor_birth_place
                );
                survey.setValue(
                  "establishment_director_data_specific_needs",
                  survey.data.investor_specific_needs
                );
                survey.setValue(
                  "establishment_director_data_cin_number",
                  survey.data.investor_cin
                );
                survey.setValue(
                  "establishment_director_data_passport",
                  survey.data.investor_passport
                );
                survey.setValue(
                  "establishment_director_data_address",
                  survey.data.investor_residence_address
                );
                survey.setValue(
                  "establishment_director_data_governorate",
                  survey.data.investor_residence_governorate
                );
                survey.setValue(
                  "establishment_director_data_delegation",
                  survey.data.investor_residence_delegation
                );
                survey.setValue(
                  "establishment_director_data_phone_number",
                  survey.data.investor_phone_number
                );
              }
            });
        } else {
          const page = survey.getPageByName(
            "Données de Directeur de l’Établissement"
          );
          if (page) {
            page.questions.forEach((question) => {
              survey.setValue(
                "establishment_director_data_nationality",
                "تونسية"
              );
              const valid = survey.getQuestionByName(
                "establishment_director_data_test_valid"
              );
              valid.value = false;

              if (question.name !== "establishment_director_data_identity") {
                survey.clearValue(question.name);
              }

              if (
                question.name !== "establishment_director_data_nationality" &&
                question.name !== "establishment_director_data_identity" &&
                question.name !== "establishment_director_data_passeport" &&
                question.name !== "establishment_director_data_cin_number" &&
                question.name !== "establishment_director_data_birth_date" &&
                question.name !== "director_search"
              ) {
                question.visible = false;
              }
              question.readOnly = false;
            });
          }
        }
      };

      FunctionFactory.Instance.register(
        "checkDirectorIdentity",
        checkDirectorIdentity
      );
    }

    return () => {
      survey.dispose(true, true);
      survey = new Model();
    };
  }, [surveyJson]);

  const checkDNCINDirector = async (survey) => {
    const dn = survey.getValue("establishment_director_data_birth_date");
    const cin = survey.getValue("establishment_director_data_cin_number");
    const identity = survey.getValue("establishment_director_data_identity");
    let data;
    const valid = survey.getQuestionByName(
      "establishment_director_data_test_valid"
    );
    const spinner = survey.getQuestionByName(
      "establishment_director_data_spinner"
    );

    if (!dn || !cin || !identity) {
      valid.value = false;
      notification.error({
        message: t("Erreur"),
        description: t("Veuillez remplir les champs obligatoires."),
      });
      return;
    }

    spinner.visible = true;

    axios
      .post(`${import.meta.env.VITE_APP_GEOPROD_API}/admin/check_cin_role`, {
        cin: cin,
        role: import.meta.env.VITE_APP_ID_ROLE_DIRECTEUR,
      })
      .then(async (res) => {
        if (res.data.existe) {
          spinner.visible = false;
          notification.error({
            message: t("Erreur"),
            description: t(
              "Le directeur ajouté est fonctionnel dans un autre EPF"
            ),
          });
          return;
        } else {
          data = await checkCitizenStatusByCinDn(dn, cin);
          const page = survey.getPageByName(
            "Données de Directeur de l’Établissement"
          );

          if (data) {
            valid.value = true;
            spinner.visible = false;

            page.questions.map((question) => {
              if (
                question.name !== "establishment_director_data_test_valid" &&
                question.name !==
                  "establishment_director_data_passeport_copie" &&
                question.name !== "copie_autorisation_ministre" &&
                question.name !== "establishment_director_data_cin_copie" &&
                question.name !==
                  "establishment_director_data_date_delivrance" &&
                question.name !==
                  "establishment_director_data_ministerial_auth_date" &&
                question.name !== "establishment_director_data_passeport" &&
                question.name !== "establishment_director_data_cin_number" &&
                question.name !== "establishment_director_data_spinner"
              ) {
                question.visible = true;
              }
              if (
                survey.getQuestionByName(
                  "establishment_director_data_nationality"
                ).value === "تونسية"
              ) {
                if (
                  question.name === "establishment_director_data_cin_copie" ||
                  question.name ===
                    "establishment_director_data_date_delivrance" ||
                  question.name === "director_search"
                ) {
                  question.visible = true;
                }
              } else if (
                survey.getQuestionByName(
                  "establishment_director_data_nationality"
                ).value !== "تونسية"
              ) {
                if (
                  question.name === "establishment_director_data_test_valid" ||
                  question.name ===
                    "establishment_director_data_passeport_copie" ||
                  question.name === "copie_autorisation_ministre" ||
                  question.name ===
                    "establishment_director_data_ministerial_auth_date"
                ) {
                  question.visible = true;
                }
              }
            });

            survey.setValue(
              "establishment_director_data_last_name",
              data?.nomAr
            );
            survey.setValue(
              "establishment_director_data_first_name",
              data?.prenomAr
            );

            const name_ar = survey.getQuestionByName(
              "establishment_director_data_last_name"
            );
            const prenom_ar = survey.getQuestionByName(
              "establishment_director_data_first_name"
            );
            name_ar.readOnly = true;
            prenom_ar.readOnly = true;
            const name_fr = survey.getQuestionByName(
              "establishment_director_data_last_name_fr"
            );
            const prenom_fr = survey.getQuestionByName(
              "establishment_director_data_first_name_fr"
            );
            if (data?.nomFr) {
              survey.setValue(
                "establishment_director_data_last_name_fr",
                data?.nomFr
              );
              survey.setValue(
                "establishment_director_data_first_name_fr",
                data?.prenomFr
              );

              name_fr.readOnly = true;
              prenom_fr.readOnly = true;
            } else {
              name_fr.readOnly = false;
              prenom_fr.readOnly = false;
            }
            survey.setValue(
              "establishment_director_data_genre",
              data?.genre === "1" ? "ذكر" : "أنثى"
            );
            const genre = survey.getQuestionByName(
              "establishment_director_data_genre"
            );
            genre.readOnly = true;

            if (data?.commune) {
              let lieu_naissance = await getCommuneByCodeCommune(data?.commune);

              survey.setValue(
                "establishment_director_data_birth_place",
                lieu_naissance
              );

              const birthplace = survey.getQuestionByName(
                "establishment_director_data_birth_place"
              );
              birthplace.readOnly = true;
            }
          } else {
            valid.value = false;
            spinner.visible = false;

            const excludedQuestions = [
              "establishment_director_data_nationality",
              "establishment_director_data_cin_number",
              "establishment_director_data_passeport",
              "establishment_director_data_birth_date",
              "establishment_director_data_identity",
              "director_search",
            ];

            page.getQuestions().forEach((question) => {
              if (!excludedQuestions.includes(question.name)) {
                question.visible = false;
              }
            });
          }
        }
      })
      .catch(() => {
        spinner.visible = false;
      });

    // setIsLoading(false);
  };

  const getTrainerWithCin = async (survey, name) => {
    const trainer_data = survey.getQuestionByName("trainer_data");
    trainer_data?.panels.map(async (panel) => {
      const spinner = panel.getQuestionByName("cin_check_spinner");
      const valid = panel.getQuestionByName("trainer_data_test_valid");
      const active = panel.getQuestionByName("trainer_active");
      const changedData = panel.getValue();
      const trainerCin = changedData.trainer_data_cin_number;
      const trainerPassport = changedData.trainer_data_passport;
      const trainerNationality = changedData.trainer_data_nationality;

      try {
        spinner.visible = true;
        const params = {
          nationality: trainerNationality,
          cin: null,
          passport: null,
        };

        if (trainerNationality === "تونسية") {
          params.cin = trainerCin;
        } else {
          params.passport = trainerPassport;
        }

        const data = await getTrainerByNationalityAndIdentity(params);

        const excludedQuestions = [
          "trainer_data_nationality",
          "trainer_data_passport",
          "trainer_data_cin_number",
        ];

        if (!data) {
          survey.setValue("trainer_data_test_valid", false);

          spinner.visible = false;
          notification.error({
            message: t("Erreur"),
            description:
              trainerNationality === "تونسية"
                ? t(
                    "Le numéro de CIN du formateur n'est pas associé à un compte sur la plateforme."
                  )
                : t(
                    "Le numéro de passeport du formateur n'est pas associé à un compte sur la plateforme."
                  ),
            duration: 0,
          });

          panel.getQuestions().forEach((question) => {
            if (!excludedQuestions.includes(question.name)) {
              question.visible = false;
            }
          });
        } else {
          survey.setValue("trainer_data_test_valid", true);

          if (data.first_login === 1) {
            active.value = false;
            spinner.visible = false;

            notification.error({
              message: t("Erreur"),
              description: t("Le compte du formateur n'est pas activé."),
              duration: 0,
            });
          } else {
            active.value = true;
            panel.getQuestions().forEach((question) => {
              if (
                question.name !== "trainer_data_cin" &&
                question.name !== "trainer_data_cin_number" &&
                question.name !== "trainer_data_cin_date_delivrance" &&
                question.name !== "trainer_data_passeport" &&
                question.name !== "trainer_data_folder" &&
                question.name !== "trainer_data_test_valid" &&
                question.name !== "trainer_active" &&
                question.name !== "trainer_data_passport" &&
                question.name !== "dossier_autoriser"
              ) {
                question.visible = true;
              }

              if (
                panel.getQuestionByName("trainer_data_nationality").value ===
                "تونسية"
              ) {
                if (
                  question.name === "trainer_data_cin" ||
                  question.name === "trainer_data_cin_date_delivrance"
                ) {
                  question.visible = true;
                }
              } else if (
                panel.getQuestionByName("trainer_data_nationality").value !==
                "تونسية"
              ) {
                if (
                  question.name === "trainer_data_passeport" ||
                  question.name === "trainer_data_passeport"
                ) {
                  question.visible = true;
                }
              }
            });
            const delegationQuestion = panel.getQuestionByName(
              "trainer_data_delegation"
            );

            const id_goverment = gouvernoratList.find(
              (e) => e.value === data?.goverment
            )?.id;

            const delegations = await fetchDelegationList(id_goverment);

            delegationQuestion.choices = delegations || [];
            spinner.visible = false;
            panel.getQuestionByName("trainer_data_last_name").value =
              data?.nom || "";
            panel.getQuestionByName("trainer_data_first_name").value =
              data?.prenom || "";
            panel.getQuestionByName("trainer_data_email").value =
              data?.mail || "";
            panel.getQuestionByName("trainer_data_birth_date").value =
              dayjs(data?.date_naiss_user) || "";
            panel.getQuestionByName("trainer_data_civility").value =
              data?.civilite;
            panel.getQuestionByName("trainer_data_phone_number").value =
              data?.mobile || "";
            panel.getQuestionByName("trainer_data_address").value =
              data?.adresse || "";
            panel.getQuestionByName("trainer_data_birth_place").value =
              data?.lieu_naissance || "";
            panel.getQuestionByName("trainer_data_governorate").value =
              data?.goverment || "";
            panel.getQuestionByName("trainer_data_delegation").value =
              data?.delegation || "";
            panel.getQuestionByName("trainer_data_postal_code").value =
              data?.code_postal || "";
            panel.getQuestionByName("trainer_data_specialty_domain").value =
              data?.domaines_specialite || "";
            panel.getQuestionByName("trainer_data_education_level").value =
              data?.niveau_scolaire || "";
            panel.getQuestionByName("trainer_data_cin_date_delivrance").value =
              dayjs(data?.delivrance_date, "DD/MM/YYYY") || "";
            panel.getQuestionByName("trainer_data_specific_needs").value =
              data?.besoins_specifiques;

            const apiUrl = `${
              import.meta.env.VITE_APP_GEOPROD_API
            }/document_formateur/getByCinPassport`;
            const param =
              trainerNationality === "تونسية"
                ? `cin=${trainerCin}`
                : `passport=${trainerPassport}`;
            axiosInstance
              .get(
                `${apiUrl}?${param}`,

                {
                  headers: {
                    Idsession: JSON.parse(
                      sessionStorage.getItem("-x-token-user")
                    ).Acces_Token,
                  },
                }
              )
              .then((res) => {
                if (trainerNationality === "تونسية") {
                  const response_file = res?.data.response?.find(
                    (e) => e.key === "copie_cin"
                  );
                  let fileType = mime.getType(response_file?.name);
                  const file = [
                    {
                      content: response_file.url,
                      name: response_file?.name,
                      type: fileType,
                    },
                  ];
                  panel.getQuestionByName("trainer_data_cin").value = file;
                  panel.getQuestionByName("trainer_data_cin").visible = true;
                } else {
                  const response_file = res?.data.response?.find(
                    (e) => e.key === "copie_passeport"
                  );

                  const file = [
                    {
                      content: response_file.url,
                      name: response_file?.name,
                      type: mime.getType(response_file?.name),
                    },
                  ];
                  panel.getQuestionByName("trainer_data_passeport").value =
                    file;
                  panel.getQuestionByName(
                    "trainer_data_passeport"
                  ).visible = true;
                }

                if (data?.autoriser_dossier === "نعم") {
                  const response_file = res?.data.response?.find(
                    (e) => e.key === "dossier_file"
                  );
                  const file = [
                    {
                      content: response_file.url,
                      name: response_file?.name,
                      type: mime.getType(response_file?.name),
                    },
                  ];
                  panel.getQuestionByName("trainer_data_folder").value = file;
                  panel.getQuestionByName("trainer_data_folder").visible = true;
                  panel.getQuestionByName("dossier_autoriser").value = true;
                } else {
                  panel.getQuestionByName("dossier_autoriser").value = false;
                }
              });

            // Object.keys(gouvernorats).map((e) => {
            //   if (e === data?.goverment) {
            //     delegationQuestion.choices =
            //       gouvernorats[e]["delegations"] || [];
            //   }
            // });
            // panel.getQuestionByName("trainer_data_delegation").value =
            //   data?.delegation;
          }
        }
      } catch (error) {
        spinner.visible = false;
        notification.error({
          message: t("Erreur"),
          description: error?.response?.data?.message || "An error occurred.",
          duration: 0,
        });
      }
    });
  };

  const getCadre = async (survey) => {
    const management_data = survey.getQuestionByName("management_data");

    management_data?.panels.map(async (panel) => {
      if (
        survey.getQuestionByName("management_data").getItemIndex() - 1 ===
        survey.getQuestionByName("management_data").panels.indexOf(panel)
      ) {
        const changedData = panel.getValue();
        const spinner = panel.getQuestionByName(
          "management_data_check_spinner"
        );
        const valid = panel.getQuestionByName("management_data_staff_valid");
        const cinNumber = changedData?.management_data_staff_cin_number;
        const birthDate = changedData?.management_data_staff_birth_date;

        try {
          if (!birthDate || !cinNumber) {
            valid.value = false;
            notification.error({
              message: t("Erreur"),
              description: t("Veuillez remplir les champs obligatoires."),
            });
            return;
          }
          spinner.visible = true;

          const data = await checkCitizenStatusByCinDn(birthDate, cinNumber);

          if (data) {
            valid.value = true;
            spinner.visible = false;
            panel.getQuestions().forEach((question) => {
              if (
                question.name !== "management_data_staff_cin_number" &&
                question.name !== "management_data_staff_passport_number" &&
                question.name !== "management_data_staff_date_delivrance" &&
                question.name !== "management_data_staff_cin_copy" &&
                question.name !== "management_data_staff_passport_copy" &&
                question.name !== "management_data_staff_valid" &&
                question.name !== "management_data_check_spinner"
              ) {
                question.visible = true;
              }

              if (
                question.name === "management_data_staff_cin_copy" ||
                question.name === "management_data_staff_date_delivrance" ||
                question.name === "management_data_search"
              ) {
                question.visible = true;
              }
            });

            panel.getQuestionByName("management_data_staff_last_name").value =
              data?.nomAr || "";
            panel.getQuestionByName("management_data_staff_first_name").value =
              data?.prenomAr || "";

            panel.getQuestionByName("management_data_staff_civility").value =
              data?.genre === "1" ? "ذكر" : "أنثى";
            const nom_fr = panel.getQuestionByName(
              "management_data_staff_last_name_fr"
            );
            const prenom_fr = panel.getQuestionByName(
              "management_data_staff_first_name_fr"
            );
            if (data?.nomFr) {
              nom_fr.value = data?.nomFr || "";
              prenom_fr.value = data?.prenomFr || "";
              nom_fr.readOnly = true;
              prenom_fr.readOnly = true;
            } else {
              nom_fr.readOnly = false;
              prenom_fr.readOnly = false;
            }
          } else {
            valid.value = false;
            spinner.visible = false;
            const excludedQuestions = [
              "management_data_staff_nationality",
              "management_data_staff_passport_number",
              "management_data_staff_cin_number",
              "management_data_staff_birth_date",
              "management_data_search",
            ];
            panel.getQuestions().forEach((question) => {
              if (!excludedQuestions.includes(question.name)) {
                question.visible = false;
              }
            });
          }
        } catch (error) {
          valid.value = false;
          notification.error({
            message: t("Erreur"),
            description: error?.response?.data?.message || "An error occurred.",
            duration: 0,
          });
        }
      }
    });
  };

  const populateDelegationOnLoad = () => {
    survey.getAllQuestions().forEach(async (question) => {
      if (question.name?.includes("_governorate")) {
        const delegateKey = question.name.replace(
          "_governorate",
          "_delegation"
        );
        const selectedGouvernorat = question.value;
        const selectedDelegate = survey.getQuestionByName(delegateKey);
        const id_goverment = gouvernoratList.find(
          (e) => e.value === selectedGouvernorat
        )?.id;
        if (id_goverment) {
          const delegations = await fetchDelegationList(id_goverment);

          selectedDelegate.choices = delegations || [];
        }
      }
      if (question.name?.includes("_delegation")) {
        const selectedDelegation = question.value;
        const postalCodeQuestion = survey.getQuestionByName(
          question.name.replace("_delegation", "_postal_code")
        );
  
        const idDelegation = listAllDelegations.find(
          (elem) => elem.value === selectedDelegation
        )?.id;
  
        if (idDelegation && postalCodeQuestion) {
          const listPostalCodes = await fetchCodePostalList(idDelegation);
          if (listPostalCodes) {
            postalCodeQuestion.choices = listPostalCodes.map((code) => ({
              value: code.value,
              text: code.text,
            }));
          } else {
            postalCodeQuestion.choices = [];
          }
        }
      }

      if (question.name === "management_data") {
        const dynamicPanel = question;
        dynamicPanel?.panels.forEach(async (panel) => {
          const governorateQuestion = panel.getQuestionByName(
            "management_data_staff_residence_governorate"
          );
          if (!governorateQuestion) return;
          const selectedGouvernorat = governorateQuestion.value;
          const delegateKey = "management_data_staff_residence_delegation";
          const selectedDelegate = panel.getQuestionByName(delegateKey);

          const id_goverment = gouvernoratList.find(
            (e) => e.value === selectedGouvernorat
          )?.id;
          if (id_goverment) {
            const delegations = await fetchDelegationList(id_goverment);

            selectedDelegate.choices = delegations || [];
          }
        });
      }

      if (question.name === "trainer_data") {
        const dynamicPanel = question;

        dynamicPanel?.panels.forEach(async (panel) => {
          const governorateQuestion = panel.getQuestionByName(
            "trainer_data_governorate"
          );
          if (!governorateQuestion) return;
          const selectedGouvernorat = governorateQuestion.value;
          const delegateKey = "trainer_data_delegation";
          const selectedDelegate = panel.getQuestionByName(delegateKey);

          const id_goverment = gouvernoratList.find(
            (e) => e.value === selectedGouvernorat
          )?.id;
          if (id_goverment) {
            const delegations = await fetchDelegationList(id_goverment);

            selectedDelegate.choices = delegations || [];
          }
        });
      }
    });
  };

  const displayQuestionsFunction = () => {
    survey.getAllQuestions().forEach(async (question) => {
      // if (question.getType() === 'file') {
      //   const fileValue = question.value;
      //   question. = function () {

      //     // if (fileValue && Array.isArray(fileValue) && fileValue.length > 0) {
      //     //   fileValue.forEach((file) => {
      //     //     if (file.content) {
      //     //       const link = document.createElement("a");
      //     //       link.href = file.content;
      //     //       link.target = "_blank";
      //     //       link.innerText = `Open ${file.name}`;
      //     //       link.style.margin = "10px";

      //     //       const questionContainer = question.getQuestionContainer();
      //     //       questionContainer.appendChild(link);
      //     //     }
      //     //   });
      //     // }
      //   };
      // }

      if (question.name === "training_nature_data") {
        const dynamicPanel = question;

        dynamicPanel?.panels.forEach(async (panel) => {
          const sectorQuestion = panel.getQuestionByName(
            "training_type_data_sector"
          );
          const subSectorQuestion = panel.getQuestionByName(
            "training_type_data_sub_sector"
          );
          const specialtyQuestion = panel.getQuestionByName(
            "training_type_data_specialty"
          );

          const selectedSector = sectorQuestion?.value;

          if (selectedSector) {
            const id = secteurData.find(
              (elem) => elem.value === selectedSector
            )?.id;
            const listSousSecteurs = await fetchSubSectorList(id);

            if (listSousSecteurs) {
              subSectorQuestion.choices = listSousSecteurs;
            } else {
              subSectorQuestion.choices = [];
            }

            const selectedSubSector = subSectorQuestion?.value;

            if (selectedSubSector) {
              const id = listSousSecteurs.find(
                (elem) => elem.value === selectedSubSector
              )?.id;
              const listSpecialiteDiplomes = await fetchSpetialityList(id);

              if (listSpecialiteDiplomes) {
                specialtyQuestion.choices = listSpecialiteDiplomes.map(
                  (item) => item[0].spetialite
                );
              } else {
                specialtyQuestion.choices = [];
              }
            }
          }
        });
      }

      if (question.name.toLowerCase().includes("_nationality")) {
        question.choices = nationalityList;
      }

      if (question.name === "legal_entity_legal_form") {
        question.choices = FormesJuridiquesList;
      }

      if (question.name === "training_nature_data") {
        question?.panels?.forEach((panel) => {
          panel?.questions.forEach((question) => {
            if (question.name === "training_type_data_sector") {
              question.choices = secteurData;
            }
          });
        });
      }

      if (
        question.name === "file_description" ||
        question.name === "legal_entity_rne_description" ||
        question.name === "establishment_rne_description" ||
        question.name === "espace_file_description"
      ) {
        question.visible = false;
      }

      if (question.name.toLowerCase().includes("_governorate")) {
        question.choices = gouvernoratList;
      }

      if (question.name?.includes("_delegation")) {
        const selectedDelegation = question.value;
        const postalCodeQuestion = survey.getQuestionByName(
          question.name.replace("_delegation", "_postal_code")
        );
  
        const idDelegation = listAllDelegations.find(
          (elem) => elem.value === selectedDelegation
        )?.id;
  
        if (idDelegation && postalCodeQuestion) {
          const listPostalCodes = await fetchCodePostalList(idDelegation);
          if (listPostalCodes) {
            postalCodeQuestion.choices = listPostalCodes.map((code) => ({
              value: code.value,
              text: code.text,
            }));
          } else {
            postalCodeQuestion.choices = [];
          }
        }
      }
      // if (question.name.toLowerCase().includes("_date")) {
      //   survey.setValue(
      //     question.name,
      //     moment(question.value).format("YYYY-MM-DD")
      //   );
      // }
      // if (question.name.toLowerCase().includes("_delegation")) {
      //   question.choices = this.delegation;
      // }

      if (question.name === "management_data") {
        const dynamicPanel = question;
        dynamicPanel?.panels.forEach(async (panel) => {
          const governorateQuestion = panel.getQuestionByName(
            "management_data_staff_residence_governorate"
          );
          if (!governorateQuestion) return;
          const selectedGouvernorat = governorateQuestion.value;
          const delegateKey = "management_data_staff_residence_delegation";
          const selectedDelegate = panel.getQuestionByName(delegateKey);

          const id_goverment = gouvernoratList.find(
            (e) => e.value === selectedGouvernorat
          )?.id;
          if (id_goverment) {
            const delegations = await fetchDelegationList(id_goverment);

            selectedDelegate.choices = delegations || [];
          }

          const nationalityQuestion = panel.getQuestionByName(
            "management_data_staff_nationality"
          );
          nationalityQuestion.choices = nationalityList;
          const govermentQuestion = panel.getQuestionByName(
            "management_data_staff_residence_governorate"
          );

          govermentQuestion.choices = gouvernoratList;

          if (
            panel.getQuestionByName("management_data_staff_valid").value ===
              true ||
            panel.getQuestionByName("management_data_staff_nationality")
              .value !== "تونسية"
          ) {
            panel.getQuestions().forEach((question) => {
              if (
                question.name !== "management_data_staff_cin_number" &&
                question.name !== "management_data_staff_passport_number" &&
                question.name !== "management_data_staff_date_delivrance" &&
                question.name !== "management_data_staff_cin_copy" &&
                question.name !== "management_data_staff_passport_copy" &&
                question.name !== "management_data_staff_valid" &&
                question.name !== "management_data_check_spinner" &&
                question.name !== "management_data_search"
              ) {
                question.visible = true;
              }

              if (
                panel.getQuestionByName("management_data_staff_nationality")
                  .value === "تونسية"
              ) {
                if (
                  question.name === "management_data_staff_cin_copy" ||
                  question.name === "management_data_staff_date_delivrance"
                ) {
                  question.visible = true;
                }
              } else if (
                panel.getQuestionByName("management_data_staff_nationality")
                  .value !== "تونسية"
              ) {
                if (question.name === "management_data_staff_passport_copy") {
                  question.visible = true;
                }
              }
            });
          }
        });
      }
      if (question.name === "trainer_data") {
        const dynamicPanel = question;

        dynamicPanel?.panels.forEach(async (panel) => {
          const governorateQuestion = panel.getQuestionByName(
            "trainer_data_governorate"
          );

          const nationalityQuestion = panel.getQuestionByName(
            "trainer_data_nationality"
          );
          const govermentQuestion = panel.getQuestionByName(
            "trainer_data_governorate"
          );
          nationalityQuestion.choices = nationalityList;

          govermentQuestion.choices = gouvernoratList;

          // }
          if (!governorateQuestion) return;
          const selectedGouvernorat = governorateQuestion.value;
          const delegateKey = "trainer_data_delegation";
          const selectedDelegate = panel.getQuestionByName(delegateKey);

          const id_goverment = gouvernoratList.find(
            (e) => e.value === selectedGouvernorat
          )?.id;
          if (id_goverment) {
            const delegations = await fetchDelegationList(id_goverment);

            selectedDelegate.choices = delegations || [];
          }

          if (survey.getValue("trainer_data_test_valid")) {
            panel.getQuestions().forEach(async (question) => {
              if (
                question.name !== "trainer_data_cin" &&
                question.name !== "trainer_data_cin_number" &&
                question.name !== "trainer_data_cin_date_delivrance" &&
                question.name !== "trainer_data_passeport" &&
                question.name !== "trainer_data_folder" &&
                question.name !== "trainer_data_test_valid" &&
                question.name !== "trainer_active" &&
                question.name !== "trainer_data_passport" &&
                question.name !== "cin_check_spinner" &&
                question.name !== "dossier_autoriser"
              ) {
                question.visible = true;
              }

              if (panel.getQuestionByName("dossier_autoriser").value === true) {
                if (question.name === "trainer_data_folder") {
                  question.visible = true;
                }
              }

              if (
                panel.getQuestionByName("trainer_data_nationality").value ===
                "تونسية"
              ) {
                if (
                  question.name === "trainer_data_cin" ||
                  question.name === "trainer_data_cin_date_delivrance"
                ) {
                  question.visible = true;
                }
              } else if (
                panel.getQuestionByName("trainer_data_nationality").value !==
                "تونسية"
              ) {
                if (
                  question.name === "trainer_data_passeport" ||
                  question.name === "trainer_data_passeport"
                ) {
                  question.visible = true;
                }
              }
            });
          }
        });
      }

      const page = survey.getPageByName(
        "Données de Directeur de l’Établissement"
      );
      if (page) {
        if (
          survey.getValue("establishment_director_data_identity") ===
          "المستثمر نفسه"
        ) {
          page.questions.forEach((question) => {
            if (
              question.name !== "establishment_director_data_identity" &&
              question.name !==
                "establishment_director_data_experience_years" &&
              question.name !==
                "establishment_director_data_ministerial_auth_date" &&
              question.name !== "copie_autorisation_ministre" &&
              question.name !== "establishment_director_data_date_delivrance"
            )
              question.readOnly = true;

            if (
              question.name !== "establishment_director_data_test_valid" &&
              question.name !== "establishment_director_data_passeport_copie" &&
              question.name !== "copie_autorisation_ministre" &&
              question.name !== "establishment_director_data_cin_copie" &&
              question.name !== "establishment_director_data_date_delivrance" &&
              question.name !==
                "establishment_director_data_ministerial_auth_date" &&
              question.name !== "establishment_director_data_passeport" &&
              question.name !== "establishment_director_data_cin_number" &&
              question.name !== "establishment_director_data_spinner" &&
              question.name !== "director_search"
            ) {
              question.visible = true;
            }
            if (
              survey.getQuestionByName("investor_nationality").value ===
              "تونسية"
            ) {
              if (
                question.name === "establishment_director_data_cin_copie" ||
                question.name === "establishment_director_data_date_delivrance"
              ) {
                question.visible = true;
              }
            } else if (
              survey.getQuestionByName("investor_nationality").value !==
              "تونسية"
            ) {
              if (
                question.name === "establishment_director_data_test_valid" ||
                question.name ===
                  "establishment_director_data_passeport_copie" ||
                question.name === "copie_autorisation_ministre" ||
                question.name ===
                  "establishment_director_data_ministerial_auth_date"
              ) {
                question.visible = true;
              }
            }
          });
        } else {
          if (question.name === "establishment_director_data_nationality") {
            const isTunisian = question.value === "تونسية";

            const tunisianVisibleQuestions = [
              "establishment_director_data_nationality",
              "establishment_director_data_identity",
              "establishment_director_data_cin_number",
              "establishment_director_data_birth_date",
              "director_search",
            ];

            const nonTunisianQuestions = [
              "establishment_director_data_test_valid",
              "establishment_director_data_passeport_copie",
              "copie_autorisation_ministre",
              "establishment_director_data_ministerial_auth_date",
              "establishment_director_data_passeport",
            ];

            const alwaysVisibleQuestions = [
              "establishment_director_data_test_valid",
              "establishment_director_data_passeport_copie",
              "copie_autorisation_ministre",
              "establishment_director_data_cin_copie",
              "establishment_director_data_date_delivrance",
              "establishment_director_data_ministerial_auth_date",
              "establishment_director_data_passeport",
              "establishment_director_data_cin_number",
              "establishment_director_data_spinner",
              "director_search",
            ];

            const page = survey.getPageByName(
              "Données de Directeur de l’Établissement"
            );

            page.questions.forEach((question) => {
              question.visible = false;

              if (isTunisian) {
                question.visible = tunisianVisibleQuestions.includes(
                  question.name
                );
              } else {
                question.visible = nonTunisianQuestions.includes(question.name);
              }

              if (
                !alwaysVisibleQuestions.includes(question.name) &&
                !isTunisian
              ) {
                question.visible = true;
              }
            });

            if (!isTunisian) {
              survey.setValue("establishment_director_data_test_valid", true);
            } else {
              survey.clearValue("establishment_director_data_passeport");
              survey.clearValue("establishment_director_data_passeport_copie");
            }
          }

          if (survey.getValue("establishment_director_data_test_valid")) {
            page.questions.map((question) => {
              if (!params?.state?.id_affaire) {
                question.readOnly = false;
              }
              if (
                question.name !== "establishment_director_data_test_valid" &&
                question.name !==
                  "establishment_director_data_passeport_copie" &&
                question.name !== "copie_autorisation_ministre" &&
                question.name !== "establishment_director_data_cin_copie" &&
                question.name !==
                  "establishment_director_data_date_delivrance" &&
                question.name !==
                  "establishment_director_data_ministerial_auth_date" &&
                question.name !== "establishment_director_data_passeport" &&
                question.name !== "establishment_director_data_cin_number" &&
                question.name !== "establishment_director_data_spinner"
                //  &&
                // question.name !== "director_search"
              ) {
                question.visible = true;
              }
              if (
                survey.getQuestionByName(
                  "establishment_director_data_nationality"
                ).value === "تونسية"
              ) {
                if (
                  question.name === "establishment_director_data_cin_copie" ||
                  question.name ===
                    "establishment_director_data_date_delivrance"
                  //    ||
                  // question.name === "director_search"
                ) {
                  question.visible = true;
                }
              } else if (
                survey.getQuestionByName(
                  "establishment_director_data_nationality"
                ).value !== "تونسية"
              ) {
                if (
                  question.name === "establishment_director_data_test_valid" ||
                  question.name ===
                    "establishment_director_data_passeport_copie" ||
                  question.name === "copie_autorisation_ministre" ||
                  question.name ===
                    "establishment_director_data_ministerial_auth_date"
                ) {
                  question.visible = true;
                }
              }
            });
          }
        }
      }
    });
  };

  const getDemandeReservDenomination = async (survey, name) => {
    const value = survey.getValue(name);
    const validation = survey.getQuestionByName("legal_entity_validate");
    const establishmentValidation = survey.getQuestionByName(
      "estblishment_validate"
    );
    try {
      const { data } = await axios.get(
        `${
          import.meta.env.VITE_APP_GEOPROD_API
        }/config/proxy/numReserv/${value}`
      );

      if (data.code === "404") {
        establishmentValidation.value = false;
        validation.value = false;

        switch (name) {
          case "legal_entity_reservation_number_official":
            survey.clearValue("legal_entity_official_name");
            survey.clearValue(
              "legal_entity_reservation_officielle_expiration_date"
            );
            break;
          case "legal_entity_reservation_number_commercial":
            survey.clearValue("legal_entity_commercial_name");
            survey.clearValue("establishment_data_commercial_name");
            survey.clearValue(
              "legal_entity_reservation_commerciale_expiration_date"
            );
            break;
          case "establishment_data_reservation_number_commercial":
            survey.clearValue("establishment_data_commercial_name");
            survey.clearValue(
              "establishment_data_reservation_commerciale_expiration_date"
            );
            break;
          default:
            break;
        }

        notification.error({
          message: "Erreur",
          description: "Numéro de réservation invalide",
          duration: 0,
        });
        return;
      }

      validation.value = true;
      establishmentValidation.value = true;
      const officialReservationNumber = survey.getQuestionByName(
        "legal_entity_reservation_number_official"
      )?.value;
      const commercialReservationNumber = survey.getQuestionByName(
        "legal_entity_reservation_number_commercial"
      )?.value;

      if (officialReservationNumber) {
        survey.setValue(
          "establishment_data_reservation_number",
          officialReservationNumber
        );
      }
      if (commercialReservationNumber) {
        survey.setValue(
          "establishment_data_reservation_number_commercial",
          commercialReservationNumber
        );
      }
      const expirationDate = dayjs(data?.date_EXPIRATION).format("YYYY-MM-DD");
      switch (name) {
        case "legal_entity_reservation_number_official":
          survey.setValue("legal_entity_official_name", data?.nomination_LATIN);
          survey.setValue(
            "legal_entity_reservation_officielle_expiration_date",
            expirationDate
          );
          break;
        case "legal_entity_reservation_number_commercial":
          survey.setValue(
            "legal_entity_commercial_name",
            data?.nomination_LATIN
          );
          survey.setValue(
            "legal_entity_reservation_commerciale_expiration_date",
            expirationDate
          );
          break;
        case "establishment_data_reservation_number_commercial":
          survey.setValue(
            "establishment_data_commercial_name",
            data?.nomination_LATIN
          );
          survey.setValue(
            "establishment_data_reservation_commerciale_expiration_date",
            expirationDate
          );
          break;
        default:
          break;
      }
    } catch (error) {
      establishmentValidation.value = false;
      validation.value = false;
      notification.error({
        message: "Erreur",
        description:
          "L'API est temporairement indisponible. Veuillez réessayer dans quelques instants.",
      });
    }
  };

  const getExtraitRegistreEntrepriseParId = async (survey, name) => {
    const validation = survey.getQuestionByName("legal_entity_validate");
    const establishmentValidation = survey.getQuestionByName(
      "estblishment_validate"
    );
    const value = survey.getValue(name);
    axios
      .get(
        `${
          import.meta.env.VITE_APP_GEOPROD_API
        }/config/proxy/registreEntrepriseParId/${value}`
      )
      .then((data) => {
        if (data?.data?.code === "404") {
          establishmentValidation.value = false;
          validation.value = false;
          if (name === "legal_entity_tax_number") {
            survey.clearValue("legal_entity_official_name");
          }

          if (name === "establishment_data_tax_number") {
            survey.clearValue("establishment_data_official_name");
          }

          return notification.error({
            message: t("Erreur"),
            description: t("Numéro d’identifiant fiscal / RNE invalide"),
            duration: 0,
          });
        }
        establishmentValidation.value = true;
        validation.value = true;
        survey.setValue(
          "legal_entity_official_name",
          data?.data?.nomAssociationLatin
        );
        survey.setValue(
          "establishment_data_official_name",
          data?.data?.nomAssociationLatin
        );
        survey.setValue(
          "legal_entity_legal_form",
          data?.data?.formeJuridiqueDesc || ""
        );
        // survey.setValue(
        //   "establishment_data_commercial_name",
        //   data?.data?.nomCommercialLatin || ""
        // );
        // setIsLoading(false);
      })
      .catch((err) => {
        // setIsLoading(false);

        notification.error({
          message:
            "L'API est temporairement indisponible. Veuillez réessayer dans quelques instants.",
        });
      });
  };

  return (
    <Spin
      spinning={isLoadingAnnexe}
      tip={t("Veuillez patientez ...")}
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 9999,
      }}
    >
      <div ref={ref}>
        <Space className={classes.rowButtonCreate}>
          {!params.state?.id_affaire && (
            <Space>
              <Button
                className={classes.buttonCreate}
                onClick={() => setConfirmModal(true)}
              >
                <SaveOutlined />
                {t("Supprimer du brouillon")}
              </Button>
              <Button
                className={classes.buttonCreate}
                onClick={async () => {
                  // setIsLoading(true);

                  const payload = {
                    current_step: 0,
                    json_prod: {
                      ...survey.data,
                    },
                  };

                  await saveAffaire(
                    payload,
                    "Brouillon",
                    setIsLoading,
                    "annexe"
                  );
                  // setIsLoading(false);
                  navigate("/brouillon");
                }}
              >
                <SaveOutlined />
                {t("Sauvegarder au brouillon")}
              </Button>{" "}
            </Space>
          )}
          <Button
            className={classes.buttonCreate}
            onClick={() => navigate("/annexes")}
          >
            <UnorderedListOutlined />
            {t("Liste de mes Annexes")}
          </Button>
        </Space>
        <Survey model={survey} />
        {/* <Modal
          open={openModal}
          width={900}
          title={
            <div
              style={{
                textAlign: "center",
                textDecoration: "underline",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span>
                Veuillez parcourir le cahier des charges jusqu&apos;à la fin
                afin de pouvoir confirmer sa lecture complète.
              </span>
              <span>
                .يرجى تصفح كراس الشروط حتى النهاية لتتمكن من تأكيد قراءته
                بالكامل
              </span>{" "}
            </div>
          }
          icon={<ExclamationCircleOutlined />}
          onOk={}
          onCancel={() => {
            setOpenModal(false);
            survey.showPreviewBeforeComplete = "showAllQuestions"; // Enable preview mode
            survey.mode = "display"; // Set mode to preview (non-editable)
            survey.render("surveyContainer");
          }}
          okText={t("Soumettre la demande")}
          okButtonProps={{
            disabled: loadingSave,
          }}
          cancelText={t("Annuler")}
          cancelButtonProps={{
            disabled: loadingSave,
          }}
        >
          <PdfViewer setConfirm={setConfirm} loadingSave={loadingSave} />
        </Modal> */}
        <DeleteBrouillonModal
          confirmModal={confirmModal}
          setConfirmModal={setConfirmModal}
        />
      </div>
    </Spin>
  );
};

export default AnnexesForm;
