import { Button, Modal } from "antd";
import React from "react";
import axiosInstance from "../../../../utils/axios";
import { useLoader } from "../../../../context/LoaderContext";
import { useTranslation } from "react-i18next";
const DeleteBrouillonModal = (props) => {
  const { t } = useTranslation();
  const { setConfirmModal, confirmModal, typeAffaire } = props;
  const { setIsLoading } = useLoader();

  const deleteBrouillon = () => {
    setIsLoading(true);
    const id_affaire =
      typeAffaire === "epfExistant"
        ? sessionStorage.getItem("idAffaireEpfExistant")
        : typeAffaire === "changementInvestisseur"
        ? sessionStorage.getItem("idAffaireChangementInvestisseur")
        : sessionStorage.getItem("id_affaire");
    const idAffaireName =
      typeAffaire === "epfExistant" ? "idAffaireEpfExistant" : "id_affaire";
    axiosInstance
      .delete(
        `${import.meta.env.VITE_APP_GEOPROD_API}/delete_affaire/${id_affaire}`,
        {
          headers: {
            Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
              ?.Acces_Token,
          },
        }
      )
      .then((res) => {
        setConfirmModal(false);
        setIsLoading(false);
        sessionStorage.removeItem(idAffaireName);
        sessionStorage.removeItem("surveyStep");
        survey.currentPageNo = 0;
        notification.success({
          message: "Votre brouillon a été supprimé avec succès",
          duration: 0,
        });
        navigate("/etablissements");
      })
      .catch(() => {
        setConfirmModal(false);
        setIsLoading(false);
      });
  };
  return (
    <Modal
      open={confirmModal}
      footer={null}
      width={600}
      onCancel={() => setConfirmModal(false)}
    >
      <h4>{t("Êtes-vous sûr de vouloir supprimer ce brouillon ?")}</h4>
      <Button type="primary" onClick={deleteBrouillon}>
        {t("Oui")}
      </Button>
      <Button
        type="primary"
        style={{ marginLeft: "1rem" }}
        onClick={() => setConfirmModal("")}
      >
        {t("Non")}
      </Button>
    </Modal>
  );
};

export default DeleteBrouillonModal;
