// src/axiosInstance.js
import axios from "axios";
import { notification } from "antd";
// Import the loader functions

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    // Hide loader on request error
    hideLoader();
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (response?.data?.erreur || response?.data?.error) {
      notification.error({
        message: "Erreur",
        duration: 4,
        closable: true,
        description:
          // response?.data?.error_message && 
          response?.data?.message
            ? response?.data?.message
            : "Désolé, une erreur est survenue. Veuillez réessayer ultérieurement.",
      });

      return Promise.reject(response);
    }

    return response;
  },
  (error) => {
    const originalRequest = error.config;
    if (error.response) {
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const headers = {
          Authorization: "Bearer " + sessionStorage.getItem("api_key"),
        };
        return axios
          .post(
            `${import.meta.env.VITE_APP_GEOPROD_API}/login`,
            {},
            {
              headers: headers,
            }
          )
          .then((res) => {
            if (res.status === 200) {
              sessionStorage.setItem("token", res.data?.Acces_Token);
              axios.defaults.headers.common["idSession"] =
                sessionStorage.getItem("token");
              originalRequest.headers["idSession"] =
                sessionStorage.getItem("token");
              return axios(originalRequest);
            }
          });
      } else {
        notification.error({
          message: "Erreur",
          duration: 4,
          closable: true,
          description:
          // && error.response?.data?.error_message
            error.response?.data?.message 
              ? error.response?.data?.message
              : "Désolé, une erreur est survenue. Veuillez réessayer ultérieurement.",
        });

        return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
