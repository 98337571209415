import { Checkbox, Spin } from "antd";
import { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Document, Page } from "react-pdf";
import GlobalContext from "../context/GlobalContext";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

const PdfViewer = ({ setConfirm, loadingSave }) => {
  const [scrollCompleted, setScrollCompleted] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { isRtl } = useContext(GlobalContext);
  const { t } = useTranslation();
  const pdfContainerRef = useRef();
  const handleScroll = () => {
    const container = pdfContainerRef.current;
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        setScrollCompleted(true);
      }
    }
  };
  const onLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setIsLoading(false);
  };

  const onLoadError = (error) => {
    console.error("Erreur lors du chargement du PDF:", error);
    setIsLoading(false);
  };

  return (
    <div style={{ direction: isRtl ? "rtl" : "" }}>
      {isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin size="large" />
        </div>
      )}
      <div
        onScroll={handleScroll}
        style={{
          height: "500px",
          overflowY: "scroll",
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
        ref={pdfContainerRef}
      >
        <Document
          file="/1404906613.pdf"
          onLoadSuccess={onLoadSuccess}
          onLoadError={onLoadError}
          loading=""
        >
          {Array.from(new Array(numPages), (_, index) => (
            <Page key={index} pageNumber={index + 1} />
          ))}
        </Document>
      </div>
      <div>
        <Checkbox
          disabled={!scrollCompleted || loadingSave}
          onChange={(e) => {
            setConfirm(e.target.checked);
          }}
        >
          <span
            style={{
              display: "inline-block",
              marginLeft: "5px",
              marginTop: "20px",
            }}
          >
            J'ai pris connaissance et accepté les termes du CDC relatifs à la
            création d'un EPF.
            <br />
            لقد قرأت ووافقت على شروط كراس الشروط المتعلقة بإنشاء هيكل تكوين خاص
          </span>
        </Checkbox>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2rem",
          }}
        >
          {loadingSave && <Spin />}
        </div>
      </div>
    </div>
  );
};

export default PdfViewer;
