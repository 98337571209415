import { Button, Form, Modal, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GlobalContext from "../../../context/GlobalContext";
import { useTranslation } from "react-i18next";
import { getAffaires } from "../../../api/geoprod";

const SelectionModal = (props) => {
  const [selectedOption, setSelectedOption] = useState(null);
  // eslint-disable-next-line react/prop-types
  const { isModalVisible, setIsModalVisible } = props;
  const { t } = useTranslation();
  const { form } = useContext(GlobalContext);
  const navigate = useNavigate();
  // const [loading, setLoading] = useState(true);

  const handleCancel = () => {
    setIsModalVisible([]);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((response) => {
        sessionStorage.removeItem("id_affaire_annexe");

        navigate("/annexes/save?affaire_id=" + response.EPFID);

        setIsModalVisible([]);
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };
  
  return (
    <Modal
      title={t("Sélectionnez la situation de l'Annexe")}
      open={isModalVisible?.length > 0}
      onOk={handleOk}
      footer={false}
      onCancel={handleCancel}
      okButtonProps={{ disabled: !selectedOption }} // Disable OK button if no option is selected
      style={{
        direction: localStorage.getItem("language") === "ar" ? "rtl" : "ltr",
      }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label={t("EPF de l'Annexe")}
          name={"EPFID"}
          rules={[
            {
              required: true,
              message: t("Veuillez selectionner un EPF."),
            },
          ]}
        >
          <Select  style={{ width: "100%" }}>
            {isModalVisible?.map((elem) => (
              <Select.Option key={elem.affaire_id} value={elem.affaire_id}>
                {elem?.establishment_data_commercial_name} -{" "}
                {elem?.real_id_affaire}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      <div style={{ textAlign: "center" }}>
        <Button
          onClick={handleOk}
          type="primary"
          style={{
            backgroundColor: "#214082",
            borderColor: "#214082",
            marginRight: "10px",
            width: "7rem",
          }}
        >
          {t("Valider")}
        </Button>

        <Button
          style={{
            backgroundColor: "#E2E9F7",
            color: "#214082",
            border: "#9BACCF 1px solid",
            marginInline: "0.5rem",
            width: "7rem",
          }}
          onClick={handleCancel}
        >
          {t("Annuler")}
        </Button>
      </div>
    </Modal>
  );
};

export default SelectionModal;
