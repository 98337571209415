import React, { useContext, useEffect, useRef } from "react";
import { Survey } from "survey-react-ui";
import { Model, CustomWidgetCollection, FunctionFactory } from "survey-core";
import GlobalContext from "../../../context/GlobalContext";
import { useLocation, useNavigate } from "react-router-dom";
import {
  fetchCodePostalList,
  fetchDelegationList,
  fetchSectorList,
  fetchSpetialityList,
  fetchSubSectorList,
} from "../../../context/FetchLists";
import {
  onCurrentPageChanged,
  onCurrentPageChanging,
  onDynamicPanelItemValueChanged,
  onErrorCustomText,
  onShowingPreview,
  onUploadFiles,
  onValueChanging,
} from "../CreateEpf/CreateEpfComponents/surveyEvents";
import DatePikckerComponent from "../CreateEpf/DatePIkcer";
import axios from "axios";
import mime from "mime";
import { useLoader } from "../../../context/LoaderContext";
import SearchInputComponent from "../CreateEpf/SearchInputComponent";
import { useTranslation } from "react-i18next";
import { notification } from "antd";
import {
  getTrainerByNationalityAndIdentity,
  saveAffaire,
  updateAffaire,
} from "../../../api/geoprod";
import ButtonComponent from "../CreateEpf/ButtonComponent";
import { checkCitizenStatusByCinDn } from "../../../api/extern";
import RefreshBarComponent from "../CreateEpf/RefreshBarComponent";
import { modifyFileLinks } from "../../../utils";

const EditEpf = () => {
  const { secteurData, gouvernoratList, nationalityList ,listAllDelegations} =
    useContext(GlobalContext);
  const { getSurveyJson, getSurveyData, setIsLoading } = useLoader();
  const ref = useRef();
  const { t } = useTranslation();
  const params = useLocation();
  const editJson = getSurveyJson();

  const surveyEdit = new Model(editJson);
  const navigate = useNavigate();
  const expressionsMap = {
    trainer_data_cin_number: {
      apiCall: (survey) => getTrainerWithCin(survey),
      maxLength: 8,
      regex: /^\d{8}$/,
      message: t("Le numéro cin doit être au format 8 chiffres(ex: 11111111)."),
    },
    trainer_data_passport: {
      apiCall: (survey) => getTrainerWithCin(survey),
    },
  };

  const buttonExpressions = {
    director_search: {
      apiCall: (survey_) => checkDNCINDirector(survey_),
    },
    management_data_search: {
      apiCall: (survey_) => getCadre(survey_),
    },
  };

  CustomWidgetCollection.Instance.addCustomWidget({
    name: "datepicker",
    title: "Date picker",
    isFit: function (question) {
      return (
        question.getType() === "text" && question.jsonObj.inputType === "date"
      );
    },
    render: function (question, element) {
      return <DatePikckerComponent question={question} survey={surveyEdit} />;
    },
  });

  CustomWidgetCollection.Instance.addCustomWidget({
    name: "refreshbar",
    title: "Refresh Bar",
    isFit: function (question) {
      return (
        question.getType() === "text" &&
        question.jsonObj.inputType === "refreshbar"
      );
    },
    render: function (question, element) {
      return <RefreshBarComponent question={question} />;
    },
  });

  CustomWidgetCollection.Instance.addCustomWidget({
    name: "callApi",
    title: "callApi",
    isFit: function (question) {
      return (
        question.getType() === "text" && question.jsonObj.inputType === "api"
      );
    },
    render: function (question, element) {
      const fieldName = question.name;

      const mapping = expressionsMap[fieldName];

      if (!mapping) {
        return null;
      }

      const { expression, apiCall, minLength, maxLength, regex, message } =
        mapping;

      if (typeof apiCall !== "function") {
        return null;
      }

      const handleClick = () => {
        const survey_ = question.survey;
        return apiCall(survey_);
      };

      return (
        <SearchInputComponent
          question={question}
          expression={expression}
          apiCall={handleClick}
          minLength={minLength}
          maxLength={maxLength}
          regex={regex}
          message={message}
          survey={surveyEdit}
        />
      );
    },
  });

  CustomWidgetCollection.Instance.addCustomWidget({
    name: "Button",
    title: "Button",
    isFit: function (question) {
      return (
        question.getType() === "text" &&
        question?.jsonObj?.inputType?.toLowerCase() === "button"
      );
    },
    render: function (question, element) {
      const expression = buttonExpressions[question.name];
      const handleClick = () => {
        const survey_ = question.survey;
        if (expression && expression.apiCall) {
          return expression.apiCall(survey_);
        }
      };

      return <ButtonComponent question={question} handleClick={handleClick} />;
    },
  });

  const displayQuestionsFunction = () => {
    surveyEdit.getAllQuestions().forEach(async (question) => {
      if (question.name === "training_nature_data") {
        const dynamicPanel = question;

        dynamicPanel?.panels.forEach(async (panel) => {
          const sectorQuestion = panel.getQuestionByName(
            "training_type_data_sector"
          );
          const subSectorQuestion = panel.getQuestionByName(
            "training_type_data_sub_sector"
          );
          const specialtyQuestion = panel.getQuestionByName(
            "training_type_data_specialty"
          );

          const selectedSector = sectorQuestion?.value;

          if (selectedSector) {
            const id = secteurData.find(
              (elem) => elem.value === selectedSector
            )?.id;
            const listSousSecteurs = await fetchSubSectorList(id);

            if (listSousSecteurs) {
              subSectorQuestion.choices = listSousSecteurs;
            } else {
              subSectorQuestion.choices = [];
            }

            const selectedSubSector = subSectorQuestion?.value;

            if (selectedSubSector) {
              const id = listSousSecteurs.find(
                (elem) => elem.value === selectedSubSector
              )?.id;
              const listSpecialiteDiplomes = await fetchSpetialityList(id);

              if (listSpecialiteDiplomes) {
                specialtyQuestion.choices = listSpecialiteDiplomes.map(
                  (item) => item[0].spetialite
                );
              } else {
                specialtyQuestion.choices = [];
              }
            }
          }
        });
      }

      if (question.name.toLowerCase().includes("_nationality")) {
        question.choices = nationalityList;
      }

      if (question.name === "training_nature_data") {
        question?.panels?.forEach((panel) => {
          panel?.questions.forEach((question) => {
            if (question.name === "training_type_data_sector") {
              question.choices = secteurData;
            }
          });
        });
      }

      if (question.name.toLowerCase().includes("_governorate")) {
        question.choices = gouvernoratList;
      }

      if (question.name?.includes("_delegation")) {
        const selectedDelegation = question.value;
        const postalCodeQuestion = surveyEdit.getQuestionByName(
          question.name.replace("_delegation", "_postal_code")
        );
  
        const idDelegation = listAllDelegations.find(
          (elem) => elem.value === selectedDelegation
        )?.id;
  
        if (idDelegation && postalCodeQuestion) {
          const listPostalCodes = await fetchCodePostalList(idDelegation);
          if (listPostalCodes) {
            postalCodeQuestion.choices = listPostalCodes.map((code) => ({
              value: code.value,
              text: code.text,
            }));
          } else {
            postalCodeQuestion.choices = [];
          }
        }
      }

      if (question.name === "trainer_data") {
        const dynamicPanel = question;

        dynamicPanel?.panels.forEach(async (panel) => {
          const governorateQuestion = panel.getQuestionByName(
            "trainer_data_governorate"
          );

          const nationalityQuestion = panel.getQuestionByName(
            "trainer_data_nationality"
          );
          const govermentQuestion = panel.getQuestionByName(
            "trainer_data_governorate"
          );
          nationalityQuestion.choices = nationalityList;

          govermentQuestion.choices = gouvernoratList;

          // }
          if (!governorateQuestion) return;
          const selectedGouvernorat = governorateQuestion.value;
          const delegateKey = "trainer_data_delegation";
          const selectedDelegate = panel.getQuestionByName(delegateKey);

          const id_goverment = gouvernoratList.find(
            (e) => e.value === selectedGouvernorat
          )?.id;
          if (id_goverment) {
            const delegations = await fetchDelegationList(id_goverment);

            selectedDelegate.choices = delegations || [];
          }

          if (surveyEdit.getValue("trainer_data_test_valid")) {
            panel.getQuestions().forEach(async (question) => {
              if (
                question.name !== "trainer_data_cin" &&
                question.name !== "trainer_data_cin_number" &&
                question.name !== "trainer_data_cin_date_delivrance" &&
                question.name !== "trainer_data_passeport" &&
                question.name !== "trainer_data_folder" &&
                question.name !== "trainer_data_test_valid" &&
                question.name !== "trainer_active" &&
                question.name !== "trainer_data_passport" &&
                question.name !== "cin_check_spinner" &&
                question.name !== "dossier_autoriser"
              ) {
                question.visible = true;
              }

              if (panel.getQuestionByName("dossier_autoriser").value === true) {
                if (question.name === "trainer_data_folder") {
                  question.visible = true;
                }
              }

              if (
                panel.getQuestionByName("trainer_data_nationality").value ===
                "تونسية"
              ) {
                if (
                  question.name === "trainer_data_cin" ||
                  question.name === "trainer_data_cin_date_delivrance"
                ) {
                  question.visible = true;
                }
              } else if (
                panel.getQuestionByName("trainer_data_nationality").value !==
                "تونسية"
              ) {
                if (
                  question.name === "trainer_data_passeport" ||
                  question.name === "trainer_data_passeport"
                ) {
                  question.visible = true;
                }
              }
            });
          }
        });
      }

      const page = surveyEdit.getPageByName(
        "Données de Directeur de l’Établissement"
      );
      if (page) {
        if (sessionStorage.getItem("role") === "directeur") {
          page.visible = false;
        }
        if (
          surveyEdit.getValue("establishment_director_data_identity") ===
          "المستثمر نفسه"
        ) {
          
          page.questions.forEach((question) => {
            if (question.name !== "establishment_director_data_identity")
              question.readOnly = true;

            if (
              question.name !== "establishment_director_data_test_valid" &&
              question.name !== "establishment_director_data_passeport_copie" &&
              question.name !== "copie_autorisation_ministre" &&
              question.name !== "establishment_director_data_cin_copie" &&
              question.name !== "establishment_director_data_date_delivrance" &&
              question.name !==
                "establishment_director_data_ministerial_auth_date" &&
              question.name !== "establishment_director_data_passeport" &&
              question.name !== "establishment_director_data_cin_number" &&
              question.name !== "establishment_director_data_spinner" &&
              question.name !== "director_search"
            ) {
              question.visible = true;
            }
            if (
              surveyEdit.getQuestionByName(
                "establishment_director_data_nationality"
              ).value === "تونسية"
            ) {
              if (
                question.name === "establishment_director_data_cin_copie" ||
                question.name === "establishment_director_data_date_delivrance"
              ) {
                question.visible = true;
              }
            } else if (
              surveyEdit.getQuestionByName(
                "establishment_director_data_nationality"
              ).value !== "تونسية"
            ) {
              if (
                question.name === "establishment_director_data_test_valid" ||
                question.name ===
                  "establishment_director_data_passeport_copie" ||
                question.name === "copie_autorisation_ministre" ||
                question.name ===
                  "establishment_director_data_ministerial_auth_date"
              ) {
                question.visible = true;
              }
            }
          });
        } else {
          if (question.name === "establishment_director_data_nationality") {
            const isTunisian = question.value === "تونسية";
            const tunisianVisibleQuestions = [
              "establishment_director_data_nationality",
              "establishment_director_data_identity",
              "establishment_director_data_cin_number",
              "establishment_director_data_birth_date",
              "director_search",
            ];

            const nonTunisianQuestions = [
              "establishment_director_data_test_valid",
              "establishment_director_data_passeport_copie",
              "copie_autorisation_ministre",
              "establishment_director_data_ministerial_auth_date",
              "establishment_director_data_passeport",
            ];

            const alwaysVisibleQuestions = [
              "establishment_director_data_test_valid",
              "establishment_director_data_passeport_copie",
              "copie_autorisation_ministre",
              "establishment_director_data_cin_copie",
              "establishment_director_data_date_delivrance",
              "establishment_director_data_ministerial_auth_date",
              "establishment_director_data_passeport",
              "establishment_director_data_cin_number",
              "establishment_director_data_spinner",
              "director_search",
            ];

            const page = surveyEdit.getPageByName(
              "Données de Directeur de l’Établissement"
            );

            page.questions.forEach((question) => {
              question.visible = false;

              if (isTunisian) {
                question.visible = tunisianVisibleQuestions.includes(
                  question.name
                );
                question.readOnly = !tunisianVisibleQuestions.includes(
                  question.name
                );
              } else {
                const nationalityIdentity = [
                  "establishment_director_data_nationality",
                  "establishment_director_data_identity",
                ];

                question.readOnly = !nationalityIdentity.includes(
                  question.name
                );

                question.visible = nonTunisianQuestions.includes(question.name);
              }

              if (
                !alwaysVisibleQuestions.includes(question.name) &&
                !isTunisian
              ) {
                question.visible = true;
              }
            });

            if (!isTunisian) {
              surveyEdit.setValue(
                "establishment_director_data_test_valid",
                true
              );
            } else {
              surveyEdit.clearValue("establishment_director_data_passeport");
              surveyEdit.clearValue(
                "establishment_director_data_passeport_copie"
              );
            }
          }

          if (surveyEdit.getValue("establishment_director_data_test_valid")) {
            page.questions.map((question) => {
              if (!params?.state?.id_affaire) {
                question.readOnly = false;
              }
              if (
                question.name !== "establishment_director_data_test_valid" &&
                question.name !==
                  "establishment_director_data_passeport_copie" &&
                question.name !== "copie_autorisation_ministre" &&
                question.name !== "establishment_director_data_cin_copie" &&
                question.name !==
                  "establishment_director_data_date_delivrance" &&
                question.name !==
                  "establishment_director_data_ministerial_auth_date" &&
                question.name !== "establishment_director_data_passeport" &&
                question.name !== "establishment_director_data_cin_number" &&
                question.name !== "establishment_director_data_spinner" &&
                question.name !== "director_search"
              ) {
                question.visible = true;
              }
              if (
                surveyEdit.getQuestionByName(
                  "establishment_director_data_nationality"
                ).value === "تونسية"
              ) {
                if (
                  question.name === "establishment_director_data_cin_copie" ||
                  question.name ===
                    "establishment_director_data_date_delivrance" ||
                  question.name === "director_search"
                ) {
                  question.visible = true;
                }
              } else if (
                surveyEdit.getQuestionByName(
                  "establishment_director_data_nationality"
                ).value !== "تونسية"
              ) {
                if (
                  question.name === "establishment_director_data_test_valid" ||
                  question.name ===
                    "establishment_director_data_passeport_copie" ||
                  question.name === "copie_autorisation_ministre" ||
                  question.name ===
                    "establishment_director_data_ministerial_auth_date"
                ) {
                  question.visible = true;
                }
              }
            });
          }
        }
      }

      if (question.name === "management_data") {
        const dynamicPanel = question;
        dynamicPanel?.panels.forEach(async (panel) => {
          const governorateQuestion = panel.getQuestionByName(
            "management_data_staff_residence_governorate"
          );
          if (!governorateQuestion) return;
          const selectedGouvernorat = governorateQuestion.value;
          const delegateKey = "management_data_staff_residence_delegation";
          const selectedDelegate = panel.getQuestionByName(delegateKey);

          const id_goverment = gouvernoratList.find(
            (e) => e.value === selectedGouvernorat
          )?.id;
          if (id_goverment) {
            const delegations = await fetchDelegationList(id_goverment);

            selectedDelegate.choices = delegations || [];
          }

          const nationalityQuestion = panel.getQuestionByName(
            "management_data_staff_nationality"
          );
          nationalityQuestion.choices = nationalityList;
          const govermentQuestion = panel.getQuestionByName(
            "management_data_staff_residence_governorate"
          );

          govermentQuestion.choices = gouvernoratList;

          panel.getQuestions().forEach((question) => {
            if (
              question.name !== "management_data_staff_cin_number" &&
              question.name !== "management_data_staff_passport_number" &&
              question.name !== "management_data_staff_date_delivrance" &&
              question.name !== "management_data_staff_cin_copy" &&
              question.name !== "management_data_staff_passport_copy" &&
              question.name !== "management_data_staff_valid" &&
              question.name !== "management_data_check_spinner" &&
              question.name !== "management_data_search" &&
              question.name !==
                "management_data_staff_date_expiration_passeport"
            ) {
              question.visible = true;
            }

            if (
              panel.getQuestionByName("management_data_staff_nationality")
                .value === "تونسية"
            ) {
              if (
                question.name === "management_data_staff_cin_copy" ||
                question.name === "management_data_staff_date_delivrance"
              ) {
                question.visible = true;
              }
            } else if (
              panel.getQuestionByName("management_data_staff_nationality")
                .value !== "تونسية"
            ) {
              if (
                question.name === "management_data_staff_passport_copy" ||
                question.name ===
                  "management_data_staff_date_expiration_passeport"
              ) {
                question.visible = true;
              }
            }
          });
        });
      }

      // const management_data = surveyEdit.getQuestionByName("management_data");
      // management_data?.panels.map((panel) => {
      //   panel.questions.map((question) => {
      //     // if (question.name === "management_data_staff_birth_date") {
      //     question.readOnly = true;
      //     if (question.name === "management_data_staff_nationality") {
      //       question.choices = nationalityList;
      //     }
      //     // }
      //   });
      // });
    });
  };

  surveyEdit.onAfterRenderQuestion.add(function (sender, options) {
    if (
      options.question.name === "investor_nationality" ||
      options.question.name === "inverstor_type" || options.question.name === "establishment_data_email"
    ) {
      displayQuestionsFunction();
    }
    if (options.question.getType() === "file") {
      setTimeout(() => {
        modifyFileLinks(surveyEdit, options);
      }, [1000]);
    }
    if (options.question.title.includes("(En arabe)")) {
      options.htmlElement.style.direction = "rtl";
    }
    const currentPage = surveyEdit.currentPage;

    currentPage.questions.forEach((question) => {
      if (
        question.name.includes("_valid") ||
        question.name.includes("_validate")
      ) {
        question.visible = false;
      }

      if (sender.isShowingPreview) {
        const managementPanel = sender.getQuestionByName("management_data");
        managementPanel?.panels.forEach((panel) => {
          const dateDelivranceQuestion = panel.getQuestionByName(
            "management_data_staff_date_delivrance"
          );
          const birthDateQuestion = panel.getQuestionByName(
            "management_data_staff_birth_date"
          );
          const search_button = panel.getQuestionByName(
            "management_data_search"
          );
          if (dateDelivranceQuestion) dateDelivranceQuestion.readOnly = true;
          if (birthDateQuestion) birthDateQuestion.readOnly = true;
          if (search_button) search_button.readOnly = true;
        });
        const trainer_data = sender.getQuestionByName("trainer_data");
        trainer_data?.panels.forEach((panel) => {
          const trainer_data_cin_number = panel.getQuestionByName(
            "trainer_data_cin_number"
          );

          if (trainer_data_cin_number) trainer_data_cin_number.readOnly = true;
        });

        if (question.inputType === "api") {
          question.readOnly = true;
        }
      }
    });

    const fileInputButton =
      options.htmlElement.querySelector(".sd-file__wrapper");
    if (fileInputButton) {
      const spanElement = fileInputButton.querySelector("span");
      if (spanElement) {
        spanElement.textContent = "Télécharger le fichier - تحميل الملف";
      }
    }
    const fileInputButtonBeforeChoose = options.htmlElement.querySelector(
      ".sd-file__choose-btn"
    );
    if (fileInputButtonBeforeChoose) {
      const spanElement = fileInputButtonBeforeChoose.querySelector("span");
      if (spanElement) {
        spanElement.textContent = "Télécharger le fichier - تحميل الملف";
      }
    }

    const emptyTextDiv = options.htmlElement.querySelector(
      ".sv-list__empty-text"
    );
    if (emptyTextDiv) {
      emptyTextDiv.textContent =
        "Veuillez d'abord remplir le champ précédent !";
    }

    const placeholderNoFile = options.htmlElement.querySelector(
      ".sd-file__decorator"
    );
    if (placeholderNoFile) {
      const placeholder = placeholderNoFile.querySelector(
        ".sd-file__drag-area-placeholder"
      );
      if (placeholder) {
        const spanElement = placeholder.querySelector(".sv-string-viewer");
        if (spanElement && spanElement.textContent === "No file selected") {
          spanElement.textContent =
            "Aucun fichier téléchargé - لم يتم تحميل أي ملف";
        }
      }
    }
  });

  const populateDelegationOnLoad = () => {
    surveyEdit.getAllQuestions().forEach(async (question) => {
      if (question.name?.includes("_governorate")) {
        const delegateKey = question.name.replace(
          "_governorate",
          "_delegation"
        );
        const selectedGouvernorat = question.value;
        const selectedDelegate = surveyEdit.getQuestionByName(delegateKey);
        const id_goverment = gouvernoratList.find(
          (e) => e.value === selectedGouvernorat
        )?.id;
        if (id_goverment) {
          const delegations = await fetchDelegationList(id_goverment);

          selectedDelegate.choices = delegations || [];
        }
      }


      if (question.name?.includes("_delegation")) {
        const selectedDelegation = question.value;
        const postalCodeQuestion = surveyEdit.getQuestionByName(
          question.name.replace("_delegation", "_postal_code")
        );
  
        const idDelegation = listAllDelegations.find(
          (elem) => elem.value === selectedDelegation
        )?.id;
  
        if (idDelegation && postalCodeQuestion) {
          const listPostalCodes = await fetchCodePostalList(idDelegation);
          if (listPostalCodes) {
            postalCodeQuestion.choices = listPostalCodes.map((code) => ({
              value: code.value,
              text: code.text,
            }));
          } else {
            postalCodeQuestion.choices = [];
          }
        }
      }

      if (question.name === "management_data") {
        const dynamicPanel = question;
        dynamicPanel?.panels.forEach(async (panel) => {
          const governorateQuestion = panel.getQuestionByName(
            "management_data_staff_residence_governorate"
          );
          if (!governorateQuestion) return;
          const selectedGouvernorat = governorateQuestion.value;
          const delegateKey = "management_data_staff_residence_delegation";
          const selectedDelegate = panel.getQuestionByName(delegateKey);

          const id_goverment = gouvernoratList.find(
            (e) => e.value === selectedGouvernorat
          )?.id;
          if (id_goverment) {
            const delegations = await fetchDelegationList(id_goverment);

            selectedDelegate.choices = delegations || [];
          }
        });
      }

      if (question.name === "trainer_data") {
        const dynamicPanel = question;

        dynamicPanel?.panels.forEach(async (panel) => {
          const governorateQuestion = panel.getQuestionByName(
            "trainer_data_governorate"
          );
          if (!governorateQuestion) return;
          const selectedGouvernorat = governorateQuestion.value;
          const delegateKey = "trainer_data_delegation";
          const selectedDelegate = panel.getQuestionByName(delegateKey);

          const id_goverment = gouvernoratList.find(
            (e) => e.value === selectedGouvernorat
          )?.id;
          if (id_goverment) {
            const delegations = await fetchDelegationList(id_goverment);

            selectedDelegate.choices = delegations || [];
          }
        });
      }
    });
  };

  onValueChanging(surveyEdit, gouvernoratList,listAllDelegations,"edit");
  onDynamicPanelItemValueChanged(
    surveyEdit,
    secteurData,
    nationalityList,
    gouvernoratList,
    "edit"
  );
  const selectedOption = sessionStorage.getItem("selectedOption");
  onUploadFiles(surveyEdit, "modification", selectedOption);
  onCurrentPageChanging(surveyEdit);
  onShowingPreview(surveyEdit);
  onErrorCustomText(surveyEdit);

  surveyEdit.onDynamicPanelRemoving.add((survey, options) => {
    const panelDynamic = survey.getQuestionByName("training_nature_data");
    if (panelDynamic.panelCount <= 1) {
      options.allow = false;
      notification.error({
        title: "Erreur",
        description: t(
          "Il est impossible de supprimer tous les types de formations, car cela entraînerait la fermeture de l'EPF."
        ),
      });
    }
  });

  // const Demande_de_modification_EPF_sans_validation = {
  //   logoPosition: "right",
  //   pages: [
  //     {
  //       name: "page5",
  //       title: "Données des Formateurs - بيانات المكونين",
  //       elements: [
  //         {
  //           type: "panel",
  //           name: "trainer",
  //           elements: [
  //             {
  //               type: "paneldynamic",
  //               name: "trainer_data",
  //               title: "Données des Formateurs - بيانات المكونين",
  //               hideNumber: true,
  //               templateElements: [
  //                 {
  //                   type: "panel",
  //                   name: "trainer_obj",
  //                   title: " Formateur - المكون {panelIndex}",
  //                   state: "expanded",
  //                   elements: [
  //                     {
  //                       type: "dropdown",
  //                       name: "trainer_data_nationality",
  //                       title: "Nationalité - الجنسية",
  //                       hideNumber: true,
  //                       defaultValue: "تونسية",
  //                       isRequired: true,
  //                       allowClear: false,
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_passport",
  //                       visibleIf:
  //                         "{panel.trainer_data_nationality} <> 'تونسية'",
  //                       startWithNewLine: false,
  //                       title: "Numéro du passeport - رقم جواز السفر",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       inputType: "api",
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_cin_number",
  //                       visibleIf:
  //                         "{panel.trainer_data_nationality} = 'تونسية'",
  //                       startWithNewLine: false,
  //                       title: "Numéro de la CIN - رقم بطاقة التعريف الوطنية",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       inputType: "api",
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_cin_date_delivrance",
  //                       visible: false,
  //                       startWithNewLine: false,
  //                       title:
  //                         "Date de délivrance de la CIN - تاريخ إصدار بطاقة التعريف الوطنية",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       readOnly: true,
  //                       inputType: "date",
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_last_name",
  //                       visible: false,
  //                       title: "Nom (En arabe) - اللقب (بالعربية)",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       readOnly: true,
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_first_name",
  //                       visible: false,
  //                       startWithNewLine: false,
  //                       title: "Prénom (En arabe) - الإسم (بالعربية)",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       readOnly: true,
  //                     },
  //                     {
  //                       type: "dropdown",
  //                       name: "trainer_data_civility",
  //                       visible: false,
  //                       startWithNewLine: false,
  //                       title: "Genre - الجنس",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       readOnly: true,
  //                       choices: [
  //                         { value: "ذكر", text: "Masculin - ذكر" },
  //                         { value: "أنثى", text: "Féminin - أنثى" },
  //                       ],
  //                       allowClear: false,
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_birth_date",
  //                       visible: false,
  //                       title: "Date de naissance - تاريخ الولادة",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       readOnly: true,
  //                       inputType: "date",
  //                       placeholder: {
  //                         default: "DD/MM/YYYY",
  //                         ar: "اليوم / الشهر / السنة",
  //                       },
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_birth_place",
  //                       visible: false,
  //                       startWithNewLine: false,
  //                       title:
  //                         "Lieu de naissance (En arabe) - مكان الولادة (بالعربية)",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       readOnly: true,
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_address",
  //                       visible: false,
  //                       startWithNewLine: false,
  //                       title: "Adresse (En arabe) - العنوان (بالعربية)",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       readOnly: true,
  //                     },
  //                     {
  //                       type: "dropdown",
  //                       name: "trainer_data_governorate",
  //                       visible: false,
  //                       title: "Gouvernorat - الولاية",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       readOnly: true,
  //                       placeholder: "",
  //                       allowClear: false,
  //                     },
  //                     {
  //                       type: "dropdown",
  //                       name: "trainer_data_delegation",
  //                       visible: false,
  //                       startWithNewLine: false,
  //                       title: "Délégation - المعتمدية",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       readOnly: true,
  //                       placeholder: "",
  //                       allowClear: false,
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_postal_code",
  //                       visible: false,
  //                       startWithNewLine: false,
  //                       title: "Code postal - الترقيم البريدي",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       readOnly: true,
  //                       maskType: "pattern",
  //                       maskSettings: { pattern: "9999" },
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_email",
  //                       visible: false,
  //                       title: "Adresse e-mail - البريد الإلكتروني",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       readOnly: true,
  //                       inputType: "email",
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_phone_number",
  //                       visible: false,
  //                       startWithNewLine: false,
  //                       title: "Numéro du téléphone - رقم الهاتف",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       readOnly: true,
  //                       maskType: "pattern",
  //                       maskSettings: { pattern: "99999999" },
  //                     },
  //                     {
  //                       type: "dropdown",
  //                       name: "trainer_data_education_level",
  //                       visible: false,
  //                       startWithNewLine: false,
  //                       title: "Niveau scolaire - المستوى الدراسي",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       readOnly: true,
  //                       choices: [
  //                         { value: "إبتدائي", text: "Primaire - إبتدائي" },
  //                         { value: "ثانوي", text: "Secondaire - ثانوي" },
  //                         { value: "جامعي", text: "Universitaire - جامعي" },
  //                         {
  //                           value: "تكوين مهني",
  //                           text: "Formation professionnelle - تكوين مهني",
  //                         },
  //                       ],
  //                       allowClear: false,
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_specialty_domain",
  //                       visible: false,
  //                       title: "Domaines de spécialité - مجالات التخصص",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       readOnly: true,
  //                     },
  //                     {
  //                       type: "dropdown",
  //                       name: "trainer_data_situation",
  //                       visible: false,
  //                       maxWidth: "50%",
  //                       startWithNewLine: false,
  //                       title: "Situation - الوضعية",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       choices: [
  //                         { value: "قار", text: "Permanent - قار" },
  //                         { value: "غير قار", text: "Vacataire - غير قار" },
  //                       ],
  //                       allowClear: false,
  //                     },
  //                     {
  //                       type: "radiogroup",
  //                       name: "trainer_data_specific_needs",
  //                       visible: false,
  //                       startWithNewLine: false,
  //                       title:
  //                         "Personne à besoin spécifique - شخص ذو إحتياجات خصوصية ",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       readOnly: true,
  //                       choices: [
  //                         { value: "نعم", text: "Oui - نعم" },
  //                         { value: "لا", text: "Non - لا" },
  //                       ],
  //                       colCount: 4,
  //                     },
  //                     {
  //                       type: "file",
  //                       name: "trainer_data_folder",
  //                       visible: false,
  //                       title:
  //                         "Dossier du formateur auprès de l'établissement - ملف المكون لدى المؤسسة ",
  //                       isRequired: true,
  //                       readOnly: true,
  //                       acceptedTypes: ".jpg,.jpeg,.png,.pdf",
  //                       storeDataAsText: false,
  //                       filePlaceholder:
  //                         "Téléchargez le dossier du formateur.\nحمل ملف المكون لدى المؤسسة \n",
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "dossier_autoriser",
  //                       visible: false,
  //                     },
  //                     {
  //                       type: "file",
  //                       name: "trainer_data_cin",
  //                       visible: false,
  //                       startWithNewLine: false,
  //                       title:
  //                         "Copie de la CIN - نسخة من بطاقة التعريف الوطنية ",
  //                       isRequired: true,
  //                       readOnly: true,
  //                       acceptedTypes: ".jpg,.jpeg,.png,.pdf",
  //                       storeDataAsText: false,
  //                       filePlaceholder:
  //                         "Téléchargez une copie de la CIN du formateur.\nحمل نسخة من بطاقة التعريف الوطنية للمكون",
  //                     },
  //                     {
  //                       type: "file",
  //                       name: "trainer_data_passeport",
  //                       visible: false,
  //                       startWithNewLine: false,
  //                       title: "Copie du passeport - نسخة من جواز السفر",
  //                       isRequired: true,
  //                       readOnly: true,
  //                       acceptedTypes: ".jpg,.jpeg,.png,.pdf",
  //                       storeDataAsText: false,
  //                       filePlaceholder:
  //                         "Téléchargez une copie du passeport du formateur.\nيرجى تحميل نسخة من جواز سفر للمكون",
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_test_valid",
  //                       visible: false,
  //                     },
  //                     { type: "text", name: "trainer_active", visible: false },
  //                     {
  //                       type: "text",
  //                       name: "cin_check_spinner",
  //                       visible: false,
  //                       startWithNewLine: false,
  //                       title: "Veuillez patientez ...",
  //                       inputType: "refreshbar",
  //                     },
  //                   ],
  //                 },
  //               ],
  //               panelCount: 1,
  //               minPanelCount: 1,
  //               panelAddText: "Ajouter un formateur - إضافة مكون",
  //               panelRemoveText: "Supprimer - إزالة",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: "page6",
  //       title: "Données des Espaces - بيانات الفضاءات",
  //       elements: [
  //         {
  //           type: "panel",
  //           name: "panel7",
  //           elements: [
  //             {
  //               type: "paneldynamic",
  //               name: "space_data",
  //               title: "Données des Espaces - بيانات الفضاءات",
  //               hideNumber: true,
  //               templateElements: [
  //                 {
  //                   type: "panel",
  //                   name: "space_obj",
  //                   title: "Espace - فضاء {panelIndex}",
  //                   state: "expanded",
  //                   elements: [
  //                     {
  //                       type: "dropdown",
  //                       name: "space_data_type",
  //                       title: "Type - النوع",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       choices: [
  //                         { value: "إدارة", text: "Administration - إدارة" },
  //                         {
  //                           value: "فضاء مشترك",
  //                           text: "Espace commun - فضاء مشترك",
  //                         },
  //                         {
  //                           value: "قاعة تدريس",
  //                           text: "Salle de classe - قاعة تدريس",
  //                         },
  //                         { value: "ورشة", text: "Atelier - ورشة" },
  //                         { value: "مخبر", text: "Laboratoire - مخبر" },
  //                       ],
  //                       showOtherItem: true,
  //                       otherText: "Autre - آخر",
  //                       placeholder: "",
  //                       allowClear: false,
  //                     },
  //                   ],
  //                 },
  //               ],
  //               panelCount: 1,
  //               minPanelCount: 1,
  //               panelAddText: "Ajouter un espace - إضافة فضاء",
  //               panelRemoveText: "Supprimer - إزالة",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: "page7",
  //       title:
  //         "Données des équipements de formation - بيانات التجهيزات التكوينية ",
  //       elements: [
  //         {
  //           type: "panel",
  //           name: "equipmets",
  //           elements: [
  //             {
  //               type: "paneldynamic",
  //               name: "equipments_data",
  //               title:
  //                 "Données des équipements de formation - بيانات التجهيزات التكوينية",
  //               hideNumber: true,
  //               isRequired: true,
  //               templateElements: [
  //                 {
  //                   type: "panel",
  //                   name: "equipement_obj",
  //                   title:
  //                     "Équipement de formation - تجهيز تكويني {panelIndex}",
  //                   state: "expanded",
  //                   elements: [
  //                     {
  //                       type: "text",
  //                       name: "equipment_label",
  //                       title: "Équipement de formation - تجهيز تكويني",
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "equipment_number",
  //                       startWithNewLine: false,
  //                       title: "Nombre - العدد",
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       inputType: "number",
  //                       min: 1,
  //                     },
  //                   ],
  //                 },
  //               ],
  //               panelCount: 1,
  //               minPanelCount: 1,
  //               panelAddText: "Ajouter un équipement - إضافة تجهيزات",
  //               panelRemoveText: "Supprimer - إزالة",
  //             },
  //             {
  //               type: "file",
  //               name: "equipment_tech_file",
  //               title:
  //                 "Fiche technique des équipements de formation -  الوثائق الفنية للتجهيزات التكوينية ",
  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               allowMultiple: true,
  //               acceptedTypes: ".jpg,.jpeg,.png,.pdf",
  //               storeDataAsText: false,
  //               filePlaceholder:
  //                 "Téléchargez les fiches techniques des équipements de formation\nتحميل الوثائق الفنية للتجهيزات التكوينية ",
  //             },
  //             {
  //               type: "html",
  //               name: "file_description",
  //               html: '<div class=\'description\'><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.4" d="M12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2Z" fill="#2BC1A7"/><path d="M12 10.25C11.59 10.25 11.25 10.59 11.25 11V16C11.25 16.41 11.59 16.75 12 16.75C12.41 16.75 12.75 16.41 12.75 16V11C12.75 10.59 12.41 10.25 12 10.25ZM11.08 8.381C11.13 8.501 11.2 8.611 11.29 8.711C11.39 8.801 11.5 8.871 11.62 8.921C11.8635 9.02102 12.1365 9.02102 12.38 8.921C12.5 8.871 12.61 8.801 12.71 8.711C12.8 8.611 12.87 8.501 12.92 8.381C12.97 8.261 13 8.131 13 8.001C13 7.871 12.97 7.741 12.92 7.621C12.87 7.491 12.8 7.391 12.71 7.291C12.61 7.201 12.5 7.131 12.38 7.081C12.26 7.031 12.13 7.001 12 7.001C11.87 7.001 11.74 7.031 11.62 7.081C11.5 7.131 11.39 7.201 11.29 7.291C11.2 7.391 11.13 7.491 11.08 7.621C11.03 7.741 11 7.871 11 8.001C11 8.131 11.03 8.261 11.08 8.381Z" fill="#194FC1"/></svg>Vous avez la possibilité de joindre plusieurs fichiers simultanément. - يمكنكم إرفاق عدة ملفات في نفس الوقت.</div>',
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  //   triggers: [
  //     {
  //       type: "runexpression",
  //       expression: "{establishment_director_data_identity} <> ''",
  //       runExpression:
  //         "checkDirectorIdentity({establishment_director_data_identity})",
  //     },
  //   ],
  //   sendResultOnPageNext: true,
  //   showCompletedPage: false,
  //   showProgressBar: "aboveheader",
  //   progressBarShowPageTitles: true,
  //   storeOthersAsComment: false,
  //   pagePrevText: "Précédant - رجوع",
  //   pageNextText: "Suivant - متابعة",
  //   completeText: "Soumettre la demande - تقديم المطلب",
  //   previewText: "Aperçu - عرض",
  //   editText: "Modifier - تعديل",
  //   showPreviewBeforeComplete: "showAllQuestions",
  // };

  // const Demande_de_modification_EPF_affectant_le_BO ={
  //   "logoPosition": "right",
  //   "pages": [
  //     {
  //       "name": "page0",
  //       "elements": [
  //         {
  //           "type": "panel",
  //           "name": "TypeInvestisseur",
  //           "elements": [
  //             {
  //               "type": "radiogroup",
  //               "name": "inverstor_type",
  //               "title": "Type de l'établissement - الصبغة القانونية",
  //               "hideNumber": true,
  //               "isRequired": true,
  //               "errorLocation": "bottom",
  //               "renderAs": "div",
  //               "choices": [
  //                 {
  //                   "value": "شخص طبيعي",
  //                   "text": "Personne physique - شخص طبيعي"
  //                 },
  //                 {
  //                   "value": "شخص معنوي",
  //                   "text": "Personne morale - شخص معنوي"
  //                 }
  //               ],
  //               "choicesOrder": "asc",
  //               "colCount": 4
  //             }
  //           ]
  //         }
  //       ],
  //       "title": "Type de l'établissement - الصبغة القانونية"
  //     },
  //     {
  //       "name": "Données de Directeur de l’Établissement",
  //       "title": "Données de Directeur de l’Établissement - بيانات مدير المؤسسة",
  //       "elements": [
  //         {
  //           "type": "panel",
  //           "name": "panel2",
  //           "elements": [
  //             {
  //               "type": "dropdown",
  //               "name": "establishment_director_data_identity",
  //               "title": "Identité - الهوية",
  //               "hideNumber": true,
  //               "isRequired": true,
  //               "errorLocation": "bottom",
  //               "choices": [
  //                 {
  //                   "value": "مدير غير المستثمر",
  //                   "text": "Directeur Autre que l’Investisseur - مدير غير المستثمر"
  //                 },
  //                 {
  //                   "value": "المستثمر نفسه",
  //                   "text": "Investisseur lui-même - المستثمر نفسه"
  //                 }
  //               ],
  //               "allowClear": false
  //             },
  //             {
  //               "type": "dropdown",
  //               "name": "establishment_director_data_nationality",
  //               "startWithNewLine": false,
  //               "title": "Nationalité - الجنسية",
  //               "hideNumber": true,
  //               "defaultValue": "تونسية",
  //               "isRequired": true,
  //               "errorLocation": "bottom",
  //               "placeholder": "",
  //               "allowClear": false
  //             },
  //             {
  //               "type": "text",
  //               "name": "establishment_director_data_cin_number",
  //               "visibleIf": "{establishment_director_data_nationality} = 'تونسية'",
  //               "title": "Numéro de la CIN - رقم بطاقة التعريف الوطنية",
  //               "hideNumber": true,
  //               "isRequired": true,
  //               "errorLocation": "bottom",
  //               "maskType": "pattern",
  //               "maskSettings": {
  //                 "pattern": "99999999"
  //               }
  //             },
  //             {
  //               "type": "text",
  //               "name": "establishment_director_data_passeport",
  //               "visibleIf": "{establishment_director_data_nationality} <> 'تونسية'",
  //               "startWithNewLine": false,
  //               "title": "Numéro du passeport - رقم جواز السفر",
  //               "hideNumber": true,
  //               "isRequired": true,
  //               "errorLocation": "bottom"
  //             },
  //             {
  //               "type": "text",
  //               "name": "establishment_director_data_birth_date",
  //               "startWithNewLine": false,
  //               "title": "Date de naissance - تاريخ الولادة",
  //               "hideNumber": true,
  //               "isRequired": true,
  //               "errorLocation": "bottom",
  //               "inputType": "date",
  //               "placeholder": {
  //                 "ar": "اليوم / الشهر / السنة",
  //                 "default": "DD/MM/YYYY"
  //               }
  //             },
  //             {
  //               "type": "text",
  //               "name": "director_search",
  //               "visibleIf": "{establishment_director_data_identity} <> 'المستثمر نفسه' and  {establishment_director_data_nationality} = 'تونسية'",
  //               "maxWidth": "10%",
  //               "startWithNewLine": false,
  //               "title": " ",
  //               "hideNumber": true,
  //               "inputType": "button"
  //             },
  //             {
  //               "type": "text",
  //               "name": "establishment_director_data_last_name",
  //               "visible": false,
  //               "title": "Nom (En arabe) - اللقب (بالعربية)",
  //               "hideNumber": true,
  //               "isRequired": true,
  //               "errorLocation": "bottom",
  //               "readOnly": true
  //             },
  //             {
  //               "type": "text",
  //               "name": "establishment_director_data_first_name",
  //               "visible": false,
  //               "startWithNewLine": false,
  //               "title": "Prénom (En arabe) - الإسم (بالعربية)",
  //               "hideNumber": true,
  //               "isRequired": true,
  //               "errorLocation": "bottom",
  //               "readOnly": true
  //             },
  //             {
  //               "type": "text",
  //               "name": "establishment_director_data_last_name_fr",
  //               "visible": false,
  //               "title": "Nom (En français) - اللقب (بالفرنسية)",
  //               "hideNumber": true,
  //               "isRequired": true,
  //               "errorLocation": "bottom"
  //             },
  //             {
  //               "type": "text",
  //               "name": "establishment_director_data_first_name_fr",
  //               "visible": false,
  //               "startWithNewLine": false,
  //               "title": "Prénom (En français) - الإسم (بالفرنسية)",
  //               "hideNumber": true,
  //               "isRequired": true,
  //               "errorLocation": "bottom"
  //             },
  //             {
  //               "type": "dropdown",
  //               "name": "establishment_director_data_genre",
  //               "visible": false,
  //               "title": "Genre - الجنس",
  //               "hideNumber": true,
  //               "isRequired": true,
  //               "errorLocation": "bottom",
  //               "choices": [
  //                 {
  //                   "value": "ذكر",
  //                   "text": "Masculin - ذكر"
  //                 },
  //                 {
  //                   "value": "أنثى",
  //                   "text": "Féminin - أنثى"
  //                 }
  //               ],
  //               "allowClear": false
  //             },
  //             {
  //               "type": "text",
  //               "name": "establishment_director_data_birth_place",
  //               "visible": false,
  //               "startWithNewLine": false,
  //               "title": "Lieu de naissance (En arabe) - مكان الولادة (بالعربية)",
  //               "hideNumber": true,
  //               "isRequired": true,
  //               "errorLocation": "bottom"
  //             },
  //             {
  //               "type": "text",
  //               "name": "establishment_director_data_ministerial_auth_date",
  //               "visible": false,
  //               "startWithNewLine": false,
  //               "title": "Date d’autorisation de ministre - تاريخ ترخيص الوزير",
  //               "hideNumber": true,
  //               "isRequired": true,
  //               "errorLocation": "bottom",
  //               "inputType": "date",
  //               "placeholder": {
  //                 "ar": "اليوم / الشهر / السنة",
  //                 "default": "DD/MM/YYYY"
  //               }
  //             },
  //             {
  //               "type": "text",
  //               "name": "establishment_director_data_date_delivrance",
  //               "visible": false,
  //               "startWithNewLine": false,
  //               "title": "Date de délivrance de la CIN - تاريخ إصدار بطاقة التعريف الوطنية",
  //               "hideNumber": true,
  //               "isRequired": true,
  //               "errorLocation": "bottom",
  //               "inputType": "date",
  //               "placeholder": {
  //                 "ar": "اليوم / الشهر / السنة",
  //                 "default": "DD/MM/YYYY"
  //               }
  //             },
  //             {
  //               "type": "text",
  //               "name": "establishment_director_data_address",
  //               "visible": false,
  //               "title": "Adresse (En arabe) - العنوان (بالعربية)",
  //               "hideNumber": true,
  //               "isRequired": true,
  //               "errorLocation": "bottom"
  //             },
  //             {
  //               "type": "dropdown",
  //               "name": "establishment_director_data_governorate",
  //               "visible": false,
  //               "startWithNewLine": false,
  //               "title": "Gouvernorat - الولاية",
  //               "hideNumber": true,
  //               "isRequired": true,
  //               "errorLocation": "bottom",
  //               "placeholder": "",
  //               "allowClear": false
  //             },
  //             {
  //               "type": "dropdown",
  //               "name": "establishment_director_data_delegation",
  //               "visible": false,
  //               "startWithNewLine": false,
  //               "title": "Délégation - المعتمدية",
  //               "hideNumber": true,
  //               "isRequired": true,
  //               "errorLocation": "bottom",
  //               "placeholder": "",
  //               "allowClear": false
  //             },
  //             {
  //               "type": "text",
  //               "name": "establishment_director_data_email",
  //               "visible": false,
  //               "title": "Adresse e-mail - البريد الإلكتروني",
  //               "hideNumber": true,
  //               "isRequired": true,
  //               "errorLocation": "bottom",
  //               "inputType": "email"
  //             },
  //             {
  //               "type": "text",
  //               "name": "establishment_director_data_phone_number",
  //               "visible": false,
  //               "maxWidth": "50%",
  //               "startWithNewLine": false,
  //               "title": "Numéro de téléphone - رقم الهاتف",
  //               "hideNumber": true,
  //               "isRequired": true,
  //               "errorLocation": "bottom",
  //               "maskType": "pattern",
  //               "maskSettings": {
  //                 "pattern": "99999999"
  //               }
  //             },
  //             {
  //               "type": "dropdown",
  //               "name": "establishment_director_data_education_level",
  //               "visible": false,
  //               "startWithNewLine": false,
  //               "title": "Niveau scolaire - المستوى الدراسي",
  //               "hideNumber": true,
  //               "isRequired": true,
  //               "errorLocation": "bottom",
  //               "choices": [
  //                 {
  //                   "value": "إبتدائي",
  //                   "text": "Primaire - إبتدائي"
  //                 },
  //                 {
  //                   "value": "ثانوي",
  //                   "text": "Secondaire - ثانوي"
  //                 },
  //                 {
  //                   "value": "جامعي",
  //                   "text": "Universitaire - جامعي"
  //                 },
  //                 {
  //                   "value": "تكوين مهني",
  //                   "text": "Formation professionnelle - تكوين مهني"
  //                 }
  //               ],
  //               "placeholder": "",
  //               "allowClear": false,
  //               "searchEnabled": false
  //             },
  //             {
  //               "type": "text",
  //               "name": "establishment_director_data_experience_years",
  //               "visible": false,
  //               "title": "Nombre d'années d'expériences professionnelles -  عدد سنوات الخبرة المهنية",
  //               "hideNumber": true,
  //               "isRequired": true,
  //               "errorLocation": "bottom",
  //               "inputType": "number",
  //               "min": 0
  //             },
  //             {
  //               "type": "radiogroup",
  //               "name": "establishment_director_data_specific_needs",
  //               "visible": false,
  //               "startWithNewLine": false,
  //               "title": "Personne à besoin spécifique - شخص ذو إحتياجات خصوصية ",
  //               "hideNumber": true,
  //               "isRequired": true,
  //               "errorLocation": "bottom",
  //               "choices": [
  //                 {
  //                   "value": "نعم",
  //                   "text": "Oui - نعم"
  //                 },
  //                 {
  //                   "value": "لا",
  //                   "text": "Non - لا"
  //                 }
  //               ],
  //               "colCount": 4
  //             },
  //             {
  //               "type": "file",
  //               "name": "establishment_director_data_director_folder",
  //               "visible": false,
  //               "title": "Dossier du directeur (CV et autres) - ملف المدير (السيرة الذاتية وغيرها)",
  //               "hideNumber": true,
  //               "isRequired": true,
  //               "errorLocation": "bottom",
  //               "allowMultiple": true,
  //               "acceptedTypes": ".jpg,.jpeg,.png,.pdf",
  //               "storeDataAsText": false,
  //               "fileOrPhotoPlaceholder": "Cliquez ou glissez pour télécharger le CV et le dossier du directeur\nانقر أو اسحب لتحميل السيرة الذاتية وملف المدير",
  //               "photoPlaceholder": "",
  //               "filePlaceholder": "Cliquez ou glissez pour télécharger le CV et le dossier du directeur\nانقر أو اسحب لتحميل السيرة الذاتية وملف المدير"
  //             },
  //             {
  //               "type": "file",
  //               "name": "establishment_director_data_cin_copie",
  //               "visible": false,
  //               "startWithNewLine": false,
  //               "title": "Copie de la CIN - نسخة من بطاقة التعريف الوطنية",
  //               "hideNumber": true,
  //               "isRequired": true,
  //               "errorLocation": "bottom",
  //               "acceptedTypes": ".jpg,.jpeg,.png,.pdf",
  //               "storeDataAsText": false,
  //               "filePlaceholder": "Télécharger la copie du CIN.\nحمّل نسخة من بطاقة التعريف الوطنية"
  //             },
  //             {
  //               "type": "file",
  //               "name": "copie_autorisation_ministre",
  //               "visible": false,
  //               "startWithNewLine": false,
  //               "title": "Copie de l’autorisation du ministre -  نسخة من ترخيص الوزير",
  //               "hideNumber": true,
  //               "isRequired": true,
  //               "errorLocation": "bottom",
  //               "acceptedTypes": ".jpg,.jpeg,.png,.pdf",
  //               "storeDataAsText": false,
  //               "filePlaceholder": "Télécharger une copie de l'autorisation du ministre\nحمل نسخة من ترخيص الوزير"
  //             },
  //             {
  //               "type": "file",
  //               "name": "establishment_director_data_passeport_copie",
  //               "visible": false,
  //               "startWithNewLine": false,
  //               "title": "Copie du passeport - نسخة من جواز السفر",
  //               "hideNumber": true,
  //               "isRequired": true,
  //               "errorLocation": "bottom",
  //               "allowMultiple": true,
  //               "acceptedTypes": ".jpg,.jpeg,.png,.pdf",
  //               "storeDataAsText": false,
  //               "filePlaceholder": "Télécharger la copie du passeport.\nحمل نسخة من جواز السفر"
  //             },
  //             {
  //               "type": "text",
  //               "name": "establishment_director_data_spinner",
  //               "visible": false,
  //               "startWithNewLine": false,
  //               "title": "Veuillez patientez ...",
  //               "inputType": "refreshbar"
  //             },
  //             {
  //               "type": "text",
  //               "name": "establishment_director_data_test_valid",
  //               "visible": false
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       "name": "page2",
  //       "elements": [
  //         {
  //           "type": "panel",
  //           "name": "panel1",
  //           "elements": [
  //             {
  //               "type": "text",
  //               "name": "establishment_data_official_name",
  //               "visibleIf": "{inverstor_type} = 'Personne morale'",
  //               "title": "Dénomination officielle - التسمية الرسمية",
  //               "hideNumber": true,
  //               "isRequired": true,
  //               "errorLocation": "bottom"
  //             },
  //             {
  //               "type": "text",
  //               "name": "establishment_data_commercial_name",
  //               "visibleIf": "{inverstor_type} <> 'Personne morale'",
  //               "startWithNewLine": false,
  //               "title": "Dénomination commerciale - التسمية التجارية",
  //               "hideNumber": true,
  //               "defaultValueExpression": "{legal_entity_commercial_name}",
  //               "errorLocation": "bottom",
  //               "readOnly": true
  //             },
  //             {
  //               "type": "text",
  //               "name": "establishment_data_address",
  //               "startWithNewLine": false,
  //               "title": "Adresse de l’établissement (En Arabe) - عنوان المؤسسة (بالعربية)",
  //               "hideNumber": true,
  //               "isRequired": true,
  //               "errorLocation": "bottom"
  //             },
  //             {
  //               "type": "dropdown",
  //               "name": "establishment_data_training_type",
  //               "startWithNewLine": false,
  //               "title": "Nature de formation - طبيعة التكوين",
  //               "hideNumber": true,
  //               "isRequired": true,
  //               "errorLocation": "bottom",
  //               "choices": [
  //                 {
  //                   "value": "أساسي",
  //                   "text": "Initiale - أساسي"
  //                 },
  //                 {
  //                   "value": "مستمر",
  //                   "text": "Continue - مستمر"
  //                 },
  //                 {
  //                   "value": "أساسي ومستمر",
  //                   "text": "Initiale et continue - أساسي ومستمر"
  //                 }
  //               ],
  //               "placeholder": "",
  //               "allowClear": false
  //             },
  //             {
  //               "type": "dropdown",
  //               "name": "establishment_data_governorate",
  //               "startWithNewLine": false,
  //               "title": "Gouvernorat - الولاية",
  //               "hideNumber": true,
  //               "isRequired": true,
  //               "errorLocation": "bottom",
  //               "readOnly": true,
  //               "placeholder": "",
  //               "allowClear": false
  //             },
  //             {
  //               "type": "dropdown",
  //               "name": "establishment_data_delegation",
  //               "title": "Délégation - المعتمدية",
  //               "hideNumber": true,
  //               "isRequired": true,
  //               "errorLocation": "bottom",
  //               "placeholder": "",
  //               "allowClear": false
  //             },
  //             {
  //               "type": "text",
  //               "name": "establishment_data_postal_code",
  //               "startWithNewLine": false,
  //               "title": "Code postal - الترقيم البريدي",
  //               "hideNumber": true,
  //               "isRequired": true,
  //               "maskType": "pattern",
  //               "maskSettings": {
  //                 "pattern": "9999"
  //               }
  //             }
  //           ]
  //         }
  //       ],
  //       "title": "Données de l’établissement de formation - معطيات مؤسسة التكوين"
  //     },
  //     {
  //       "name": "page4",
  //       "title": "Données sur la nature de formation - بيانات حول طبيعة التكوين",
  //       "elements": [
  //         {
  //           "type": "panel",
  //           "name": "panel_training",
  //           "elements": [
  //             {
  //               "type": "paneldynamic",
  //               "name": "training_nature_data",
  //               "title": "Données sur la nature de formation - بيانات حول طبيعة التكوين",
  //               "hideNumber": true,
  //               "isRequired": true,
  //               "templateElements": [
  //                 {
  //                   "type": "panel",
  //                   "name": "training_type_data",
  //                   "title": "Formation - تكوين {panel.training_type_data_nature}",
  //                   "state": "expanded",
  //                   "elements": [
  //                     {
  //                       "type": "dropdown",
  //                       "name": "training_type_data_nature",
  //                       "title": "Nature de la formation - طبيعة التكوين",
  //                       "defaultValueExpression": "{establishment_data_training_type}",
  //                       "hideNumber": true,
  //                       "enableIf": "{establishment_data_training_type} = 'أساسي ومستمر'",
  //                       "isRequired": true,
  //                       "errorLocation": "bottom",
  //                       "choices": [
  //                         {
  //                           "value": "أساسي",
  //                           "text": "Initiale - أساسي"
  //                         },
  //                         {
  //                           "value": "مستمر",
  //                           "text": "Continue - مستمر"
  //                         }
  //                       ],
  //                       "placeholder": "",
  //                       "allowClear": false
  //                     },
  //                     {
  //                       "type": "dropdown",
  //                       "name": "training_type_data_type",
  //                       "visibleIf": "{panel.training_type_data_nature} = 'أساسي'",
  //                       "startWithNewLine": false,
  //                       "title": "Type - النوع",
  //                       "isRequired": true,
  //                       "errorLocation": "bottom",
  //                       "choices": [
  //                         {
  //                           "value": "منظرة",
  //                           "text": "Homologuée - منظرة"
  //                         },
  //                         {
  //                           "value": "غير منظرة",
  //                           "text": "Non homologuée - غير منظرة"
  //                         }
  //                       ],
  //                       "placeholder": "",
  //                       "allowClear": false
  //                     },
  //                     {
  //                       "type": "dropdown",
  //                       "name": "training_type_data_sector",
  //                       "startWithNewLine": false,
  //                       "title": "Secteur - القطاع",
  //                       "allowClear": false,
  //                       "isRequired": true,
  //                       "errorLocation": "bottom",
  //                       "choices": [
  //                         {
  //                           "text": "الفلاحة - AGRICULTURE",
  //                           "value": "الفلاحة",
  //                           "id": 266
  //                         },
  //                         {
  //                           "text": "الصيد البحري وتربية الأحياء المائية - PECHE ET AQUACULTURE",
  //                           "value": "الصيد البحري وتربية الأحياء المائية",
  //                           "id": 269
  //                         },
  //                         {
  //                           "text": "البناء والأشغال العمومية و توابعه - BATIMENT, TRAVAUX PUBLICS ET ANNEXES",
  //                           "value": "البناء والأشغال العمومية و توابعه",
  //                           "id": 272
  //                         },
  //                         {
  //                           "text": "النسيج والإكسـاء - TEXTILE ET HABILLEMENT",
  //                           "value": "النسيج والإكسـاء",
  //                           "id": 275
  //                         },
  //                         {
  //                           "text": "الجلود و الأحذية - CUIR ET CHAUSSURES",
  //                           "value": "الجلود و الأحذية",
  //                           "id": 278
  //                         },
  //                         {
  //                           "text": "الآلية العامة و التركيب المعدني - MECANIQUE GENERALE ET CONSTRUCTION METALLIQUE",
  //                           "value": "الآلية العامة و التركيب المعدني",
  //                           "id": 281
  //                         },
  //                         {
  //                           "text": "الكهرباء والإلكترونيك - ELECTRICITE ET ELECTRONIQUE",
  //                           "value": "الكهرباء والإلكترونيك",
  //                           "id": 284
  //                         },
  //                         {
  //                           "text": "الصنــــاعـــــات الغــــذائيــــــة ومهن الاطعام  - AGRO-ALIMENTAIRE ET METIERS DE BOUCHE",
  //                           "value": "الصنــــاعـــــات الغــــذائيــــــة ومهن الاطعام ",
  //                           "id": 287
  //                         },
  //                         {
  //                           "text": "النقل والخدمات اللوجستية - TRANSPORT ET LOGISTIQUE",
  //                           "value": "النقل والخدمات اللوجستية",
  //                           "id": 290
  //                         },
  //                         {
  //                           "text": "السياحة و الفندقة - TOURISME ET HOTELLERIE",
  //                           "value": "السياحة و الفندقة",
  //                           "id": 293
  //                         },
  //                         {
  //                           "text": "الحرف الفنية والتقليدية - METIERS D'ART ET DE L'ARTISANAT",
  //                           "value": "الحرف الفنية والتقليدية",
  //                           "id": 296
  //                         },
  //                         {
  //                           "text": "  الوظائف الإدارية والتجارة-EMPLOIS DE BUREAU ET COMMERCE ",
  //                           "value": " الوظائف الإدارية والتجارة",
  //                           "id": 299
  //                         },
  //                         {
  //                           "text": "  الصناعات والمهن المختلفة - SERVICES ET INDUSTRIES DIVERS",
  //                           "value": "  الصناعات والمهن المختلفة",
  //                           "id": 302
  //                         },
  //                         {
  //                           "text": "التجــهيز الصحـي والتبريد وتكـييف الهـــواء - SANITAIRE FROID ET CLIMATISATION",
  //                           "value": "التجــهيز الصحـي والتبريد وتكـييف الهـــواء",
  //                           "id": 308
  //                         },
  //                         {
  //                           "text": "الخشـــــــب و الأثـــــــــاث - BOIS ET AMEUBLEMENT",
  //                           "value": "الخشـــــــب و الأثـــــــــاث",
  //                           "id": 311
  //                         },
  //                         {
  //                           "text": "صيانة العربات والمعدات الثقيلة - MAINTENANCE DES VEHICULES ET DES ENGEINS",
  //                           "value": "صيانة العربات والمعدات الثقيلة",
  //                           "id": 314
  //                         },
  //                         {
  //                           "text": "فنون الطباعة - ARTS GRAPHIQUES",
  //                           "value": "فنون الطباعة",
  //                           "id": 317
  //                         },
  //                         {
  //                           "text": "الإختـصـاصات شـــبه الطــــبيّة، الصناعات الصيدلية ومهن ذوي الاحتياجات - SPÉCIALITES PARA - MEDICALE",
  //                           "value": "الإختـصـاصات شـــبه الطــــبيّة، الصناعات الصيدلية ومهن ذوي الاحتياجات",
  //                           "id": 320
  //                         },
  //                         {
  //                           "text": " تكنولوجيا المعلومات والاتصالات - TECHNOLOGIE DE L'INFORMATION ET DE LA COMMUNICATION ",
  //                           "value": " تكنولوجيا المعلومات والاتصالات",
  //                           "id": 323
  //                         },
  //                         {
  //                           "text": "مهن الثقافة والسمعي والبصري - METIERS DE LA CULTURE ET D'AUDIO VISUEL ",
  //                           "value": "مهن الثقافة والسمعي والبصري",
  //                           "id": 326
  //                         }
  //                       ]
  //                     },
  //                     {
  //                       "type": "dropdown",
  //                       "name": "training_type_data_sub_sector",
  //                       "startWithNewLine": true,
  //                       "title": "Sous-secteur - القطاع الفرعي",
  //                       "allowClear": false,
  //                       "isRequired": true,
  //                       "errorLocation": "bottom",
  //                       "choices": [

  //                       ]
  //                     },
  //                     {
  //                       "type": "text",
  //                       "name": "training_type_data_domain",
  //                       "visibleIf": "({panel.training_type_data_type} = 'غير منظرة') or ({panel.training_type_data_nature} = 'مستمر')",
  //                       "startWithNewLine": false,
  //                       "title": "Domaine (En arabe) - المجال (بالعربية)",
  //                       "hideNumber": true,
  //                       "isRequired": true,
  //                       "errorLocation": "bottom"
  //                     },
  //                     {
  //                       "type": "dropdown",
  //                       "name": "training_type_data_specialty",
  //                       "visibleIf": "{panel.training_type_data_type} = 'منظرة'",
  //                       "title": "Spécialité - الإختصاص",
  //                       "isRequired": true,
  //                       "errorLocation": "bottom",
  //                       "choices": [

  //                       ],
  //                       "allowClear": false,
  //                       "startWithNewLine": false
  //                     },
  //                     {
  //                       "type": "text",
  //                       "name": "training_type_data_diploma",
  //                       "visibleIf": "{panel.training_type_data_type} = 'منظرة'",
  //                       "startWithNewLine": true,
  //                       "title": "Diplôme délivré - الشهادة المسلمة ",
  //                       "readOnly": true,
  //                       "isRequired": true,
  //                       "errorLocation": "bottom"
  //                     },
  //                     {
  //                       "type": "dropdown",
  //                       "name": "training_type_data_required_level",
  //                       "visibleIf": "{panel.training_type_data_type} = 'منظرة'",
  //                       "startWithNewLine": false,
  //                       "title": "Niveau requis - المستوى الادنى المطلوب ",
  //                       "allowClear": false,
  //                       "isRequired": true,
  //                       "errorLocation": "bottom",
  //                       "choices": [
  //                         {
  //                           "value": "Certificat de compétence (CC) - شهادة المهارة",
  //                           "text": "Certificat de compétence (CC) - شهادة المهارة"
  //                         },
  //                         {
  //                           "value": "Certificat d'Aptitude Professionnelle (CAP) - شهادة الكفاءة المهنية",
  //                           "text": "Certificat d'Aptitude Professionnelle (CAP) - شهادة الكفاءة المهنية"
  //                         },
  //                         {
  //                           "value": "Brevet du Technicien Professionnel (BTP) - شهادة مؤهل التقني المهني",
  //                           "text": "Brevet du Technicien Professionnel (BTP) - شهادة مؤهل التقني المهني"
  //                         }
  //                       ]
  //                     },
  //                     {
  //                       "type": "text",
  //                       "name": "training_type_data_duration_hour",
  //                       "title": "Durée en (H) - المدة بالساعات (س)",
  //                       "isRequired": true,
  //                       "errorLocation": "bottom",
  //                       "inputType": "number",
  //                       "min": 0,
  //                       "startWithNewLine": true
  //                     },
  //                     {
  //                       "type": "text",
  //                       "name": "training_type_data_duration_month",
  //                       "startWithNewLine": false,
  //                       "title": "Durée en mois - المدة بالاشهر",
  //                       "visibleIf": "{panel.training_type_data_nature} != 'مستمر'",
  //                       "isRequired": true,
  //                       "errorLocation": "bottom",
  //                       "inputType": "number",
  //                       "min": 0
  //                     },
  //                     {
  //                       "type": "text",
  //                       "name": "training_type_data_duration_minimum",
  //                       "visibleIf": "({panel.training_type_data_nature} != 'مستمر') and ({panel.training_type_data_type} != 'غير منظرة')",
  //                       "startWithNewLine": false,
  //                       "title": "Durée minimale en (H) - (المدة الدنيا بالساعات (س",
  //                       "isRequired": true,
  //                       "errorLocation": "bottom",
  //                       "inputType": "number",
  //                       "min": 0
  //                     },
  //                     {
  //                       "type": "dropdown",
  //                       "name": "training_type_data_mode",
  //                       "visibleIf": "{panel.training_type_data_nature} = 'أساسي'",
  //                       "maxWidth": "50%",
  //                       "startWithNewLine": false,
  //                       "title": "Mode de formation - نمط التكوين",
  //                       "isRequired": true,
  //                       "errorLocation": "bottom",
  //                       "choices": [
  //                         {
  //                           "value": "حضوري",
  //                           "text": "Présentielle - حضوري"
  //                         },
  //                         {
  //                           "value": "عن بعد",
  //                           "text": "À distance - عن بعد"
  //                         },
  //                         {
  //                           "value": "بالتداول",
  //                           "text": "En alternance - بالتداول"
  //                         }
  //                       ],
  //                       "placeholder": "",
  //                       "allowClear": false,
  //                       "searchEnabled": false
  //                     }
  //                   ]
  //                 }
  //               ],
  //               "panelCount": 1,
  //               "minPanelCount": 1,
  //               "panelAddText": "Ajouter une formation - إضافة تكوين",
  //               "panelRemoveText": "Supprimer - إزالة"
  //             }
  //           ]
  //         }
  //       ]
  //     }
  //   ],
  //   "triggers": [
  //     {
  //       "type": "runexpression",
  //       "expression": "{establishment_director_data_identity} <> ''",
  //       "runExpression": "checkDirectorIdentity({establishment_director_data_identity})"
  //     }
  //   ],
  //   "sendResultOnPageNext": true,
  //   "showCompletedPage": false,
  //   "showProgressBar": "aboveheader",
  //   "progressBarShowPageTitles": true,
  //   "storeOthersAsComment": false,
  //   "pagePrevText": "Précédant - رجوع",
  //   "pageNextText": "Suivant - متابعة",
  //   "completeText": "Soumettre la demande - تقديم المطلب",
  //   "previewText": "Aperçu - عرض",
  //   "editText": "Modifier - تعديل",
  //   "showPreviewBeforeComplete": "showAllQuestions"
  // };

  // const Demande_de_modification_EPF_sans_impact_sur_le_BO = {
  //   logoPosition: "right",
  //   pages: [
  //     {
  //       name: "page0",
  //       title: "Type de l'établissement - الصبغة القانونية",
  //       elements: [
  //         {
  //           type: "panel",
  //           name: "TypeInvestisseur",
  //           elements: [
  //             {
  //               type: "radiogroup",
  //               name: "inverstor_type",
  //               title: "Type de l'établissement - الصبغة القانونية",
  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               renderAs: "div",
  //               choices: [
  //                 { value: "شخص طبيعي", text: "Personne physique - شخص طبيعي" },
  //                 { value: "شخص معنوي", text: "Personne morale - شخص معنوي" },
  //               ],
  //               choicesOrder: "asc",
  //               colCount: 4,
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: "page4",
  //       title: "Données sur la nature de formation - بيانات حول طبيعة التكوين",
  //       elements: [
  //         {
  //           type: "panel",
  //           name: "panel_training",
  //           elements: [
  //             {
  //               type: "paneldynamic",
  //               name: "training_nature_data",
  //               title:
  //                 "Données sur la nature de formation - بيانات حول طبيعة التكوين",
  //               hideNumber: true,
  //               isRequired: true,
  //               templateElements: [
  //                 {
  //                   type: "panel",
  //                   name: "training_type_data",
  //                   title:
  //                     "Formation - تكوين {panel.training_type_data_nature}",
  //                   state: "expanded",
  //                   elements: [
  //                     {
  //                       type: "dropdown",
  //                       name: "training_type_data_nature",
  //                       title: "Nature de la formation - طبيعة التكوين",
  //                       hideNumber: true,
  //                       enableIf:
  //                         "{establishment_data_training_type} = 'أساسي ومستمر'",
  //                       defaultValueExpression:
  //                         "{establishment_data_training_type}",
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       choices: [
  //                         { value: "أساسي", text: "Initiale - أساسي" },
  //                         { value: "مستمر", text: "Continue - مستمر" },
  //                       ],
  //                       placeholder: "",
  //                       allowClear: false,
  //                     },
  //                     {
  //                       type: "dropdown",
  //                       name: "training_type_data_type",
  //                       visibleIf:
  //                         "{panel.training_type_data_nature} = 'أساسي'",
  //                       startWithNewLine: false,
  //                       title: "Type - النوع",
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       choices: [
  //                         { value: "منظرة", text: "Homologuée - منظرة" },
  //                         {
  //                           value: "غير منظرة",
  //                           text: "Non homologuée - غير منظرة",
  //                         },
  //                       ],
  //                       placeholder: "",
  //                       allowClear: false,
  //                     },
  //                     {
  //                       type: "dropdown",
  //                       name: "training_type_data_sector",
  //                       startWithNewLine: false,
  //                       title: "Secteur - القطاع",
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       allowClear: false,
  //                     },
  //                     {
  //                       type: "dropdown",
  //                       name: "training_type_data_sub_sector",
  //                       title: "Sous-secteur - القطاع الفرعي",
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       allowClear: false,
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "training_type_data_domain",
  //                       visibleIf:
  //                         "({panel.training_type_data_type} = 'غير منظرة') or ({panel.training_type_data_nature} = 'مستمر')",
  //                       startWithNewLine: false,
  //                       title: "Domaine (En arabe) - المجال (بالعربية)",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                     },
  //                     {
  //                       type: "dropdown",
  //                       name: "training_type_data_specialty",
  //                       visibleIf: "{panel.training_type_data_type} = 'منظرة'",
  //                       startWithNewLine: false,
  //                       title: "Spécialité - الإختصاص",
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       allowClear: false,
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "training_type_data_diploma",
  //                       visibleIf: "{panel.training_type_data_type} = 'منظرة'",
  //                       title: "Diplôme délivré - الشهادة المسلمة ",
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       readOnly: true,
  //                     },
  //                     {
  //                       type: "dropdown",
  //                       name: "training_type_data_required_level",
  //                       visibleIf: "{panel.training_type_data_type} = 'منظرة'",
  //                       startWithNewLine: false,
  //                       title: "Niveau requis - المستوى الادنى المطلوب ",
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       choices: [
  //                         "Certificat de compétence (CC) - شهادة المهارة",
  //                         "Certificat d'Aptitude Professionnelle (CAP) - شهادة الكفاءة المهنية",
  //                         "Brevet du Technicien Professionnel (BTP) - شهادة مؤهل التقني المهني",
  //                       ],
  //                       allowClear: false,
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "training_type_data_duration_hour",
  //                       title: "Durée en (H) - المدة بالساعات (س)",
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       inputType: "number",
  //                       min: 0,
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "training_type_data_duration_month",
  //                       visibleIf:
  //                         "{panel.training_type_data_nature} != 'مستمر'",
  //                       startWithNewLine: false,
  //                       title: "Durée en mois - المدة بالاشهر",
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       inputType: "number",
  //                       min: 0,
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "training_type_data_duration_minimum",
  //                       visibleIf:
  //                         "({panel.training_type_data_nature} != 'مستمر') and ({panel.training_type_data_type} != 'غير منظرة')",
  //                       startWithNewLine: false,
  //                       title:
  //                         "Durée minimale en (H) - (المدة الدنيا بالساعات (س",
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       inputType: "number",
  //                       min: 0,
  //                     },
  //                     {
  //                       type: "dropdown",
  //                       name: "training_type_data_mode",
  //                       visibleIf:
  //                         "{panel.training_type_data_nature} = 'أساسي'",
  //                       maxWidth: "50%",
  //                       startWithNewLine: false,
  //                       title: "Mode de formation - نمط التكوين",
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       choices: [
  //                         { value: "حضوري", text: "Présentielle - حضوري" },
  //                         { value: "عن بعد", text: "À distance - عن بعد" },
  //                         {
  //                           value: "بالتداول",
  //                           text: "En alternance - بالتداول",
  //                         },
  //                       ],
  //                       placeholder: "",
  //                       allowClear: false,
  //                       searchEnabled: false,
  //                     },
  //                   ],
  //                 },
  //               ],
  //               panelCount: 1,
  //               minPanelCount: 1,
  //               panelAddText: "Ajouter une formation - إضافة تكوين",
  //               panelRemoveText: "Supprimer - إزالة",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: "page5",
  //       visible: false,
  //       title: "Données des Formateurs - بيانات المكونين",
  //       elements: [
  //         {
  //           type: "panel",
  //           name: "trainer",
  //           elements: [
  //             {
  //               type: "paneldynamic",
  //               name: "trainer_data",
  //               title: "Données des Formateurs - بيانات المكونين",
  //               hideNumber: true,
  //               templateElements: [
  //                 {
  //                   type: "panel",
  //                   name: "trainer_obj",
  //                   title: " Formateur - المكون {panelIndex}",
  //                   state: "expanded",
  //                   elements: [
  //                     {
  //                       type: "dropdown",
  //                       name: "trainer_data_nationality",
  //                       title: "Nationalité - الجنسية",
  //                       hideNumber: true,
  //                       defaultValue: "تونسية",
  //                       isRequired: true,
  //                       allowClear: false,
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_passport",
  //                       visibleIf:
  //                         "{panel.trainer_data_nationality} <> 'تونسية'",
  //                       startWithNewLine: false,
  //                       title: "Numéro du passeport - رقم جواز السفر",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       inputType: "api",
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_cin_number",
  //                       visibleIf:
  //                         "{panel.trainer_data_nationality} = 'تونسية'",
  //                       startWithNewLine: false,
  //                       title: "Numéro de la CIN - رقم بطاقة التعريف الوطنية",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       inputType: "api",
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_cin_date_delivrance",
  //                       visible: false,
  //                       startWithNewLine: false,
  //                       title:
  //                         "Date de délivrance de la CIN - تاريخ إصدار بطاقة التعريف الوطنية",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       readOnly: true,
  //                       inputType: "date",
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_last_name",
  //                       visible: false,
  //                       title: "Nom (En arabe) - اللقب (بالعربية)",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       readOnly: true,
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_first_name",
  //                       visible: false,
  //                       startWithNewLine: false,
  //                       title: "Prénom (En arabe) - الإسم (بالعربية)",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       readOnly: true,
  //                     },
  //                     {
  //                       type: "dropdown",
  //                       name: "trainer_data_civility",
  //                       visible: false,
  //                       startWithNewLine: false,
  //                       title: "Genre - الجنس",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       readOnly: true,
  //                       choices: [
  //                         { value: "ذكر", text: "Masculin - ذكر" },
  //                         { value: "أنثى", text: "Féminin - أنثى" },
  //                       ],
  //                       allowClear: false,
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_birth_date",
  //                       visible: false,
  //                       title: "Date de naissance - تاريخ الولادة",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       readOnly: true,
  //                       inputType: "date",
  //                       placeholder: {
  //                         default: "DD/MM/YYYY",
  //                         ar: "اليوم / الشهر / السنة",
  //                       },
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_birth_place",
  //                       visible: false,
  //                       startWithNewLine: false,
  //                       title:
  //                         "Lieu de naissance (En arabe) - مكان الولادة (بالعربية)",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       readOnly: true,
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_address",
  //                       visible: false,
  //                       startWithNewLine: false,
  //                       title: "Adresse (En arabe) - العنوان (بالعربية)",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       readOnly: true,
  //                     },
  //                     {
  //                       type: "dropdown",
  //                       name: "trainer_data_governorate",
  //                       visible: false,
  //                       title: "Gouvernorat - الولاية",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       readOnly: true,
  //                       placeholder: "",
  //                       allowClear: false,
  //                     },
  //                     {
  //                       type: "dropdown",
  //                       name: "trainer_data_delegation",
  //                       visible: false,
  //                       startWithNewLine: false,
  //                       title: "Délégation - المعتمدية",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       readOnly: true,
  //                       placeholder: "",
  //                       allowClear: false,
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_postal_code",
  //                       visible: false,
  //                       startWithNewLine: false,
  //                       title: "Code postal - الترقيم البريدي",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       readOnly: true,
  //                       maskType: "pattern",
  //                       maskSettings: { pattern: "9999" },
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_email",
  //                       visible: false,
  //                       title: "Adresse e-mail - البريد الإلكتروني",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       readOnly: true,
  //                       inputType: "email",
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_phone_number",
  //                       visible: false,
  //                       startWithNewLine: false,
  //                       title: "Numéro du téléphone - رقم الهاتف",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       readOnly: true,
  //                       maskType: "pattern",
  //                       maskSettings: { pattern: "99999999" },
  //                     },
  //                     {
  //                       type: "dropdown",
  //                       name: "trainer_data_education_level",
  //                       visible: false,
  //                       startWithNewLine: false,
  //                       title: "Niveau scolaire - المستوى الدراسي",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       readOnly: true,
  //                       choices: [
  //                         { value: "إبتدائي", text: "Primaire - إبتدائي" },
  //                         { value: "ثانوي", text: "Secondaire - ثانوي" },
  //                         { value: "جامعي", text: "Universitaire - جامعي" },
  //                         {
  //                           value: "تكوين مهني",
  //                           text: "Formation professionnelle - تكوين مهني",
  //                         },
  //                       ],
  //                       allowClear: false,
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_specialty_domain",
  //                       visible: false,
  //                       title: "Domaines de spécialité - مجالات التخصص",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       readOnly: true,
  //                     },
  //                     {
  //                       type: "dropdown",
  //                       name: "trainer_data_situation",
  //                       visible: false,
  //                       maxWidth: "50%",
  //                       startWithNewLine: false,
  //                       title: "Situation - الوضعية",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       choices: [
  //                         { value: "قار", text: "Permanent - قار" },
  //                         { value: "غير قار", text: "Vacataire - غير قار" },
  //                       ],
  //                       allowClear: false,
  //                     },
  //                     {
  //                       type: "radiogroup",
  //                       name: "trainer_data_specific_needs",
  //                       visible: false,
  //                       startWithNewLine: false,
  //                       title:
  //                         "Personne à besoin spécifique - شخص ذو إحتياجات خصوصية ",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       readOnly: true,
  //                       choices: [
  //                         { value: "نعم", text: "Oui - نعم" },
  //                         { value: "لا", text: "Non - لا" },
  //                       ],
  //                       colCount: 4,
  //                     },
  //                     {
  //                       type: "file",
  //                       name: "trainer_data_folder",
  //                       visible: false,
  //                       title:
  //                         "Dossier du formateur auprès de l'établissement - ملف المكون لدى المؤسسة ",
  //                       isRequired: true,
  //                       readOnly: true,
  //                       acceptedTypes: ".jpg,.jpeg,.png,.pdf",
  //                       storeDataAsText: false,
  //                       filePlaceholder:
  //                         "Téléchargez le dossier du formateur.\nحمل ملف المكون لدى المؤسسة \n",
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "dossier_autoriser",
  //                       visible: false,
  //                     },
  //                     {
  //                       type: "file",
  //                       name: "trainer_data_cin",
  //                       visible: false,
  //                       startWithNewLine: false,
  //                       title:
  //                         "Copie de la CIN - نسخة من بطاقة التعريف الوطنية ",
  //                       isRequired: true,
  //                       readOnly: true,
  //                       acceptedTypes: ".jpg,.jpeg,.png,.pdf",
  //                       storeDataAsText: false,
  //                       filePlaceholder:
  //                         "Téléchargez une copie de la CIN du formateur.\nحمل نسخة من بطاقة التعريف الوطنية للمكون",
  //                     },
  //                     {
  //                       type: "file",
  //                       name: "trainer_data_passeport",
  //                       visible: false,
  //                       startWithNewLine: false,
  //                       title: "Copie du passeport - نسخة من جواز السفر",
  //                       isRequired: true,
  //                       readOnly: true,
  //                       acceptedTypes: ".jpg,.jpeg,.png,.pdf",
  //                       storeDataAsText: false,
  //                       filePlaceholder:
  //                         "Téléchargez une copie du passeport du formateur.\nيرجى تحميل نسخة من جواز سفر للمكون",
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_test_valid",
  //                       visible: false,
  //                     },
  //                     { type: "text", name: "trainer_active", visible: false },
  //                     {
  //                       type: "text",
  //                       name: "cin_check_spinner",
  //                       visible: false,
  //                       startWithNewLine: false,
  //                       title: "Veuillez patientez ...",
  //                       inputType: "refreshbar",
  //                     },
  //                   ],
  //                 },
  //               ],
  //               panelCount: 1,
  //               minPanelCount: 1,
  //               panelAddText: "Ajouter un formateur - إضافة مكون",
  //               panelRemoveText: "Supprimer - إزالة",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: "page6",
  //       visible: false,
  //       title: "Données des Espaces - بيانات الفضاءات",
  //       elements: [
  //         {
  //           type: "panel",
  //           name: "panel7",
  //           elements: [
  //             {
  //               type: "paneldynamic",
  //               name: "space_data",
  //               title: "Données des Espaces - بيانات الفضاءات",
  //               hideNumber: true,
  //               templateElements: [
  //                 {
  //                   type: "panel",
  //                   name: "space_obj",
  //                   title: "Espace - فضاء {panelIndex}",
  //                   state: "expanded",
  //                   elements: [
  //                     {
  //                       type: "dropdown",
  //                       name: "space_data_type",
  //                       title: "Type - النوع",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       choices: [
  //                         { value: "إدارة", text: "Administration - إدارة" },
  //                         {
  //                           value: "فضاء مشترك",
  //                           text: "Espace commun - فضاء مشترك",
  //                         },
  //                         {
  //                           value: "قاعة تدريس",
  //                           text: "Salle de classe - قاعة تدريس",
  //                         },
  //                         { value: "ورشة", text: "Atelier - ورشة" },
  //                         { value: "مخبر", text: "Laboratoire - مخبر" },
  //                       ],
  //                       showOtherItem: true,
  //                       otherText: "Autre - آخر",
  //                       placeholder: "",
  //                       allowClear: false,
  //                     },
  //                   ],
  //                 },
  //               ],
  //               panelCount: 1,
  //               minPanelCount: 1,
  //               panelAddText: "Ajouter un espace - إضافة فضاء",
  //               panelRemoveText: "Supprimer - إزالة",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: "page7",
  //       visible: false,
  //       title:
  //         "Données des équipements de formation - بيانات التجهيزات التكوينية ",
  //       elements: [
  //         {
  //           type: "panel",
  //           name: "equipmets",
  //           elements: [
  //             {
  //               type: "paneldynamic",
  //               name: "equipments_data",
  //               title:
  //                 "Données des équipements de formation - بيانات التجهيزات التكوينية",
  //               hideNumber: true,
  //               isRequired: true,
  //               templateElements: [
  //                 {
  //                   type: "panel",
  //                   name: "equipement_obj",
  //                   title:
  //                     "Équipement de formation - تجهيز تكويني {panelIndex}",
  //                   state: "expanded",
  //                   elements: [
  //                     {
  //                       type: "text",
  //                       name: "equipment_label",
  //                       title: "Équipement de formation - تجهيز تكويني",
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "equipment_number",
  //                       startWithNewLine: false,
  //                       title: "Nombre - العدد",
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       inputType: "number",
  //                       min: 1,
  //                     },
  //                   ],
  //                 },
  //               ],
  //               panelCount: 1,
  //               minPanelCount: 1,
  //               panelAddText: "Ajouter un équipement - إضافة تجهيزات",
  //               panelRemoveText: "Supprimer - إزالة",
  //             },
  //             {
  //               type: "file",
  //               name: "equipment_tech_file",
  //               title:
  //                 "Fiche technique des équipements de formation -  الوثائق الفنية للتجهيزات التكوينية ",
  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               allowMultiple: true,
  //               acceptedTypes: ".jpg,.jpeg,.png,.pdf",
  //               storeDataAsText: false,
  //               filePlaceholder:
  //                 "Téléchargez les fiches techniques des équipements de formation\nتحميل الوثائق الفنية للتجهيزات التكوينية ",
  //             },
  //             {
  //               type: "html",
  //               name: "file_description",
  //               html: '<div class=\'description\'><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.4" d="M12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2Z" fill="#2BC1A7"/><path d="M12 10.25C11.59 10.25 11.25 10.59 11.25 11V16C11.25 16.41 11.59 16.75 12 16.75C12.41 16.75 12.75 16.41 12.75 16V11C12.75 10.59 12.41 10.25 12 10.25ZM11.08 8.381C11.13 8.501 11.2 8.611 11.29 8.711C11.39 8.801 11.5 8.871 11.62 8.921C11.8635 9.02102 12.1365 9.02102 12.38 8.921C12.5 8.871 12.61 8.801 12.71 8.711C12.8 8.611 12.87 8.501 12.92 8.381C12.97 8.261 13 8.131 13 8.001C13 7.871 12.97 7.741 12.92 7.621C12.87 7.491 12.8 7.391 12.71 7.291C12.61 7.201 12.5 7.131 12.38 7.081C12.26 7.031 12.13 7.001 12 7.001C11.87 7.001 11.74 7.031 11.62 7.081C11.5 7.131 11.39 7.201 11.29 7.291C11.2 7.391 11.13 7.491 11.08 7.621C11.03 7.741 11 7.871 11 8.001C11 8.131 11.03 8.261 11.08 8.381Z" fill="#194FC1"/></svg>Vous avez la possibilité de joindre plusieurs fichiers simultanément. - يمكنكم إرفاق عدة ملفات في نفس الوقت.</div>',
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  //   triggers: [
  //     {
  //       type: "runexpression",
  //       expression: "{establishment_director_data_identity} <> ''",
  //       runExpression:
  //         "checkDirectorIdentity({establishment_director_data_identity})",
  //     },
  //   ],
  //   sendResultOnPageNext: true,
  //   showCompletedPage: false,
  //   showProgressBar: "aboveheader",
  //   progressBarShowPageTitles: true,
  //   storeOthersAsComment: false,
  //   pagePrevText: "Précédant - رجوع",
  //   pageNextText: "Suivant - متابعة",
  //   completeText: "Soumettre la demande - تقديم المطلب",
  //   previewText: "Aperçu - عرض",
  //   editText: "Modifier - تعديل",
  //   showPreviewBeforeComplete: "showAllQuestions",
  // };
  surveyEdit.onComplete.add(async (sender, options) => {
    setIsLoading(true);
    const surveyJson = getSurveyJson();
    const payload = {
      additional_form: "{}",
      json_prod: { ...surveyEdit.data },
      model: surveyJson,
    };
    await saveAffaire(payload, "SoumettreModification", null, "edit")
      .then(() => {
        setIsLoading(false);
        navigate("/demandes");
        sessionStorage.removeItem(`id_affaire_edit_${selectedOption}`);
      })
      .catch(() => {
        setIsLoading(false);
      });
  });

  const getData = async () => {
    const data = await getSurveyData();
    setIsLoading(true);

    if (data) {
      let id_edit = `id_affaire_edit_${sessionStorage.getItem("selectedOption")}`;
      if (!sessionStorage.getItem(id_edit) && sessionStorage.getItem("demande")!=="true") {
        // sessionStorage.setItem(
        //   `id_affaire_edit_${params?.state?.selectedOption}`,
        //   params?.state?.id_affaire
        // );
        const payload = {
          id_affaire: params?.state?.id_affaire,
          data_form_prod: JSON.stringify(data?.response?.data),
          ligne_produit: params?.state?.ligne_produit,
          etat_affaire:
            params?.state?.selectedOption === "sans_bo_sans_validation"
              ? import.meta.env.VITE_APP_ID_STATUS_BROUILLON_SANS_VALIDATION
              : import.meta.env.VITE_APP_ID_STATUS_BROUILLON_AVEC_VALIDATION,
        };

        axios
          .post(import.meta.env.VITE_APP_GEOPROD_API + "/update_EPF", payload, {
            headers: {
              Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
                ?.Acces_Token,
            },
          })
          .then((res) => {
            sessionStorage.setItem(id_edit, res?.data?.new_affaire);
            setIsLoading(false);
          })
          .catch(() => {
            navigate("/etablissements");
            setIsLoading(false);
          });
      } else {
        setIsLoading(false);
      }
    }
  };

  const checkDirectorIdentity = (value) => {
    if (value[0] === "المستثمر نفسه") {
      const page = surveyEdit.getPageByName(
        "Données de Directeur de l’Établissement"
      );

      let cin = surveyEdit.data.investor_cin;
      let passeport = surveyEdit.data.investor_passport;
      let nationality = surveyEdit.data.investor_nationality;

      let payload = {
        role: import.meta.env.VITE_APP_ID_ROLE_INVESTISSEUR_DIRCETEUR,
      };
      if (nationality === "تونسية") {
        payload.cin = cin;
      } else {
        payload.passport = passeport;
      }

      axios
        .post(
          `${import.meta.env.VITE_APP_GEOPROD_API}/admin/check_cin_role`,
          payload
        )
        .then((res) => {
          if (res.data.existe) {
            notification.error({
              message: t("Erreur"),
              description: t(
                "Le directeur ajouté est fonctionnel dans un autre EPF"
              ),
            });
            return;
          } else {
            surveyEdit.setValue("establishment_director_data_test_valid", true);
            page.questions.forEach((question) => {
              if (
                question.name !== "establishment_director_data_identity" &&
                question.name !==
                  "establishment_director_data_experience_years" &&
                question.name !==
                  "establishment_director_data_ministerial_auth_date" &&
                question.name !== "copie_autorisation_ministre"
              )
                question.readOnly = true;

              if (
                question.name !== "establishment_director_data_test_valid" &&
                question.name !==
                  "establishment_director_data_passeport_copie" &&
                question.name !== "copie_autorisation_ministre" &&
                question.name !== "establishment_director_data_cin_copie" &&
                question.name !==
                  "establishment_director_data_date_delivrance" &&
                question.name !==
                  "establishment_director_data_ministerial_auth_date" &&
                question.name !== "establishment_director_data_passeport" &&
                question.name !== "establishment_director_data_cin_number" &&
                question.name !== "establishment_director_data_spinner" &&
                question.name !== "director_search"
              ) {
                question.visible = true;
              } else {
                question.visible = false;
              }
              if (surveyEdit.data.investor_nationality === "تونسية") {
                if (
                  question.name === "establishment_director_data_cin_copie" ||
                  question.name ===
                    "establishment_director_data_date_delivrance"
                ) {
                  question.visible = true;
                }
              } else if (surveyEdit.data.investor_nationality !== "تونسية") {
                if (
                  question.name === "establishment_director_data_test_valid" ||
                  question.name ===
                    "establishment_director_data_passeport_copie" ||
                  question.name === "copie_autorisation_ministre" ||
                  question.name ===
                    "establishment_director_data_ministerial_auth_date"
                ) {
                  question.visible = true;
                }
              }
            });
            surveyEdit.setValue("establishment_director_data_test_valid", true);

            surveyEdit.setValue(
              "establishment_director_data_last_name",
              surveyEdit.data.investor_last_name_ar
            );
            surveyEdit.setValue(
              "establishment_director_data_last_name_fr",
              surveyEdit.data.investor_last_name_fr
            );
            surveyEdit.setValue(
              "establishment_director_data_date_delivrance",
              surveyEdit.data.investor_date_delivrance
            );
            surveyEdit.setValue(
              "establishment_director_data_genre",
              surveyEdit.data.investor_civility
            );
            surveyEdit.setValue(
              "establishment_director_data_passeport",
              surveyEdit.data.investor_passport
            );
            surveyEdit.setValue(
              "establishment_director_data_passeport_copie",
              surveyEdit.data.investor_copie_passeport
            );

            surveyEdit.setValue(
              "establishment_director_data_cin_copie",
              surveyEdit.data.investor_copie_cin
            );
            surveyEdit.setValue(
              "establishment_director_data_director_folder",
              surveyEdit.data.investor_copie_cv
            );
            surveyEdit.setValue(
              "establishment_director_data_first_name",
              surveyEdit.data.investor_first_name_ar
            );

            surveyEdit.setValue(
              "establishment_director_data_first_name_fr",
              surveyEdit.data.investor_first_name_fr
            );

            surveyEdit.setValue(
              "establishment_director_data_email",
              surveyEdit.data.investor_email
            );
            surveyEdit.setValue(
              "establishment_director_data_education_level",
              surveyEdit.data.investor_eduction_level
            );
            surveyEdit.setValue(
              "establishment_director_data_nationality",
              surveyEdit.data.investor_nationality
            );
            surveyEdit.setValue(
              "establishment_director_data_birth_date",
              surveyEdit.data.investor_birth_date
            );
            surveyEdit.setValue(
              "establishment_director_data_birth_place",
              surveyEdit.data.investor_birth_place
            );
            surveyEdit.setValue(
              "establishment_director_data_specific_needs",
              surveyEdit.data.investor_specific_needs
            );
            surveyEdit.setValue(
              "establishment_director_data_cin_number",
              surveyEdit.data.investor_cin
            );
            surveyEdit.setValue(
              "establishment_director_data_passport",
              surveyEdit.data.investor_passport
            );
            surveyEdit.setValue(
              "establishment_director_data_address",
              surveyEdit.data.investor_residence_address
            );
            surveyEdit.setValue(
              "establishment_director_data_governorate",
              surveyEdit.data.investor_residence_governorate
            );
            surveyEdit.setValue(
              "establishment_director_data_delegation",
              surveyEdit.data.investor_residence_delegation
            );
            surveyEdit.setValue(
              "establishment_director_data_phone_number",
              surveyEdit.data.investor_phone_number
            );
          }
        });
    } else {
      const page = surveyEdit.getPageByName(
        "Données de Directeur de l’Établissement"
      );
      if (page) {
        page.questions.forEach((question) => {
          surveyEdit.setValue(
            "establishment_director_data_nationality",
            "تونسية"
          );
          const valid = surveyEdit.getQuestionByName(
            "establishment_director_data_test_valid"
          );
          valid.value = false;

          if (question.name !== "establishment_director_data_identity") {
            surveyEdit.clearValue(question.name);
          }

          if (
            question.name !== "establishment_director_data_nationality" &&
            question.name !== "establishment_director_data_identity" &&
            question.name !== "establishment_director_data_passeport" &&
            question.name !== "establishment_director_data_cin_number" &&
            question.name !== "establishment_director_data_birth_date" &&
            question.name !== "director_search"
          ) {
            question.visible = false;
          }
          question.readOnly = false;
        });
      }
    }
  };

  const getTrainerWithCin = async (survey, name) => {
    const trainer_data = survey.getQuestionByName("trainer_data");
    trainer_data?.panels.map(async (panel) => {
      const spinner = panel.getQuestionByName("cin_check_spinner");
      const valid = panel.getQuestionByName("trainer_data_test_valid");
      const active = panel.getQuestionByName("trainer_active");
      const changedData = panel.getValue();
      const trainerCin = changedData.trainer_data_cin_number;
      const trainerPassport = changedData.trainer_data_passport;
      const trainerNationality = changedData.trainer_data_nationality;

      try {
        spinner.visible = true;
        const params = {
          nationality: trainerNationality,
          cin: null,
          passport: null,
        };

        if (trainerNationality === "تونسية") {
          params.cin = trainerCin;
        } else {
          params.passport = trainerPassport;
        }

        const data = await getTrainerByNationalityAndIdentity(params);

        const excludedQuestions = [
          "trainer_data_nationality",
          "trainer_data_passport",
          "trainer_data_cin_number",
        ];

        if (!data) {
          survey.setValue("trainer_data_test_valid", false);

          spinner.visible = false;
          notification.error({
            message: t("Erreur"),
            description:
              trainerNationality === "تونسية"
                ? t(
                    "Le numéro de CIN du formateur n'est pas associé à un compte sur la plateforme."
                  )
                : t(
                    "Le numéro de passeport du formateur n'est pas associé à un compte sur la plateforme."
                  ),
            duration: 0,
          });

          panel.getQuestions().forEach((question) => {
            if (!excludedQuestions.includes(question.name)) {
              question.visible = false;
            }
          });
        } else {
          survey.setValue("trainer_data_test_valid", true);

          if (data.first_login === 1) {
            active.value = false;
            spinner.visible = false;

            notification.error({
              message: t("Erreur"),
              description: t("Le compte du formateur n'est pas activé."),
              duration: 0,
            });
          } else {
            active.value = true;
            panel.getQuestions().forEach((question) => {
              if (
                question.name !== "trainer_data_cin" &&
                question.name !== "trainer_data_cin_number" &&
                question.name !== "trainer_data_cin_date_delivrance" &&
                question.name !== "trainer_data_passeport" &&
                question.name !== "trainer_data_folder" &&
                question.name !== "trainer_data_test_valid" &&
                question.name !== "trainer_active" &&
                question.name !== "trainer_data_passport" &&
                question.name !== "dossier_autoriser"
              ) {
                question.visible = true;
              }

              if (
                panel.getQuestionByName("trainer_data_nationality").value ===
                "تونسية"
              ) {
                if (
                  question.name === "trainer_data_cin" ||
                  question.name === "trainer_data_cin_date_delivrance"
                ) {
                  question.visible = true;
                }
              } else if (
                panel.getQuestionByName("trainer_data_nationality").value !==
                "تونسية"
              ) {
                if (
                  question.name === "trainer_data_passeport" ||
                  question.name === "trainer_data_passeport"
                ) {
                  question.visible = true;
                }
              }
            });
            const delegationQuestion = panel.getQuestionByName(
              "trainer_data_delegation"
            );

            const id_goverment = gouvernoratList.find(
              (e) => e.value === data?.goverment
            )?.id;

            const delegations = await fetchDelegationList(id_goverment);

            delegationQuestion.choices = delegations || [];
            spinner.visible = false;
            if (delegations) {
              panel.getQuestionByName("trainer_data_last_name").value =
                data?.nom || "";
              panel.getQuestionByName("trainer_data_first_name").value =
                data?.prenom || "";
              panel.getQuestionByName("trainer_data_email").value =
                data?.mail || "";
              panel.getQuestionByName("trainer_data_birth_date").value =
                dayjs(data?.date_naiss_user) || "";
              panel.getQuestionByName("trainer_data_civility").value =
                data?.civilite;
              panel.getQuestionByName("trainer_data_phone_number").value =
                data?.mobile || "";
              panel.getQuestionByName("trainer_data_address").value =
                data?.adresse || "";
              panel.getQuestionByName("trainer_data_birth_place").value =
                data?.lieu_naissance || "";
              panel.getQuestionByName("trainer_data_governorate").value =
                data?.goverment || "";
              panel.getQuestionByName("trainer_data_delegation").value =
                data?.delegation || "";
              panel.getQuestionByName("trainer_data_postal_code").value =
                data?.code_postal || "";
              panel.getQuestionByName("trainer_data_specialty_domain").value =
                data?.domaines_specialite || "";
              panel.getQuestionByName("trainer_data_education_level").value =
                data?.niveau_scolaire || "";
              panel.getQuestionByName(
                "trainer_data_cin_date_delivrance"
              ).value = dayjs(data?.delivrance_date, "DD/MM/YYYY") || "";
              panel.getQuestionByName("trainer_data_specific_needs").value =
                data?.besoins_specifiques;

              const apiUrl = `${
                import.meta.env.VITE_APP_GEOPROD_API
              }/document_formateur/getByCinPassport`;
              const param =
                trainerNationality === "تونسية"
                  ? `cin=${trainerCin}`
                  : `passport=${trainerPassport}`;
              axiosInstance
                .get(
                  `${apiUrl}?${param}`,

                  {
                    headers: {
                      Idsession: JSON.parse(
                        sessionStorage.getItem("-x-token-user")
                      ).Acces_Token,
                    },
                  }
                )
                .then((res) => {
                  if (trainerNationality === "تونسية") {
                    const response_file = res?.data.response?.find(
                      (e) => e.key === "copie_cin"
                    );
                    let fileType = mime.getType(response_file?.name);
                    const file = [
                      {
                        content: response_file.url,
                        name: response_file?.name,
                        type: fileType,
                      },
                    ];
                    panel.getQuestionByName("trainer_data_cin").value = file;
                    panel.getQuestionByName("trainer_data_cin").visible = true;
                  } else {
                    const response_file = res?.data.response?.find(
                      (e) => e.key === "copie_passeport"
                    );

                    const file = [
                      {
                        content: response_file.url,
                        name: response_file?.name,
                        type: mime.getType(response_file?.name),
                      },
                    ];
                    panel.getQuestionByName("trainer_data_passeport").value =
                      file;
                    panel.getQuestionByName(
                      "trainer_data_passeport"
                    ).visible = true;
                  }

                  if (data?.autoriser_dossier === "نعم") {
                    const response_file = res?.data.response?.find(
                      (e) => e.key === "dossier_file"
                    );
                    const file = [
                      {
                        content: response_file.url,
                        name: response_file?.name,
                        type: mime.getType(response_file?.name),
                      },
                    ];
                    panel.getQuestionByName("trainer_data_folder").value = file;
                    panel.getQuestionByName(
                      "trainer_data_folder"
                    ).visible = true;
                    panel.getQuestionByName("dossier_autoriser").value = true;
                  } else {
                    panel.getQuestionByName("dossier_autoriser").value = false;
                  }
                });
            }
          }
        }
      } catch (error) {
        spinner.visible = false;
        notification.error({
          message: t("Erreur"),
          description: error?.response?.data?.message || "An error occurred.",
          duration: 0,
        });
      }
    });
  };

  const checkDNCINDirector = async (survey) => {
    const dn = survey.getValue("establishment_director_data_birth_date");
    const cin = survey.getValue("establishment_director_data_cin_number");
    const identity = survey.getValue("establishment_director_data_identity");
    let data;
    const valid = survey.getQuestionByName(
      "establishment_director_data_test_valid"
    );
    const spinner = survey.getQuestionByName(
      "establishment_director_data_spinner"
    );

    if (!dn || !cin || !identity) {
      valid.value = false;
      notification.error({
        message: t("Erreur"),
        description: t("Veuillez remplir les champs obligatoires."),
      });
      return;
    }

    spinner.visible = true;

    axios
      .post(`${import.meta.env.VITE_APP_GEOPROD_API}/admin/check_cin_role`, {
        cin: cin,
        role: import.meta.env.VITE_APP_ID_ROLE_DIRECTEUR,
      })
      .then(async (res) => {
        if (res.data.existe) {
          spinner.visible = false;
          notification.error({
            message: t("Erreur"),
            description: t(
              "Le directeur ajouté est fonctionnel dans un autre EPF"
            ),
          });
          return;
        } else {
          data = await checkCitizenStatusByCinDn(dn, cin);
          const page = survey.getPageByName(
            "Données de Directeur de l’Établissement"
          );

          if (data) {
            valid.value = true;
            spinner.visible = false;

            page.questions.map((question) => {
              if (
                question.name !== "establishment_director_data_test_valid" &&
                question.name !==
                  "establishment_director_data_passeport_copie" &&
                question.name !== "copie_autorisation_ministre" &&
                question.name !== "establishment_director_data_cin_copie" &&
                question.name !==
                  "establishment_director_data_date_delivrance" &&
                question.name !==
                  "establishment_director_data_ministerial_auth_date" &&
                question.name !== "establishment_director_data_passeport" &&
                question.name !== "establishment_director_data_cin_number" &&
                question.name !== "establishment_director_data_spinner"
              ) {
                question.visible = true;
              }
              if (
                survey.getQuestionByName(
                  "establishment_director_data_nationality"
                ).value === "تونسية"
              ) {
                if (
                  question.name === "establishment_director_data_cin_copie" ||
                  question.name ===
                    "establishment_director_data_date_delivrance" ||
                  question.name === "director_search"
                ) {
                  question.visible = true;
                }
              } else if (
                survey.getQuestionByName(
                  "establishment_director_data_nationality"
                ).value !== "تونسية"
              ) {
                if (
                  question.name === "establishment_director_data_test_valid" ||
                  question.name ===
                    "establishment_director_data_passeport_copie" ||
                  question.name === "copie_autorisation_ministre" ||
                  question.name ===
                    "establishment_director_data_ministerial_auth_date"
                ) {
                  question.visible = true;
                }
              }
            });

            survey.setValue(
              "establishment_director_data_last_name",
              data?.nomAr
            );
            survey.setValue(
              "establishment_director_data_first_name",
              data?.prenomAr
            );

            const name_ar = survey.getQuestionByName(
              "establishment_director_data_last_name"
            );
            const prenom_ar = survey.getQuestionByName(
              "establishment_director_data_first_name"
            );
            name_ar.readOnly = true;
            prenom_ar.readOnly = true;
            const name_fr = survey.getQuestionByName(
              "establishment_director_data_last_name_fr"
            );
            const prenom_fr = survey.getQuestionByName(
              "establishment_director_data_first_name_fr"
            );
            if (data?.nomFr) {
              survey.setValue(
                "establishment_director_data_last_name_fr",
                data?.nomFr
              );
              survey.setValue(
                "establishment_director_data_first_name_fr",
                data?.prenomFr
              );

              name_fr.readOnly = true;
              prenom_fr.readOnly = true;
            } else {
              name_fr.readOnly = false;
              prenom_fr.readOnly = false;
            }
            survey.setValue(
              "establishment_director_data_genre",
              data?.genre === "1" ? "ذكر" : "أنثى"
            );
            const genre = survey.getQuestionByName(
              "establishment_director_data_genre"
            );
            genre.readOnly = true;

            if (data?.commune) {
              let lieu_naissance = await getCommuneByCodeCommune(data?.commune);

              survey.setValue(
                "establishment_director_data_birth_place",
                lieu_naissance
              );

              const birthplace = survey.getQuestionByName(
                "establishment_director_data_birth_place"
              );
              birthplace.readOnly = true;
            }
          } else {
            valid.value = false;
            spinner.visible = false;

            const excludedQuestions = [
              "establishment_director_data_nationality",
              "establishment_director_data_cin_number",
              "establishment_director_data_passeport",
              "establishment_director_data_birth_date",
              "establishment_director_data_identity",
              "director_search",
            ];

            page.getQuestions().forEach((question) => {
              if (!excludedQuestions.includes(question.name)) {
                question.visible = false;
              }
            });
          }
        }
      })
      .catch(() => {
        spinner.visible = false;
      });

    // setIsLoading(false);
  };

  const getCadre = async (survey) => {
    const management_data = survey.getQuestionByName("management_data");

    management_data?.panels.map(async (panel) => {
      if (
        survey.getQuestionByName("management_data").getItemIndex() - 1 ===
        survey.getQuestionByName("management_data").panels.indexOf(panel)
      ) {
        const changedData = panel.getValue();
        const spinner = panel.getQuestionByName(
          "management_data_check_spinner"
        );
        const valid = panel.getQuestionByName("management_data_staff_valid");
        const cinNumber = changedData?.management_data_staff_cin_number;
        const birthDate = changedData?.management_data_staff_birth_date;

        try {
          if (!birthDate || !cinNumber) {
            valid.value = false;
            notification.error({
              message: t("Erreur"),
              description: t("Veuillez remplir les champs obligatoires."),
            });
            return;
          }
          spinner.visible = true;

          const data = await checkCitizenStatusByCinDn(birthDate, cinNumber);

          if (data) {
            valid.value = true;
            spinner.visible = false;
            panel.getQuestions().forEach((question) => {
              if (
                question.name !== "management_data_staff_cin_number" &&
                question.name !== "management_data_staff_passport_number" &&
                question.name !== "management_data_staff_date_delivrance" &&
                question.name !== "management_data_staff_cin_copy" &&
                question.name !== "management_data_staff_passport_copy" &&
                question.name !== "management_data_staff_valid" &&
                question.name !== "management_data_check_spinner"
              ) {
                question.visible = true;
              }

              if (
                question.name === "management_data_staff_cin_copy" ||
                question.name === "management_data_staff_date_delivrance" ||
                question.name === "management_data_search"
              ) {
                question.visible = true;
              }
            });

            panel.getQuestionByName("management_data_staff_last_name").value =
              data?.nomAr || "";
            panel.getQuestionByName("management_data_staff_first_name").value =
              data?.prenomAr || "";

            panel.getQuestionByName("management_data_staff_civility").value =
              data?.genre === "1" ? "ذكر" : "أنثى";
            const nom_fr = panel.getQuestionByName(
              "management_data_staff_last_name_fr"
            );
            const prenom_fr = panel.getQuestionByName(
              "management_data_staff_first_name_fr"
            );
            if (data?.nomFr) {
              nom_fr.value = data?.nomFr || "";
              prenom_fr.value = data?.prenomFr || "";
              nom_fr.readOnly = true;
              prenom_fr.readOnly = true;
            } else {
              nom_fr.readOnly = false;
              prenom_fr.readOnly = false;
            }
          } else {
            valid.value = false;
            spinner.visible = false;
            const excludedQuestions = [
              "management_data_staff_nationality",
              "management_data_staff_passport_number",
              "management_data_staff_cin_number",
              "management_data_staff_birth_date",
              "management_data_search",
            ];
            panel.getQuestions().forEach((question) => {
              if (!excludedQuestions.includes(question.name)) {
                question.visible = false;
              }
            });
          }
        } catch (error) {
          valid.value = false;
          notification.error({
            message: t("Erreur"),
            description: error?.response?.data?.message || "An error occurred.",
            duration: 0,
          });
        }
      }
    });
  };

  FunctionFactory.Instance.register(
    "checkDirectorIdentity",
    checkDirectorIdentity
  );

  const handleClickOutside = async (event) => {
    const payload = {
      current_step: surveyEdit.currentPageNo,
      json_prod: { ...surveyEdit.data },
      model: editJson,
    };

    sessionStorage.setItem("payload", JSON.stringify(payload));
  };

  useEffect(() => {
    sessionStorage.setItem("consulter", false);
    getData();
    if( sessionStorage.getItem("demande") ==="false" || !sessionStorage.getItem("demande")  ){
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }

    
  }, []);

  const getAllData = async () => {
    const data = await getSurveyData();
    const currentData = {
      ...data?.response?.data,
    };

    data?.response?.key_urls?.forEach((item) => {
      const fileType = mime.getType(item.name_doc);
      currentData[item.key] = [
        {
          content: item.url,
          name: item.name_doc,
          type: fileType,
        },
      ];
    });
    surveyEdit.data = currentData;
    surveyEdit.getAllQuestions().map(async (question) => {
      if (question.name?.toLowerCase().includes("_governorate")) {
        question.choices = gouvernoratList;

        const delegateKey = question.name.replace(
          "_governorate",
          "_delegation"
        );
        const selectedGouvernorat = question.value;
        const selectedDelegate = surveyEdit.getQuestionByName(delegateKey);
        const id_goverment = gouvernoratList.find(
          (e) => e.value === selectedGouvernorat
        )?.id;
        if (id_goverment) {
          const delegations = await fetchDelegationList(id_goverment);

          selectedDelegate.choices = delegations || [];
        }
      }

      // if(params?.state?.id_affaire){
      //   surveyEdit.getQuestionByName("establishment_director_data_test_valid").value=true;

      // }

      if(sessionStorage.getItem("demande")==="true"){
        question.readOnly=true
      }

      if (question.name === "trainer_data") {
        question.panels.forEach((panel) => {
          panel.questions.forEach((question) => {
            if (question.name.toLowerCase().includes("_nationality")) {
              question.choices = nationalityList;
            }
            if (question.name.toLowerCase().includes("_governorate")) {
              question.choices = gouvernoratList;
            }
            if(sessionStorage.getItem("demande")==="true"){
              question.readOnly=true
            }
          });

          const isTestValid = surveyEdit.getValue("trainer_data_test_valid");

          if (isTestValid) {
            panel.getQuestions().forEach(async (question) => {
              const questionName = question.name;

              // Handle visibility for specific questions
              const alwaysVisibleFields = [
                "trainer_data_cin",
                "trainer_data_cin_number",
                "trainer_data_cin_date_delivrance",
                "trainer_data_passeport",
                "trainer_data_folder",
                "trainer_data_test_valid",
                "trainer_active",
                "trainer_data_passport",
                "cin_check_spinner",
                "dossier_autoriser",
              ];

              // Show all except explicitly excluded fields
              if (!alwaysVisibleFields.includes(questionName)) {
                question.visible = true;
              }

              // Handle "dossier_autoriser" condition
              if (
                panel.getQuestionByName("dossier_autoriser")?.value === true
              ) {
                if (questionName === "trainer_data_folder") {
                  question.visible = true;
                }
              }

              // Handle nationality-specific visibility
              const nationality = panel.getQuestionByName(
                "trainer_data_nationality"
              )?.value;

              if (nationality === "تونسية") {
                if (
                  [
                    "trainer_data_cin",
                    "trainer_data_cin_date_delivrance",
                  ].includes(questionName)
                ) {
                  question.visible = true;
                }
              } else if (nationality) {
                if (questionName === "trainer_data_passeport") {
                  question.visible = true;
                }
              }
            });
          }
        });
      }

      if (question.name === "management_data") {
        const dynamicPanel = question;
        dynamicPanel?.panels.forEach(async (panel) => {
          const governorateQuestion = panel.getQuestionByName(
            "management_data_staff_residence_governorate"
          );
          if (!governorateQuestion) return;
          const selectedGouvernorat = governorateQuestion.value;
          const delegateKey = "management_data_staff_residence_delegation";
          const selectedDelegate = panel.getQuestionByName(delegateKey);

          const id_goverment = gouvernoratList.find(
            (e) => e.value === selectedGouvernorat
          )?.id;
          if (id_goverment) {
            const delegations = await fetchDelegationList(id_goverment);

            selectedDelegate.choices = delegations || [];
          }

          const nationalityQuestion = panel.getQuestionByName(
            "management_data_staff_nationality"
          );
          nationalityQuestion.choices = nationalityList;
          const govermentQuestion = panel.getQuestionByName(
            "management_data_staff_residence_governorate"
          );

          govermentQuestion.choices = gouvernoratList;

          if (
            panel.getQuestionByName("management_data_staff_valid").value ===
              true ||
            panel.getQuestionByName("management_data_staff_nationality")
              .value !== "تونسية"
          ) {
            panel.getQuestions().forEach((question) => {
              if (
                question.name !== "management_data_staff_cin_number" &&
                question.name !== "management_data_staff_passport_number" &&
                question.name !== "management_data_staff_date_delivrance" &&
                question.name !== "management_data_staff_cin_copy" &&
                question.name !== "management_data_staff_passport_copy" &&
                question.name !== "management_data_staff_valid" &&
                question.name !== "management_data_check_spinner" &&
                question.name !== "management_data_search"
              ) {
                question.visible = true;
              }
              if(sessionStorage.getItem("demande")==="true"){
                question.readOnly=true
              }

              if (
                panel.getQuestionByName("management_data_staff_nationality")
                  .value === "تونسية"
              ) {
                if (
                  question.name === "management_data_staff_cin_copy" ||
                  question.name === "management_data_staff_date_delivrance"
                ) {
                  question.visible = true;
                }
              } else if (
                panel.getQuestionByName("management_data_staff_nationality")
                  .value !== "تونسية"
              ) {
                if (question.name === "management_data_staff_passport_copy") {
                  question.visible = true;
                }
              }
            });
          }
        });
      }

      if (question.name.toLowerCase().includes("_nationality")) {
        question.choices = nationalityList;
      }
      if (question.name === "training_nature_data") {
        const dynamicPanel = question;

        dynamicPanel?.panels.forEach(async (panel) => {
          const sectorQuestion = panel.getQuestionByName(
            "training_type_data_sector"
          );
          const subSectorQuestion = panel.getQuestionByName(
            "training_type_data_sub_sector"
          );
          const specialtyQuestion = panel.getQuestionByName(
            "training_type_data_specialty"
          );
          sectorQuestion.choices = secteurData;
          const selectedSector = sectorQuestion?.value;

          if (selectedSector) {
            const id = secteurData.find(
              (elem) => elem.value === selectedSector
            )?.id;
            const listSousSecteurs = await fetchSubSectorList(id);

            if (listSousSecteurs) {
              subSectorQuestion.choices = listSousSecteurs;
            } else {
              subSectorQuestion.choices = [];
            }

            const selectedSubSector = subSectorQuestion?.value;

            if (selectedSubSector) {
              const id = listSousSecteurs.find(
                (elem) => elem.value === selectedSubSector
              )?.id;
              const listSpecialiteDiplomes = await fetchSpetialityList(id);

              if (listSpecialiteDiplomes) {
                specialtyQuestion.choices = listSpecialiteDiplomes.map(
                  (item) => item[0].spetialite
                );
              } else {
                specialtyQuestion.choices = [];
              }
            }
          }
          if (
            panel.getQuestionByName("training_type_data_type").value === "منظرة"
          ) {
            const trainer_page = surveyEdit.getPageByName("page5");
            const space_page = surveyEdit.getPageByName("page6");
            const equipement_page = surveyEdit.getPageByName("page7");
            trainer_page.visible = true;
            space_page.visible = true;
            equipement_page.visible = true;
          }
        });
      }
    });
    populateDelegationOnLoad();
    displayQuestionsFunction();
  };

  useEffect(() => {
    getAllData();
  }, [surveyEdit]);

  return <Survey model={surveyEdit} ref={ref} />;
};

export default EditEpf;
