
import { useTranslation } from "react-i18next";
const Home =()=> {
  const { t } = useTranslation();
  return (
    <h4>
     {t("Acceuil")}
    </h4>
  );
}

export default Home;
