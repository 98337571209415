import { Input, notification } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
const { Search } = Input;
const SearchInputComponent = ({
  question,
  apiCall,
  minLength,
  maxLength,
  regex,
  message,
}) => {
  const { t } = useTranslation();
  const searchFunction = (value) => {
    if (!value || value.trim() === "") {
      notification.error({
        message: t("Erreur"),
        description: t(`Veuillez remplir le champ avant de lancer la recherche`),
      });
      return;
    }
    if (regex && !regex.test(value)) {
      notification.error({
        message: t("Erreur"),
        description: message,
      });
      return;
    }
    apiCall();
  };

  useEffect(() => {
    
    question.showRefreshBar = (isVisible) => {
      const searchInput = document.querySelector(
        `div[data-name="${question.name}"]`
      );
      if (searchInput) {
        searchInput.style.display = "block";
      }
    };
    return () => {
      delete question.showRefreshBar;
    };
  }, [question]);

  const onChange = (e) => {
    if (e) {
      question.value = e.target.value;
    } else {
      question.value = null;
    }
  };
  return (
    <Search
    value={question.value ? question.value : null}
      onSearch={searchFunction}
      onChange={onChange}
      maxLength={maxLength}
      disabled={question.readOnly || sessionStorage.getItem("consulter")==="true"}
    />
  );
};

export default SearchInputComponent;
