import { useEffect } from "react";
import Loader from "../../../layout/Loader";

const RefreshBarComponent = ({ question }) => {
    useEffect(() => {
      let timer;
      question.showRefreshBar = (isVisible) => {
        const refreshBar = document.querySelector(`div[data-name="${question.name}"] .refresh-bar`);
        if (refreshBar) {
          timer = setTimeout(() => {
            refreshBar.style.display = "block"; // Show the refresh bar after the delay
          }, 6000);
        }
      };
      return () => {
        delete question.showRefreshBar;
      };
    }, [question]);
  
    return (
       <Loader />
    );
  };
  export default RefreshBarComponent