import { SearchOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useEffect } from "react";
import * as Sentry from "@sentry/react"
const ButtonComponent = ({ question,handleClick }) => {
  
  useEffect(() => {
    question.showRefreshBar = (isVisible) => {
      const searchInput = document.querySelector(
        `div[data-name="${question.name}"]`
      );
      if (searchInput) {
        searchInput.style.display = "block";
      }
    };
    return () => {
      delete question.showRefreshBar;
    };
  }, [question]);

  const onClick = () => {
    try{
      handleClick();
    }
    catch(e){
      console.log(e);
      Sentry.captureException(e)
      
    }
  }
  return (
    <Button id="search" type="primary" icon={<SearchOutlined />} onClick={onClick} disabled={question.readOnly}>
      Rechercher
    </Button>
  );
};

export default ButtonComponent;
