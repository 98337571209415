import { CalendarOutlined } from "@ant-design/icons";
import { Modal, Row, Upload } from "antd";
import dayjs from "dayjs";
import React from "react";

const CommentModal = (props) => {
  const { commentaire, setCommentaire } = props;

  return (
    <Modal
      title={"Commentaires"}
      open={commentaire?.length > 0}
      onCancel={() => setCommentaire([])}
      footer={null}
    >
      {commentaire?.map((comment) => (
        <div
          style={{
            padding: "16px",
            border: "1px solid #d9d9d9",
            borderRadius: "0.5rem",
            marginBottom: "16px",
            backgroundColor: "#eef7ff",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            display:"flex",
            flexDirection:"column",
          }}
        >
          <span style={{color:"#024fa8"}}>
            <CalendarOutlined />
            {comment?.date && dayjs(comment?.date).format("DD/MM/YYYY")}
          </span>
          <span>{comment?.commentaire} </span>
          {comment?.docs.map((doc, docIndex) => (
            <Upload
              key={docIndex}
              fileList={[
                {
                  uid: docIndex.toString(),
                  name: doc.name,
                  url: doc.doc,
                  status: "done",
                },
              ]}
              showUploadList={{
                showPreviewIcon: true,
                showDownloadIcon: true,
                showRemoveIcon: false,
              }}
              onPreview={() => window.open(doc.doc, "_blank")}
            />
          ))}
        </div>
      ))}
    </Modal>
  );
};

export default CommentModal;
