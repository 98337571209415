import { useContext, useEffect, useRef, useState } from "react";
import { Survey } from "survey-react-ui";
import { Model, CustomWidgetCollection } from "survey-core";
import GlobalContext from "../../../context/GlobalContext";
import { useLocation, useNavigate } from "react-router-dom";
import {
  onCompleteInvestisseurChanging,
  onDynamicPanelItemValueChanged,
  onUploadFiles,
  onValueChanging,
} from "../CreateEpf/CreateEpfComponents/surveyEvents";
import mime from "mime";
import DatePikckerComponent from "../CreateEpf/DatePIkcer";
import axios from "axios";
import { getCommuneByCodeCommune } from "../../../context/FetchLists";
import dayjs from "dayjs";
import { checkCitizenStatusByCinDn } from "../../../api/extern";
import { Button, Modal, notification, Space } from "antd";
import { t } from "i18next";
import DeleteBrouillonModal from "../CreateEpf/Modals/DeleteBrouillonModal";
import { SaveOutlined, UnorderedListOutlined } from "@ant-design/icons";
import {
  addAffaire,
  addRelationAffaire,
  getSchema,
  saveAffaire,
  updateAffaire,
} from "../../../api/geoprod";

import { themeJson } from "../CreateEpf/CreateEpfComponents/creationJson";
import { useLoader } from "../../../context/LoaderContext";
import classes from "../CreateEpf/CreateEpf.module.css";
// import surveyJson from "./ChangementInvestisseur.json";

const ChangementInvestisseur = () => {
  const [confirmModal, setConfirmModal] = useState(false);
  const { setIsLoading, getSurveyJson, getSurveyData } = useLoader();
  const {
    secteurData,
    gouvernoratList,
    nationalityList,
    matriculeFiscale,
    listAllDelegations,
  } = useContext(GlobalContext);
  const ref = useRef();
  const params = useLocation();
  const navigate = useNavigate();
  const surveyJson = getSurveyJson();
  const survey = new Model(surveyJson);

  CustomWidgetCollection.Instance.addCustomWidget({
    name: "datepicker",
    title: "Date picker",
    isFit: function (question) {
      return (
        question.getType() === "text" && question.jsonObj.inputType === "date"
      );
    },
    render: function (question, element) {
      return <DatePikckerComponent question={question} survey={survey} />;
    },
  });
  // onCompleteInvestisseurChanging(survey, setIsLoading, navigate);
  onValueChanging(survey, gouvernoratList, listAllDelegations);
  onDynamicPanelItemValueChanged(survey, secteurData);
  onUploadFiles(
    survey,
    "changementInvestisseur",
    params?.state?.selectedOption
  );

  survey.onComplete.add(async (sender, options) => {
    setIsLoading(true);
    const surveyJson = getSurveyJson();
    const surveyData = JSON.parse(
      sessionStorage.getItem("BeforechangeInvestorData")
    );
    const data = {
      ...survey.data,
      ...surveyData,
    };
    // const payload = {
    //   current_step: surveyEdit.currentPageNo,
    //   json_prod: {  },
    //   model: editJson,
    // };
    const payload = {
      additional_form: "{}",
      json_prod: { ...data },
      model: surveyJson,
    };
    await saveAffaire(
      payload,
      "SoumettreModification",
      null,
      "changementInvestisseur"
    )
      .then(() => {
        sessionStorage.removeItem("idAffaireChangementInvestisseur");

        setIsLoading(false);
        navigate("/demandes");
      })
      .catch(() => {
        setIsLoading(false);
      });
  });

  survey.applyTheme(themeJson);

  const updateSurveyData = async () => {
    const data = await getSurveyData();
    survey.data = data;
  };

  const setFileValues = (value) => {
    const fileType = mime.getType(value.name);
    const file = {
      content: value.content,
      name: value.name,
      type: fileType,
    };
    return file;
  };

  const getData = async () => {
    const user = JSON.parse(sessionStorage.getItem("-x-token-user"));
    survey.showProgressBar = "off";

    survey.showPreviewBeforeComplete = false;
    async function fetchData() {
      let data;
      if (user?.nationality === "تونسية" && user?.ehouwiya !== 1) {
        data = await checkCitizenStatusByCinDn(
          user?.user?.date_naiss_user,
          user?.cin
        );
      }
      if (data?.prenomAr) {
        survey.setValue("investor_first_name_ar", data?.prenomAr);
        const name_ar = survey.getQuestionByName("investor_first_name_ar");
        name_ar.readOnly = true;
      }
      if (data?.nomAr) {
        survey.setValue("investor_last_name_ar", data?.nomAr);
        const prenom_ar = survey.getQuestionByName("investor_last_name_ar");
        prenom_ar.readOnly = true;
      }
      if (data?.commune) {
        let lieu_naissance = await getCommuneByCodeCommune(data?.commune);
        survey.setValue("investor_birth_place", lieu_naissance);
        survey.getQuestionByName("investor_birth_place").readOnly = true;
      }
      if (data?.prenomFr) {
        survey.setValue("investor_last_name_fr", data?.prenomFr || user?.nom);
        const name_fr = survey.getQuestionByName("investor_last_name_fr");
        name_fr.readOnly = true;
      }
      if (data?.nomFr) {
        survey.setValue("investor_first_name_fr", data?.nomFr || user?.prenom);
        const prenom_fr = survey.getQuestionByName("investor_first_name_fr");
        prenom_fr.readOnly = true;
      }

      if (user?.email) {
        survey.setValue("investor_email", user?.email);
        const investor_email = survey.getQuestionByName("investor_email");
        investor_email.readOnly = true;
      }

      survey.setValue("investor_phone_number", user?.mobile);
      survey.setValue("investor_nationality", user?.nationality);
      survey.setValue(
        "investor_civility",
        data?.genre === "1" ? "ذكر" : "أنثى"
      );
      survey.setValue("investor_cin", user?.cin);
      survey.setValue(
        "investor_birth_date",
        dayjs(user?.user?.date_naiss_user).format("YYYY-MM-DD")
      );
    }
    fetchData();
    const setUserData = (userData, role) => {
      const fieldsToSet = [
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_passeport"
              : "investor_passport",
          userDataField: "passport",
        },

        {
          surveyField:
            role === "directeur"
              ? "establishment_director_date_expiration_passeport"
              : "investor_date_expiration_passeport",
          userDataField: "expiration_passeport",
        },
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_experience_years"
              : "",
          userDataField: "experience_years",
          readOnly: true,
        },
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_first_name"
              : "investor_first_name_ar",
          userDataField: "prenom_ar",
          readOnly: true,
        },
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_last_name"
              : "investor_last_name_ar",
          userDataField: "nom_ar",
          readOnly: true,
        },

        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_first_name_fr"
              : "investor_last_name_fr",
          userDataField: "prenom",
          readOnly: true,
        },
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_last_name_fr"
              : "investor_first_name_fr",
          userDataField: "nom",
          readOnly: true,
        },
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_email"
              : "investor_email",
          userDataField: "mail",
          readOnly: true,
        },
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_birth_place"
              : "investor_birth_date",
          userDataField: "date_naiss_user",
          readOnly: true,
        },
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_genre"
              : "investor_civility",
          userDataField: "civilite",
          readOnly: true,
        },

        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_nationality"
              : "investor_nationality",
          userDataField: "nationality",
          readOnly: true,
        },
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_cin_number"
              : "investor_cin",
          userDataField: "cin",
          readOnly: true,
        },

        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_date_delivrance"
              : "investor_date_delivrance",
          userDataField: "delivrance_date",
        },
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_birth_place"
              : "investor_birth_place",
          userDataField: "birthplace",
          readOnly: true,
        },
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_education_level"
              : "investor_eduction_level",
          userDataField: "niveau_scolaire",
          readOnly: true,
        },
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_phone_number"
              : "investor_phone_number",
          userDataField: "telephone",
          readOnly: true,
        },
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_address"
              : "investor_residence_address",
          userDataField: "adresse",
        },
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_governorate"
              : "investor_residence_governorate",
          userDataField: "goverment",
        },
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_delegation"
              : "investor_residence_delegation",
          userDataField: "delegation",
        },
        {
          surveyField:
            role === "directeur" ? "" : "investor_residence_postal_code",
          userDataField: "code_postal",
        },
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_specific_needs"
              : "investor_specific_needs",
          userDataField: "besoins_specifiques",
        },
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_cin_copie"
              : "investor_copie_cin",
          userDataField: "copie_cin",
        },
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_passeport_copie"
              : "investor_copie_passeport",
          userDataField: "copie_passeport",
        },
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_director_folder"
              : "investor_copie_cv",
          userDataField: "copie_cv",
        },
        {
          surveyField:
            role === "directeur" ? "copie_autorisation_ministre" : "",
          userDataField: "copie_autorisation_ministre",
        },
      ];

      fieldsToSet.forEach((field) => {
        const { surveyField, userDataField, readOnly } = field;
        const value =
          surveyField.includes("copie") && userData[userDataField]
            ? setFileValues(userData[userDataField])
            : surveyField.includes("_date") && userData[userDataField]
            ? dayjs(userData[userDataField]).format("YYYY-MM-DD")
            : userData[userDataField];
        const question = survey.getQuestionByName(surveyField);

        if (!value) {
          question ? (question.readOnly = false) : null;
        } else {
          survey.setValue(surveyField, value);
          if (readOnly) {
            question.readOnly = true;
          }
        }
      });
    };
    if (user) {
      const idAffaire = sessionStorage.getItem("idAffaireEpfExistant")
        ? sessionStorage.getItem("idAffaireEpfExistant")
        : params.state?.idAffaireEpfExistant;
      const idAffaireChangementInvestisseur = sessionStorage.getItem(
        "idAffaireChangementInvestisseur"
      )
        ? sessionStorage.getItem("idAffaireChangementInvestisseur")
        : params.state?.idAffaireChangementInvestisseur;

      updateSurveyData();

      if (idAffaire) {
        axios
          .get(
            `${
              import.meta.env.VITE_APP_GEOPROD_API
            }/config/proxy/registreEntrepriseParId/${matriculeFiscale.substring(
              0,
              8
            )}`
          )
          .then((res) => {
            if (res?.data?.code === "404") {
              survey.clearValue("establishment_data_official_arabic_name");
              survey.clearValue("establishment_data_official_name");
              return notification.error({
                message: t("Erreur"),
                description: t("Numéro d’identifiant fiscal / RNE invalide"),
                duration: 0,
              });
            }
            const epfData = res?.data;
            const arabicEpfName = epfData?.nomSociete
              ? epfData?.nomSociete
              : epfData?.nomAssociation;
            const latinEpfName = epfData?.nomAssociationLatin
              ? epfData?.nomAssociationLatin
              : epfData?.nomSocieteLatin;
            survey.setValue(
              "establishment_data_official_arabic_name",
              arabicEpfName
            );
            survey.setValue("establishment_data_official_name", latinEpfName);
            axios
              .get(`${import.meta.env.VITE_APP_GEOPROD_API}/get_user_with_id`, {
                headers: {
                  Idsession: user?.Acces_Token,
                },
              })
              .then(async (data) => {
                const role = sessionStorage.getItem("role");
                setUserData(data?.data?.user, role);
                if (
                  !sessionStorage.getItem("idAffaireChangementInvestisseur")
                ) {
                  // const dataSurvey = await getSurveyData();

                  const data = await getSchema(
                    params?.state?.idAffaireChangerInv
                  );

                  if (data) {
                    sessionStorage.setItem(
                      "BeforechangeInvestorData",
                      JSON.stringify(data?.response?.data)
                    );

                    const payload = {
                      id_affaire: params?.state?.idAffaireChangerInv,
                      data_form_prod: JSON.stringify(data?.response?.data),
                      ligne_produit: import.meta.env
                        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO,
                      etat_affaire: import.meta.env
                        .VITE_APP_ID_STATUS_BROUILLON_AVEC_VALIDATION,
                      epf_existant: true,
                    };
                    axios
                      .post(
                        import.meta.env.VITE_APP_GEOPROD_API + "/update_EPF",
                        payload,
                        {
                          headers: {
                            Idsession: JSON.parse(
                              sessionStorage.getItem("-x-token-user")
                            )?.Acces_Token,
                          },
                        }
                      )
                      .then((res) => {
                        sessionStorage.setItem(
                          "idAffaireChangementInvestisseur",
                          res?.data?.new_affaire
                        );
                        setIsLoading(false);
                      })
                      .catch(() => {
                        navigate("/etablissements");
                        setIsLoading(false);
                      });
                  }
                }

                // const payload = {
                //   ligne_produit: import.meta.env
                //     .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO,
                //   id_opp: null,
                //   nom_produit: "THAMM PRODUCT (  )",
                //   id_produit: "d728587fff2d87201eae0a158507d814",
                //   cmp: "987654300",
                //   user_id: user.id_user,
                //   date_effet: "2024-01-02",
                //   souscription: "2024-01-02",
                //   id_fractionnement: 11,
                //   affected: "1002237",
                //   data_form_prod: JSON.stringify({
                //     ...survey.data,
                //   }),
                // };
                // const getIdAffaire = async () => {
                //   try {
                //     const res = await addAffaire(payload);
                //     sessionStorage.setItem(
                //       "idAffaireChangementInvestisseur",
                //       res?.id_affaire
                //     );
                //     const idAffaireFils = res?.id_affaire;
                //     const payloadRelationAffaire = {
                //       id_affaire1: idAffaire,
                //       id_affaire2: idAffaireFils,
                //       type: 1,
                //     };
                //     await addRelationAffaire(payloadRelationAffaire);
                //     const infoSpecefiquePayload = {
                //       additional_form: "{}",
                //       json_prod: { ...survey.data },
                //     };
                //     await updateAffaire(
                //       idAffaireFils,
                //       infoSpecefiquePayload,
                //       "Brouillon"
                //     );
                //     setSurveyData({ ...survey.data });
                //   } catch {
                //     notification.error({
                //       message: t("Erreur"),
                //       description: t(
                //         "Erreur lors de la création de la demande."
                //       ),
                //       duration: 0,
                //     });
                //   }
                // };
                // getIdAffaire();
              });
          });
      }
    }
    survey.getAllQuestions().forEach((question) => {
      if (question.name.toLowerCase().includes("_nationality")) {
        question.choices = nationalityList;
      }
      if (question.name.toLowerCase().includes("_governorate")) {
        question.choices = gouvernoratList;
      }
    });
  };

  survey.onErrorCustomText.add(function (sender, options) {
    if (options.name == "required") {
      options.text = t("Veuillez remplir ce champ.");
    }
  });

  const handleClickOutside = async (event) => {
    // const data = await getSurveyData();
    // let data_brouillon
    // if(!sessionStorage.getItem("demande") || sessionStorage.getItem("demande")!=="true"){
    //   const all_data_but_investor=Object.fromEntries(
    //     Object.entries(data).filter(([key, value]) => !key.includes("investor"))
    //   );
    //   data_brouillon = all_data_but_investor;
    // }
    // else{
    //   data_brouillon = data;
    // }
    const payload = {
      current_step: 0,
      json_prod: { ...survey.data },
      model: surveyJson,
    };
    sessionStorage.setItem("payload", JSON.stringify(payload));
  };

  useEffect(() => {
    getData();

    if (
      sessionStorage.getItem("demande") === "false" ||
      !sessionStorage.getItem("demande")
    ) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [survey]);

  const populateFunction = async () => {
    if (question.name.toLowerCase().includes("_governorate")) {
      question.choices = gouvernoratList;
    }
    if (question.name?.includes("_delegation")) {
      const selectedDelegation = question.value;
      const postalCodeQuestion = survey.getQuestionByName(
        question.name.replace("_delegation", "_postal_code")
      );

      const idDelegation = listAllDelegations.find(
        (elem) => elem.value === selectedDelegation
      )?.id;

      if (idDelegation) {
        const listPostalCodes = await fetchCodePostalList(idDelegation);
        if (listPostalCodes) {
          postalCodeQuestion.choices = listPostalCodes.map((code) => ({
            value: code.value,
            text: code.text,
          }));
        } else {
          postalCodeQuestion.choices = [];
        }
      }
    }
  };

  survey.onAfterRenderQuestion.add(function (sender, options) {
    populateFunction();

    const fileInputButton =
      options.htmlElement.querySelector(".sd-file__wrapper");
    if (fileInputButton) {
      const spanElement = fileInputButton.querySelector("span");
      if (spanElement) {
        spanElement.textContent = "Télécharger le fichier - تحميل الملف";
      }
    }
    const emptyTextDiv = options.htmlElement.querySelector(
      ".sv-list__empty-text"
    );
    if (emptyTextDiv) {
      emptyTextDiv.textContent =
        "Veuillez d'abord remplir le champ précédent !";
    }
    const fileInputButtonBeforeChoose = options.htmlElement.querySelector(
      ".sd-file__choose-btn"
    );
    if (fileInputButtonBeforeChoose) {
      const spanElement = fileInputButtonBeforeChoose.querySelector("span");
      if (spanElement) {
        spanElement.textContent = "Télécharger le fichier - تحميل الملف";
      }
    }

    const placeholderNoFile = options.htmlElement.querySelector(
      ".sd-file__decorator"
    );
    if (placeholderNoFile) {
      const placeholder = placeholderNoFile.querySelector(
        ".sd-file__drag-area-placeholder"
      );
      if (placeholder) {
        const spanElement = placeholder.querySelector(".sv-string-viewer");
        if (spanElement && spanElement.textContent === "No file selected") {
          spanElement.textContent =
            "Aucun fichier téléchargé - لم يتم تحميل أي ملف";
        }
      }
    }
  });
  return (
    <div ref={ref}>
      <Space className={classes.rowButtonCreate}>
        {!params.state?.idAffaireChangementInvestisseur && (
          <Space>
            <Button
              className={classes.buttonCreate}
              onClick={() => setConfirmModal(true)}
            >
              <SaveOutlined />
              {t("Supprimer du brouillon")}
            </Button>
            <Button
              className={classes.buttonCreate}
              onClick={async () => {
                // setIsLoading(true);

                const payload = {
                  current_step: 0,
                  json_prod: {
                    ...survey.data,
                  },
                };

                await saveAffaire(payload, "Brouillon", setIsLoading);
                // setIsLoading(false);
                navigate("/brouillon");
              }}
            >
              <SaveOutlined />
              {t("Sauvegarder au brouillon")}
            </Button>{" "}
          </Space>
        )}
        <Button
          className={classes.buttonCreate}
          onClick={() => navigate("/etablissements")}
        >
          <UnorderedListOutlined />
          {t("Liste de mes EPFs")}
        </Button>
      </Space>

      <Survey model={survey} />
      <DeleteBrouillonModal
        confirmModal={confirmModal}
        setConfirmModal={setConfirmModal}
        typeAffaire="changementInvestisseur"
      />
    </div>
  );
};

export default ChangementInvestisseur;
