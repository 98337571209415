import React, { useContext, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import * as Sentry from "@sentry/react";

import AppContext from "./context/AppContext";
import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/Signup/SignUp";
import Main from "./layout/Main";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
// import Home from "./components/Home";
import Demandes from "./pages/Demandes/Demandes";
import Rtl from "./pages/Formateurs";
import Etablissements from "./pages/Etablissements/Etablissements";
import CreateEpf from "./pages/Etablissements/CreateEpf/CreateEpf";
import EditEpf from "./pages/Etablissements/EditEpf/EditEpf";
import PdfViewer from "./pages/PdfViewer";
import Profile from "./pages/Profile";
import Brouillons from "./pages/Brouillons/Brouillons";
import { LoaderProvider } from "./context/LoaderContext";
import Home from "./pages/Home";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import ComplementDossier from "./pages/Demandes/ComplementDossier/ComplementDossier";
import SignInEHouwiya from "./pages/SignInEHouwiya/SignInEHouwiya";
import ValidationProvisoire from "./pages/Demandes/ValidationProvisoire/ValidationProvisoire";
import AnnexesList from "./pages/Annexes/AnnexesList";
import AnnexesForm from "./pages/Annexes/AnnexesForm";
import { AnnexeLoaderProvider } from "./context/AnnexeLoaderContext";
import CreateEpfExistante from "./pages/Etablissements/CreateEpf/CreateEpfExistant";
import ChangementInvestisseur from "./pages/Etablissements/ChangementInvestisseur/ChangementInvestisseur";
import SurveyProfile from "./pages/SurveyProfile/SurveyProfile";
import { Button } from "antd";

function App() {
  const [token, setToken] = useState(null);
  const [role, setRole] = useState("");
  const paramRole = new URLSearchParams(window.location.search).get("role");
  const navigate = useNavigate();
  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError }) => (
        <React.Fragment>
          <div
            style={{
              maxWidth: "600px",
              margin: "0 auto",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              gap: "10px",
              marginTop: "20px",
              backgroundColor: "white",
              padding:"20px",
              borderRadius: "13px",
              boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"
            }}
          >
            <div
              style={{ fontSize: "26px", color: "black", textAlign: "center", fontWeight: 600 }}
            >
              Erreur détectée et prise en charge ✅
            </div>
            <div style={{ fontSize: "14px", marginBottom: "20px" }}>
              Nous avons détecté une erreur sur le système et souhaitons vous
              rassurer que toutes les informations nécessaires pour la corriger
              ont été automatiquement transmises à notre équipe technique chez
              Neopolis Development.
              <br />
              <br />
              <strong>Il n'est pas nécessaire de la déclarer manuellement.</strong>
              <br />
              <br />
              Nos développeurs analysent actuellement le problème pour le
              résoudre dans les meilleurs délais. Merci pour votre contribution
              précieuse à l'amélioration de la plateforme !
            </div>
            <Button
              type="primary"
              onClick={() => {
                resetError();
                navigate("/userProfil");
              }}
            >
              Cliquez ici pour réinitialiser !
            </Button>
          </div>
        </React.Fragment>
      )}
    >
      <AppContext>
        <LoaderProvider>
          <AnnexeLoaderProvider>
            <Routes>
              <Route
                path="/signin"
                element={
                  <SignIn setToken={setToken} setRole={setRole} role={role} />
                }
              />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route
                path="/signup"
                element={
                  paramRole !== "directeur" ? (
                    <SignUp />
                  ) : (
                    <Navigate to={`/signin?role=${paramRole}`} />
                  )
                }
              />
              <Route
                path="*"
                element={<Navigate to={`/signin?role=${paramRole}`} />}
              />
              <Route
                path="/signin/e-houwiya"
                element={
                  <SignInEHouwiya setToken={setToken} setRole={setRole} />
                }
              />

              {role !== "formateur" ? (
                <Route element={<Main />}>
                  {/* <Route path="/dashboard" element={<Home />} /> */}
                  <Route path="/demandes" element={<Demandes />} />
                  <Route path="/demandes/:id" element={<Demandes />} />

                  <Route path="/formateurs" element={<Rtl />} />
                  <Route path="/annexes" element={<AnnexesList />} />
                  <Route path="/annexes/:id" element={<AnnexesList />} />

                  <Route path="/annexes/save" element={<AnnexesForm />} />
                  <Route
                    path="/etablissements"
                    element={<Etablissements role={role} />}
                  />
                  <Route
                    path="/etablissements/:id"
                    element={<Etablissements role={role} />}
                  />
                  <Route path="/etablissements/save" element={<CreateEpf />} />
                  <Route path="/etablissements/edit" element={<EditEpf />} />
                  <Route
                    path="/etablissements/save/:id"
                    element={<CreateEpf />}
                  />
                  <Route
                    path="/etablissements/existante/save"
                    element={<CreateEpfExistante />}
                  />
                  <Route
                    path="/investisseur/changer"
                    element={<ChangementInvestisseur />}
                  />
                  <Route
                    path="/complement/dossier"
                    element={<ComplementDossier />}
                  />

                  <Route
                    path="/complement/validation/provisoire"
                    element={<ValidationProvisoire />}
                  />

                  <Route
                    path="/etablissements/view/:id"
                    element={<CreateEpf />}
                  />
                  <Route path="/pdf/viewer" element={<PdfViewer />} />
                  <Route path="*" element={<Navigate to="/demandes" />} />
                  <Route path="/brouillon" element={<Brouillons />} />
                  <Route path="/userProfil" element={<SurveyProfile />} />
                </Route>
              ) : (
                <Route element={<Main />}>
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/acceuil" element={<Home />} />
                  <Route path="*" element={<Navigate to="/profile" />} />
                </Route>
              )}
            </Routes>
          </AnnexeLoaderProvider>
        </LoaderProvider>
      </AppContext>
    </Sentry.ErrorBoundary>
  );
}

export default App;
