import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  Form,
  Input,
  Card,
  Select,
  notification,
  Modal,
  Spin,
  Space,
} from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import GlobalContext from "../../context/GlobalContext";
import logo from "../../assets/sigaf.svg";
import republique from "../../assets/tunisia.png";
import { useTranslation } from "react-i18next";
import { getAffaires, login } from "../../api/geoprod";
import axios from "axios";
import classes from "./Signin.module.css";
import axiosInstance from "../../utils/axios";
import { useLoader } from "../../context/LoaderContext";
import logoMobileId from "../../assets/LogoMobileID.png";
import pkceChallenge from "pkce-challenge";
const SignIn = (props) => {
  const navigate = useNavigate();
  const { form, globalData, setGlobalData, setIsRtl } =
    useContext(GlobalContext);
  const { setIsLoading, setSurveyJson } = useLoader();
  const { setToken, setRole, role } = props;
  const { Option } = Select;
  const [openModalPassword, setOpenModalPassword] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [loaderChange, setLoaderChange] = useState(false);
  const [loaderForget, setLoaderForget] = useState(false);
  const [openModalForgetPassword, setOpenModalForgetPassword] = useState(false);
  const { t, i18n } = useTranslation();
  const roleParams = new URLSearchParams(window.location.search).get("role");
  const idRole =
    roleParams === "investisseur"
      ? import.meta.env.VITE_APP_ID_ROLE_INVESTISSEUR
      : roleParams === "formateur"
      ? import.meta.env.VITE_APP_ID_ROLE_FORMATEUR
      : roleParams === "directeur"
      ? import.meta.env.VITE_APP_ID_ROLE_DIRECTEUR
      : "";
  const handleLanguageChange = (value) => {
    localStorage.setItem("language", value);
    setGlobalData({
      ...globalData,
      language: value,
    });
    i18n.changeLanguage(value);
    setIsRtl(value === "ar");
  };

  const saveLoginCredentials = async (data) => {
    const first_login = data?.first_login;
    setToken(data?.Acces_Token);
    const role =
      data.id_role === import.meta.env.VITE_APP_ID_ROLE_FORMATEUR
        ? "formateur"
        : data?.id_role ===
          import.meta.env.VITE_APP_ID_ROLE_INVESTISSEUR_DIRCETEUR
        ? "investisseur-directeur"
        : data?.id_role === import.meta.env.VITE_APP_ID_ROLE_DIRECTEUR
        ? "directeur"
        : "investisseur";
    setRole(role);
    sessionStorage.setItem("role", role);
    sessionStorage.setItem("-x-token-user", JSON.stringify(data));
    if (data.id_role === import.meta.env.VITE_APP_ID_ROLE_FORMATEUR) {
      navigate("/profile", {
        state: {
          first_login: first_login,
          ehouwiya: data?.ehouwiya,
        },
      });
    } else {
      axios
        .get(
          `${import.meta.env.VITE_APP_GEOPROD_API}/admin-bpm/contrat/${
            import.meta.env.VITE_ID_LIGNE_PRODUIT
          }`,
          {
            headers: {
              Idsession: data?.Acces_Token,
            },
          }
        )
        .then((res) => {
          setIsLoading(false);
          setSurveyJson(res?.data?.contrat?.form);
        });

      const response = await getAffaires({
        id_user: JSON.parse(sessionStorage.getItem("-x-token-user")).id_user,
        id_entreprise: JSON.parse(sessionStorage.getItem("-x-token-user")).user
          .entreprise_id,
        filters: {
          contrat: [],
          status: [],
        },
      });

      let tempData = [];

      response?.aaData?.forEach((element) => {
        if (
          element.etat !== "Demande validée" &&
          element.etat !== "Brouillon"
        ) {
          tempData.push(element);
        }
      });

      if (tempData?.length > 0) {
        navigate("/demandes");
      } else {
        navigate("/etablissements");
      }

      // if (first_login) {
      //   navigate("/etablissements");
      // } else {
      //   navigate("/demandes");
      // }
    }
  };

  const handleFinish = async (values) => {
    setLoader(true);
    try {
      const data = await login(values.email, values.password, "", idRole);
      if (data?.first_login === 1) {
        notification.destroy();

        setUserData(data);

        if (data?.id_role == import.meta.env.VITE_APP_ID_ROLE_INVESTISSEUR) {
          setOpenModalPassword(true);
        } else {
          saveLoginCredentials(data);
        }
      } else {
        notification.destroy();
        saveLoginCredentials(data);
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      notification.error({
        message: t("Erreur"),
        description:
          error?.response?.data?.message ||
          t("Une erreur s'est produite lors de la connexion"),
        duration: 0,
      });
    }
  };

  const changePassword = async () => {
    setLoaderChange(true);
    try {
      const newPassword = form.getFieldValue("password_change");
      await axios.put(
        `${import.meta.env.VITE_APP_GEOPROD_API}/change_password_user`,
        {
          id_user: userData?.id_user,
          password: newPassword,
        },
        {
          headers: {
            Idsession: userData?.Acces_Token,
          },
        }
      );
      setOpenModalPassword(false);
      notification.success({
        message: t("Mot de passe changé avec succès !"),
        duration: 0,
      });
      setLoaderChange(false);

      saveLoginCredentials(userData);
    } catch (error) {
      setLoaderChange(false);
      notification.error({
        message: t("Erreur lors du changement de mot de passe"),
        description: error.message || t("Veuillez réessayer."),
        duration: 0,
      });
    }
  };
  const forgetPassword = (values) => {
    setLoaderForget(true);

    axiosInstance
      .get(
        `${import.meta.env.VITE_APP_GEOPROD_API}/forget_password?mail=${
          values.email_oublie
        }&role=${idRole}`
      )
      .then((res) => {
        setLoaderForget(false);
        notification.success({
          message:
            "Veuillez consulter votre boîte mail. Un lien de réinitialisation de mot de passe vous a été envoyé.",
          duration: 0,
        });
        setOpenModalForgetPassword(false);
      })
      .catch((err) => {
        setLoaderForget(false);
      });
  };

  return (
    <Row
      gutter={24}
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Col
        lg={24}
        style={{
          marginBottom: "4rem",
          display: "flex",
          justifyContent: "space-around",
          backgroundColor: "white",
          width: "100%",
          padding: "1rem",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "90%",
            alignItems: "center",
          }}
        >
          <img src={republique} style={{ height: "86px" }} />
          <img src={logo} />
          <Select
            style={{ width: "8rem", display: "flex", alignItems: "center" }}
            onChange={handleLanguageChange}
            value={localStorage.getItem("language") || "fr"}
          >
            <Option value="fr">Français</Option>
            <Option value="ar">العربية</Option>
          </Select>
        </Row>
      </Col>

      {roleParams && roleParams !== "null" && (
        <h2 className="mb-2">{t("Espace ") + roleParams}</h2>
      )}

        <Card
          style={{
            direction:
              localStorage.getItem("language") === "ar" ? "rtl" : "ltr",
              maxWidth: "600px",
              width: "90%",
          }}
        >
          <h2 className="mb-2">{t("Se connecter")}</h2>

          <Form
            form={form}
            layout="vertical"
            onFinish={handleFinish}
            disabled={loader}
            style={{ width: "100%" }}
          >
            <Form.Item
              label="Adresse e-mail - البريد الإلكتروني"
              name="email"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
                {
                  pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  message: t("Veuillez vérifier ce champ."),
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Mot de passe - كلمة المرور"
              name="password"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="recaptcha"
              rules={[
                {
                  required: true,
                  message: t("Veuillez vérifier ce champ."),
                },
              ]}
            >
              <ReCAPTCHA sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" />
            </Form.Item>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item>
                <Space>
                  {loader && <Spin />}
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: "#21A8A8",
                      width: "7rem",
                      color: "white",
                    }}
                    htmlType="submit"
                  >
                    {t("Se connecter")}
                  </Button>
                </Space>
                {roleParams !== "directeur" && (
                  <p>
                    {t("Vous n'avez pas de compte ?")}{" "}
                    <span
                      className={classes.seConnecter}
                      onClick={() => {
                        form.resetFields();
                        navigate(`/signup?role=${roleParams}`);
                      }}
                    >
                      {t("Créez-en un")}
                    </span>
                  </p>
                )}
                <p
                  style={{ textAlign: "left" }}
                  className={classes.seConnecter}
                  onClick={() => setOpenModalForgetPassword(true)}
                >
                  {t("Mot de passe oublié ?")}{" "}
                </p>
              </Form.Item>
              <Form.Item>
                <Space>
                  {/* {loader && <Spin />} */}
                  {/* {roleParams !== "directeur" && ( */}
                    <div style={{ backgroundColor: "#f9f9f9", padding: "6px" }}>
                      <Button
                        disabled={
                          roleParams !== "formateur" &&
                          roleParams !== "investisseur" &&  roleParams !== "directeur"
                        }
                        style={{
                          color: "#0f313d",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          border: "1px solid #0f313d",
                          paddingBlock: "2rem",
                          fontSize: "18px",
                          backgroundColor: "#f9f9f9",
                          borderRadius: "0px",
                          cursor:
                            roleParams === "formateur" ||
                            roleParams === "investisseur" || roleParams === "directeur"
                              ? "pointer"
                              : "not-allowed",
                          wordWrap: "break-word",
                          whiteSpace: "normal",
                        }}
                        onClick={async () => {
                          const challenge = await pkceChallenge(128);
                          const codeChallenge = challenge?.code_challenge;
                          const codeVerifier = challenge?.code_verifier;
                          localStorage.setItem("code_verifier", codeVerifier);
                          const redirect_uri = `${window.location.origin}/signin/e-houwiya?role=${roleParams}`;
                          const uri = `${
                            import.meta.env.VITE_APP_E_HOUWIYA_API
                          }/oauth2/authorize?response_type=code&client_id=${
                            import.meta.env.VITE_CLIENT_ID_E_HOUWIYA
                          }&redirect_uri=${redirect_uri}&code_challenge=${codeChallenge}&code_challenge_method=S256&scope=openid profile email phone identity birthdate address name gender`;
                          window.location.href = uri;
                        }}
                      >
                        <img
                          src={logoMobileId}
                          alt="logo"
                          style={{ width: "90px", height: "40px" }}
                        />
                        <span>تسجيل الدخول</span>
                      </Button>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: "8px",
                        }}
                      >
                        <a
                          href={
                            roleParams === "formateur" ||
                            roleParams === "investisseur" ||  roleParams === "directeur"
                              ? "https://www.mobile-id.tn/home/condition"
                              : null
                          }
                          style={{
                            fontSize: "16px",
                            color: "#0f313d",
                            textDecoration: "underline",
                            cursor:
                              roleParams === "formateur" ||
                              roleParams === "investisseur"   || roleParams === "directeur"
                                ? "pointer"
                                : "not-allowed",
                          }}
                        >
                          طلب الهوية الرقمية
                        </a>
                      </div>
                    </div>
                  {/* )} */}
                </Space>
              </Form.Item>
            </div>

            {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item>
                <Space>
                  {loader && <Spin />}
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: "#21A8A8",
                      width: "7rem",
                      color: "white",
                    }}
                    htmlType="submit"
                  >
                    {t("Se connecter")}
                  </Button>
                </Space>
              </Form.Item>
              <p style={{ textAlign: "right" }}>
                {t("Vous n'avez pas de compte ?")}{" "}
                <span
                  className={classes.seConnecter}
                  onClick={() => navigate(`/signup?role=${role}`)}
                >
                  {t("Créez-en un")}
                </span>
              </p>
            </div>
            <p
             style={{ textAlign: "left" }}
              className={classes.seConnecter}
              onClick={() => setOpenModalForgetPassword(true)}
            >
              {t("Mot de passe oublié ?")}{" "}
            </p> */}
          </Form>
        </Card>

      <Modal
        title={t("Changer le mot de passe")}
        open={openModalPassword}
        footer={null}
        width={600}
        onCancel={() => setOpenModalPassword(false)}
      >
        <Form
          form={form}
          layout="vertical"
          style={{ maxWidth: "600px", margin: "auto", paddingTop: "20px" }}
          onFinish={changePassword}
          disabled={loaderChange}
        >
          <Form.Item
            label={t("Mot de passe")}
            name="password_change"
            rules={[
              {
                required: true,
                message: t("Veuillez remplir ce champ."),
              },
              {
                pattern:
                  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[*/.@-])[A-Za-z\d*/.@-]{6,}$/,
                message: t(
                  "Le mot de passe doit contenir 6 caractères alphanumériques (A-Z, a-z, 0-9) et des caractères spéciaux (*/.@-)"
                ),
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label={t("Confirmer le mot de passe")}
            name="confirmPassword"
            dependencies={["password_change"]}
            rules={[
              {
                required: true,
                message: t("Veuillez remplir ce champ."),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password_change") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      t(
                        "Le mot de passe et la confirmation doivent être identiques."
                      )
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <div style={{ textAlign: "center" }}>
            <Space>
              {loaderChange && <Spin />}
              <Button
                htmlType="submit"
                type="primary"
                style={{
                  backgroundColor: "#214082",
                  borderColor: "#214082",
                  marginRight: "10px",
                  width: "7rem",
                  marginTop: "1rem",
                }}
              >
                Enregistrer
              </Button>
            </Space>
          </div>
        </Form>
      </Modal>

      <Modal
        title={t("Veuillez entrer votre adresse e-mail")}
        open={openModalForgetPassword}
        footer={null}
        width={600}
        onCancel={() => setOpenModalForgetPassword(false)}
      >
        <Form
          // form={form}
          layout="vertical"
          style={{ maxWidth: "600px", margin: "auto", paddingTop: "20px" }}
          onFinish={forgetPassword}
          disabled={loaderForget}
        >
          <Form.Item
            name="email_oublie"
            label="Adresse e-mail - البريد الإلكتروني"
            rules={[
              {
                required: true,
                message: t("Veuillez remplir ce champ."),
              },
              {
                pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: t("Veuillez vérifier ce champ."),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <div style={{ textAlign: "center" }}>
            <Space>
              {loaderForget && <Spin />}
              <Button
                htmlType="submit"
                type="primary"
                style={{
                  backgroundColor: "#214082",
                  borderColor: "#214082",
                  marginRight: "10px",
                  width: "7rem",
                  marginTop: "1rem",
                }}
              >
                {t("Valider")}
              </Button>
            </Space>
          </div>
        </Form>
      </Modal>

      {/* <Modal
        
        visible={openModalForgetPassword}
        footer={null}
        width={600}
        onCancel={() => setOpenModalForgetPassword(false)}
      >
        <Form onFinish={forgetPassword} form={form} layout="vertical">
          <Form.Item
            name="email_oublie"
            label="Adresse e-mail - البريد الإلكتروني"
            rules={[
              {
                required: true,
                message: t("Veuillez remplir ce champ."),
              },
              {
                pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: t("Veuillez vérifier ce champ."),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Valider
          </Button>
        </Form>
      </Modal> */}
    </Row>
  );
};

export default SignIn;
