import { Button, Card, Checkbox, Modal, Space, notification } from "antd";
import React, { useContext, useState } from "react";
import { useLoader } from "../../../../context/LoaderContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import GlobalContext from "../../../../context/GlobalContext";
import axios from "axios";

const ModalModification = (props) => {
  const { t } = useTranslation();
  const { modal, setModal, role } = props; // Added role prop
  const { setIsLoading, setSurveyJson } = useLoader();
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();

  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  const handleValidate = () => {
    if (!selectedOption) {
      notification.error({
        message: t("Erreur"),
        description: t("Veuillez choisir une option !"),
      });
      return;
    }

    const existing_brouillon_selected_option = sessionStorage.getItem(
      `id_affaire_edit_${selectedOption}`
    );
    if (!existing_brouillon_selected_option) {
      setIsLoading(true);
      notification.destroy();

      const ligne_produit =
        selectedOption === "avec_bo_validation"
          ? import.meta.env
              .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO
          : selectedOption === "sans_bo_sans_validation"
          ? import.meta.env
              .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_SANS_VALIDATION_SANS_BO
          : import.meta.env
              .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_SANS_BO;

      axios
        .get(
          `${
            import.meta.env.VITE_APP_GEOPROD_API
          }/admin-bpm/contrat/${ligne_produit}`,
          {
            headers: {
              Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
                ?.Acces_Token,
            },
          }
        )
        .then((res) => {
          setIsLoading(false);

          if (selectedOption === "avec_bo_validation") {
            let form = res?.data?.contrat?.form;
            form.pages = res?.data?.contrat?.form.pages.filter(
              (page) => page.name !== "ChnangementInvestisseur"
            );
            setSurveyJson(form);
          } else if (selectedOption === "sans_bo_avec_validation") {
            let form = res?.data?.contrat?.form;

            if (role === "investisseur") {
              form.pages = res?.data?.contrat?.form.pages.filter(
                (page) =>
                  page.name === "Données de Directeur de l’Établissement"
              );
            setSurveyJson(form);

            }
            else if(role==="directeur"){
              form.pages = res?.data?.contrat?.form.pages.filter(
                (page) =>
                  page.name !== "Données de Directeur de l’Établissement"
              );
            setSurveyJson(form);

            }
            else{
              setSurveyJson(res?.data?.contrat?.form);
            }

          } else if (
            selectedOption === "sans_bo_sans_validation" &&
            (role === "directeur" || role === "investisseur-directeur")
          ) {
            let form = res?.data?.contrat?.form;
            form.pages = res?.data?.contrat?.form.pages.filter(
              (page) => page.name === "page2" || page.name === "page8"
            );
            setSurveyJson(form);
          } else {
            setSurveyJson(res?.data?.contrat?.form);
          }

          sessionStorage.setItem("selectedOption", selectedOption);
          navigate(`/etablissements/edit`, {
            state: {
              id_affaire: modal,
              selectedOption: selectedOption,
              ligne_produit: ligne_produit,
            },
          });
          setModal("");
        });
    } else {
      notification.info({
        message: t("info"),
        description: t(
          "Vous avez déjà commencé une demande de modification de ce type. Merci de consulter votre brouillon."
        ),
      });
    }
  };

  const handleCancel = () => {
    setModal("");
  };

  const getCardContent = (option) => {
    if (option === "avec_bo_validation") {
      return t(
        "Cette option concerne les modifications qui affectent le bordereau (BO). Elles nécessitent une validation obligatoire par la direction régionale."
      );
    }

    if (option === "sans_bo_avec_validation") {
      return t(
        "Cette option concerne les modifications qui n'affectent pas le bordereau (BO). Elles nécessitent une validation de la direction régionale."
      );
    }

    if (option === "sans_bo_sans_validation") {
      return t(
        "Cette option concerne les modifications qui n'affectent pas le bordereau (BO). Elles ne nécessitent pas une validation de la direction régionale."
      );
    }

    return null;
  };

  const renderCheckboxes = () => {
    if (role === "investisseur") {
      return (
        <div>
          <Checkbox
            checked={selectedOption === "avec_bo_validation"}
            onChange={() => handleOptionChange("avec_bo_validation")}
            style={{
              fontSize: "16px",
              marginBottom: "10px",
              color: "#333",
            }}
          >
            {t(
              "Nécessite la validation de la direction régionale concernée et mise à jour du numéro d’enregistrement"
            )}
          </Checkbox>
          <Card
            style={{
              background: "#fff",
              borderRadius: "8px",
              border: "1px solid #d9d9d9",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              padding: "15px",
            }}
          >
            <p style={{ margin: 0, color: "#555", fontSize: "14px" }}>
              {getCardContent("avec_bo_validation")}
            </p>
          </Card>

          <Checkbox
            checked={selectedOption === "sans_bo_avec_validation"}
            onChange={() => handleOptionChange("sans_bo_avec_validation")}
            style={{
              fontSize: "16px",
              marginBottom: "10px",
              color: "#333",
            }}
          >
            {t(
              "Modifications sans impact sur le bordereau (BO), nécessitant une validation obligatoire par la direction régionale - تعديلات لا تؤثر على وصل الإيداع و تتطلب موافقة إلزامية من الإدارة الجهوية."
            )}
          </Checkbox>
          <Card
            style={{
              background: "#fff",
              borderRadius: "8px",
              border: "1px solid #d9d9d9",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              padding: "15px",
            }}
          >
            <p style={{ margin: 0, color: "#555", fontSize: "14px" }}>
              {getCardContent("sans_bo_avec_validation")}
            </p>
          </Card>
        </div>
      );
    }

    if (role === "directeur") {
      return (
        <>
          <Checkbox
            checked={selectedOption === "sans_bo_avec_validation"}
            onChange={() => handleOptionChange("sans_bo_avec_validation")}
            style={{
              fontSize: "16px",
              marginBottom: "10px",
              color: "#333",
            }}
          >
            {t(
              "Modifications sans impact sur le bordereau (BO), nécessitant une validation obligatoire par la direction régionale - تعديلات لا تؤثر على وصل الإيداع و تتطلب موافقة إلزامية من الإدارة الجهوية."
            )}
          </Checkbox>
          <Card
            style={{
              background: "#fff",
              borderRadius: "8px",
              border: "1px solid #d9d9d9",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              padding: "15px",
            }}
          >
            <p style={{ margin: 0, color: "#555", fontSize: "14px" }}>
              {getCardContent("sans_bo_avec_validation")}
            </p>
          </Card>

          {sessionStorage.getItem("Modifier_sans_validation") === "true" && (
            <>
              <Checkbox
                checked={selectedOption === "sans_bo_sans_validation"}
                onChange={() => handleOptionChange("sans_bo_sans_validation")}
                style={{
                  fontSize: "16px",
                  marginBottom: "10px",
                  color: "#333",
                }}
              >
                {t(
                  "Modifications sans impact sur le bordereau (BO),ne nécessitant pas une validation obligatoire par la direction régionale - تعديلات لا تؤثر على وصل الإيداع و لا تتطلب موافقة إلزامية من الإدارة الجهوية."
                )}
              </Checkbox>
              <Card
                style={{
                  background: "#fff",
                  borderRadius: "8px",
                  border: "1px solid #d9d9d9",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  padding: "15px",
                }}
              >
                <p style={{ margin: 0, color: "#555", fontSize: "14px" }}>
                  {getCardContent("sans_bo_sans_validation")}
                </p>
              </Card>
            </>
          )}
        </>
      );
    }

    return (
      <>
        <Checkbox
          checked={selectedOption === "avec_bo_validation"}
          onChange={() => handleOptionChange("avec_bo_validation")}
          style={{
            fontSize: "16px",
            marginBottom: "10px",
            color: "#333",
          }}
        >
          {t(
            "Nécessite la validation de la direction régionale concernée et mise à jour du numéro d’enregistrement"
          )}
        </Checkbox>
        <Card
          style={{
            background: "#fff",
            borderRadius: "8px",
            border: "1px solid #d9d9d9",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            padding: "15px",
          }}
        >
          <p style={{ margin: 0, color: "#555", fontSize: "14px" }}>
            {getCardContent("avec_bo_validation")}
          </p>
        </Card>

        <Checkbox
          checked={selectedOption === "sans_bo_avec_validation"}
          onChange={() => handleOptionChange("sans_bo_avec_validation")}
          style={{
            fontSize: "16px",
            marginBottom: "10px",
            color: "#333",
          }}
        >
          {t(
            "Modifications sans impact sur le bordereau (BO), nécessitant une validation obligatoire par la direction régionale - تعديلات لا تؤثر على وصل الإيداع و تتطلب موافقة إلزامية من الإدارة الجهوية."
          )}
        </Checkbox>
        <Card
          style={{
            background: "#fff",
            borderRadius: "8px",
            border: "1px solid #d9d9d9",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            padding: "15px",
          }}
        >
          <p style={{ margin: 0, color: "#555", fontSize: "14px" }}>
            {getCardContent("sans_bo_avec_validation")}
          </p>
        </Card>

        {sessionStorage.getItem("Modifier_sans_validation") === "true" && (
          <>
            <Checkbox
              checked={selectedOption === "sans_bo_sans_validation"}
              onChange={() => handleOptionChange("sans_bo_sans_validation")}
              style={{
                fontSize: "16px",
                marginBottom: "10px",
                color: "#333",
              }}
            >
              {t(
                "Modifications sans impact sur le bordereau (BO),ne nécessitant pas une validation obligatoire par la direction régionale - تعديلات لا تؤثر على وصل الإيداع و لا تتطلب موافقة إلزامية من الإدارة الجهوية."
              )}
            </Checkbox>
            <Card
              style={{
                background: "#fff",
                borderRadius: "8px",
                border: "1px solid #d9d9d9",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                padding: "15px",
              }}
            >
              <p style={{ margin: 0, color: "#555", fontSize: "14px" }}>
                {getCardContent("sans_bo_sans_validation")}
              </p>
            </Card>
          </>
        )}
      </>
    );
  };

  return (
    <Modal
      title={
        <span style={{ fontSize: "18px", fontWeight: "bold" }}>
          {t("type de modification - نوع التعديل")}
        </span>
      }
      open={!!modal}
      onCancel={handleCancel}
      width={800}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          {t("Annuler")}
        </Button>,
        <Button key="validate" type="primary" onClick={handleValidate}>
          {t("Valider")}
        </Button>,
      ]}
      centered
    >
      <Space
        direction="vertical"
        style={{
          padding: "20px",
          backgroundColor: "#f9f9f9",
          borderRadius: "10px",
        }}
      >
        {renderCheckboxes()}
      </Space>
    </Modal>
  );
};

export default ModalModification;
