import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  ar: {
    translation: {
      "Error !": "خطأ !",
      Erreur: "خطأ",
      "Votre inscription est enregistrée": "لقد تم تسجيلك بنجاح.",
      "Merci de confirmer votre compte pour finaliser la création de votre compte.":
        "يرجى تأكيد حسابك لإتمام إنشاء حسابك.",
      "Le Couple CIN/Date de naissance est erroné":
        "رقم التعريف الشخصي/تاريخ الميلاد غير صحيح",
      "Cin,Date de naissance conformes.Citoyen non vivant":
        "بطاقة التعريف الوطنية وتاريخ الميلاد متوافقان. المواطن غير حي",
      "Aucun citoyen trouv\u00e9 correspondant aux donn\u00e9es saisies":
        ".لم يتم العثور على أي مواطن مطابق للبيانات المدخلة",
      "Sign up": "اشتراك",
      "Adresse e-mail": "البريد الإلكتروني",
      Nationalité: "الجنسية",
      "Date de naissance": "تاريخ الولادة",
      "Numéro de téléphone": "رقم الهاتف",
      Passeport: "رقم جواز السفر",
      "Date d’expiration du passeport": "تاريخ انتهاء جواز السفر",

      Investisseur: "المستثمر",
      "Type de personne": "نوع الشركة",
      "Personne Physique": "شخص طبيعي",
      "Personne Morale": "شخص معنوي",
      "Nom de l'investisseur": "اسم المستثمر",
      "Prénom de l'investisseur": "لقب المستثمر",
      "Adresse de résidence": "عنوان الإقامة",
      "Niveau scolaire": "المستوى الدراسي",
      Nature: "الطبيعة",
      Type: "النوع",
      Homologuée: "معترف بها",
      "Non homologuée": "غير معترف بها",
      Formation: "التكوين",
      Secteur: "القطاع",
      "Sous-secteur": "القطاع الفرعي",
      Spécialité: "التخصص",
      Diplôme: "الشهادة",
      "Niveau requis": "المستوى المطلوب",
      "Durée en (H)": "المدة بالساعات",
      "Durée en (mois)": "المدة بالأشهر",
      "Durée minimale": "المدة الدنيا",
      "Mode de formation": "طريقة التكوين",
      "Ajouter une formation": "إضافة تكوين",
      "Création d'un nouvel EPF": "إحداث مؤسسة تكوين مهني خاصة جديدة",
      Page: "الصفحة",
      "Personne à besoin spécifique": "احتياجات خاصة",
      Genre: "الجنس",
      "Lieu de naissance": "مكان الولادة",
      "Numéro de passeport": "رقم جواز السفر",
      "Gouvernorat de résidence": "ولاية الإقامة",
      "Délégation de résidence": "معتمدية الإقامة",
      "Code postal": "الترقيم البريدي",
      "Dénomination commerciale": "الإسم التجاري",
      "Numéro de réservation": "رقم الحجز",
      "Type de l'EPF": "نوع هيكل التكوين الخاص",
      Logo: "الشارة",
      "Numéro fiscal": "المعرف الجبائي",
      "Numéro CNSS": "رقم الصندوق الوطني للضمان الاجتماعي",
      "Numéro de téléphone 1": "رقم الهاتف 1",
      "Numéro de téléphone 2": "رقم الهاتف 2",
      "Numéro de fax": "رقم الفاكس",
      "Matricule CNSS": "رقم التسجيل في الصندوق الوطني للضمان الاجتماعي",
      "Nature de formation": "طبيعة التكوين",
      "Adresse de l’établissement": "عنوان الهيكل",
      Gouvernorat: "الولاية",
      Délégation: "المعتمدية",
      "Données de l’établissement de formation": "معطيات هيكل التكوين الخاص",
      "Données de Directeur de l’Établissement": "بيانات مديرالهيكل",
      Nom: "الاسم",
      Prénom: "اللقب",
      "Date d’autorisation de ministre": "تاريخ ترخيص الوزير",
      "Numéro CIN": "رقم بطاقة التعريف الوطنية",
      Adresse: "العنوان",
      "Dossier du directeur": "ملف المدير (السيرة الذاتية وغيرها)",
      "Nombre d’années d’expérience": "عدد سنوات الخبرة",
      "Données sur la nature de formation": "بيانات حول طبيعة التكوين",
      "Données des Formateurs": "بيانات المكونين",
      Dossier: "الملف",
      "Domaines de spécialité": "مجالات التخصص",
      Situation: "الوضعية",
      "Données des Espaces": "بيانات الفضاءات",
      Espace: "فضاء",
      Libellé: "الوصف",
      "Superficie en m²": "المساحة بالمتر المربع",
      "Capacité en personne": "طاقة الإستيعاب للأشخاص",
      "Données des Équipements": "بيانات المعدات",
      Nombre: "العدد",
      "Fiche technique": "البطاقة الفنية",
      "Données du personnel d'encadrement et de gestion":
        "بيانات أعوان الإحاطة والتأطير",
      Personnel: "العون",
      Catégorie: "الفئة",
      "Années d’expérience professionnelle": "سنوات الخبرة المهنية",
      "Dossier de personnel": "ملف الموظف",
      "J’ai lu et accepté les termes du Cahier des Charges (CDC) lié à la création d’un EPF.":
        "أصّرح بأّني اطّلعت ووافقت على كراس الشروط المتعلق بضبط قواعد إحداث وسير هياكل التكوين الخاصة",
      Présentielle: "حضورية",
      "À distance": "عن بعد",
      "En alternance": "بالتناوب",
      Initiale: "أولية",
      Continue: "مستمرة",
      "Initiale et continue": "أولية ومستمرة",
      Vacataire: "متعاقد",
      Principal: "رئيسي",
      Annexe: "الفرع",
      Annexes: "الفروع",
      Principale: "المؤسسة الرئيسية",
      Homme: "ذكر",
      Femme: "أنثى",
      "Créer un nouvel EPF": "إحداث مؤسسة تكوين مهني خاصة",
      "EPF existant": "مؤسسة تكوين مهني خاصة موجودة",
      "Le champ doit contenir 7 chiffres et une lettre à la fin.":
        "يجب أن يحتوي الحقل على 7 أرقام وحرف في النهاية",
      "Le champ doit contenir 3 lettres": "يجب أن يحتوي الحقل على 3 أحرف",
      "Le champ doit contenir 3 chiffres": "يجب أن يحتوي الحقل على 3 أرقام",
      "Sélectionnez la situation d'EPF":
        "إختر وضعية مؤسسة التكوين المهني الخاصة",
      "Merci de remplir les informations de l'établissement existante":
        "يرجى ملء معلومات المؤسسة الموجودة",
      "Type de l'établissement": "نوع المؤسسة",
      "Numéro de la patente": "رقم السجل التجاري",
      "Nouvel EPF": "مؤسسة تكوين مهني خاصة جديدة",
      Valider: "تأكيد",
      Annuler: "إلغاء",
      Consulter: "الإطلاع",
      Modifier: "تعديل",
      Fermer: "إغلاق",
      Actions: "إجراءات",
      Statut: "الوضعية",
      Soumettre: "تقديم",
      Confirmation: "تأكيد",
      "Veuillez valider le numéro de téléphone.": "يرجى تأكيد رقم الهاتف.",
      "Êtes-vous sûr(e) de vouloir soumettre ces informations ?":
        "هل أنت متأكد(ة) من رغبتك في تقديم هذه المعلومات ؟",
      "Si vous êtes certain(e) que toutes les informations sont correctes, cliquez sur 'Valider' pour continuer.":
        " إذا كنت متأكد(ة) أن جميع المعلومات صحيحة، انقر على 'تأكيد' للمتابعة.",
      "Soumettre la demande": "تقديم المطلب",
      "Ajouter un personnel": "إضافة عون",
      "Ajouter un espace": "إضافة فضاء",
      "Dénomination officielle": "التسمية الرسمية",
      "Date d’expiration de la réservation": "تاريخ انتهاء الحجز",
      "Forme Juridique": "الشكل القانوني",
      "Adresse du siège social": "عنوان المقر الرئيسي",
      "Secteur d’activité": "قطاع النشاط",
      "Numéro d’identifiant fiscal / RNE": "رقم المعرف الجبائي / RNE",
      "Numéro de matricule CNSS":
        "رقم الانخراط في الصندوق الوطني للضمان الاجتماعي",
      "Liste de mes EPFs": "مؤسسات التكوين المهني الخاصة بي",
      "Liste de mes établissements": "مؤسسات التكوين المهني الخاصة بي",

      Dénomination: "التسمية",
      Code: "الرمز",
      Directeur: "المدير",
      "Matricule fiscal": "المعرف الجبائي",
      "Mes demandes": "طلباتي",
      "Liste de mes demandes": "قائمة طلباتي",
      Demandes: "المطالب",
      "Mes établissements": "المؤسسات",
      Formateurs: "المكونون",
      Identité: "الهوية",
      "Continue et Initiale": "مستمرة وأولية",
      "Décrivez si la personne a des besoins spécifiques ou non":
        "حدد إذا كانت هناك احتياجات خاصة للشخص أم لا",
      Oui: "نعم",
      Non: "لا",
      Encadrement: "التأطير",
      Gestion: "الإحاطة",
      Permanent: "قار",
      "Non permanent": "غير قار",
      "Espace investisseur": "فضاء المستثمر",
      Primaire: "ابتدائي",
      Secondaire: "ثانوي",
      Universitaire: "جامعي",
      "Télécharger le logo": "تحميل الشارة",
      "Cliquez ou glissez pour télécharger le CV et le dossier du directeur":
        "انقر أو اسحب لتحميل السيرة الذاتية وملف المدير",
      "Cliquez ou glissez pour télécharger le  dossier du formateur":
        "انقر أو اسحب لتحميل ملف المكون",
      "Reprendre l’activité": "استئناف النشاط",
      "Veuillez remplir tous les champs obligatoires.":
        "يرجى ملء جميع الخانات الإلزامية.",
      "Veuillez remplir ce champ.": "يرجى ملء هذه الخانة.",
      "Veuillez vérifier ce champ.": "يرجى التحقق من هذه الخانة.",
      Baccalauréat: "البكالوريا",
      Licence: "الإجازة",
      Master: "الماجستير",
      Doctorat: "الدكتوراه",
      "Votre demande a été ajouté avec succés": "تم إضافة مطلبك بنجاح",
      "Vous êtes sûr de vouloir soumettre cette demande ?":
        "هل أنت متأكد من رغبتك في تقديم هذا المطلب؟",
      "Numéro d'enregistrement": "رقم التسجيل",
      Domaine: "المجال",
      "Créer un nouveau compte": "إنشاء حساب جديد",
      "Vous-avez déjà un compte ?": "هل لديك حساب ؟",
      "Se connecter": "تسجيل الدخول",
      "Mot de passe": "كلمة المرور",
      "Nouveau mot de passe": "كلمة المرور الجديدة",
      "Confirmer le mot de passe": "تأكيد كلمة المرور",
      "Le mot de passe doit contenir 6 caractères alphanumériques (A-Z, a-z, 0-9) et des caractères spéciaux (*/.@-)":
        " يجب أن تحتوي كلمة المرور على 6 أحرف أبجدية رقمية (A-Z، a-z، 0-9) وأحرف خاصة (*/.@-)",
      "Le mot de passe et la confirmation doivent être identiques.":
        "يجب أن تكون كلمة المرور والتأكيد متطابقين.",
      "Vous n'avez pas de compte ?": "ليس لديك حساب؟",
      "Créez-en un": " أنشئ حسابًا",
      "Liste des formateurs": "قائمة المكونين",
      formateurs: "المكونون",
      "Espace formateur": "فضاء المكون",
      "Mon profil": "ملفي الشخصي",
      "Se déconnecter": "تسجيل الخروج",
      "Dénomination EPF": "التسمية",
      "Modifier Epf": " تعديل الهيكل",
      Etablissement: "الهيكل",
      "J'ai pris connaissance et accepté les termes du CDC relatifs à la création d'un EPF.":
        "لقد قرأت ووافقت على شروط كراس الشروط المتعلقة بإنشاء هيكل تكوين خاص",
      "Je confirme": "تأكيد",
      Brouillon: "مسودة",
      "Merci de cocher la case pour attester que vous avez pris connaissance des termes du CDC et que vous les acceptez concernant la création d'un EPF.":
        "يرجى وضع علامة في المربع لتأكيد أنك قرأت ووافقت على شروط كراس الشروط المتعلقة بإنشاء هيكل تكوين خاص",
      "Aucun établissement.": "ليس هناك مطالب",
      "Le numéro de CIN du formateur indiqué n'est pas associé à un compte sur la plateforme SIGAF. Veuillez vérifier le numéro de CIN fourni ou inviter le formateur à créer un compte sur la plateforme.":
        "رقم بطاقة التعريف الوطنية للمكون المُدرج غير مرتبط بحساب على منصة SIGAF. يُرجى التحقق من رقم بطاقة التعريف المُقدَّمة أو دعوة المكون لإنشاء حساب على المنصة.",
      "Veuillez parcourir le cahier des charges jusqu'à la fin afin de pouvoir confirmer sa lecture complète.":
        "يرجى تصفح كراس الشروط حتى النهاية لتتمكن من تأكيد قراءته بالكامل.",
      "Votre demande de création d'un nouvel EPF a été soumise avec succès. Vous recevrez un email vous informant des prochaines étapes à suivre.":
        "تم تقديم طلبك لإنشاء هيكل تكوين خاص بنجاح. ستتلقى بريدًا إلكترونيًا يوضح لك الخطوات التالية التي يجب اتباعها",
      "Votre demande de création d'un EPF existant a été soumise avec succès. Vous recevrez un email vous informant des prochaines étapes à suivre.":
        "تم إرسال طلب إنشاء مركز تكوين خاص قائم بنجاح. ستتلقى بريدًا إلكترونيًا لإعلامك بالخطوات التالية الواجب اتباعها.",
      "Liste des documents à fournir": "قائمة الوثائق المطلوب تقديمها",
      "Veuillez patientez ...": "يرجى الانتظار...",
      Notifications: "الإشعارات",
      "Nouvelle notification": "إشعار جديد",
      Accéder: "دخول",
      "Changer le mot de passe": "تغيير كلمة العبور",
      "La date sélectionnée ne peut pas être dans le futur. Veuillez choisir une date valide.":
        "خطأ: لا يمكن أن يكون التاريخ المحدد في المستقبل. الرجاء اختيار تاريخ صالح",
      "Veuillez entrer uniquement des lettres arabes.":
        "يرجى إدخال حروف عربية فقط.",
      "Veuillez entrer uniquement des lettres latines.":
        "يرجى إدخال حروف لاتينية فقط.",
      "Mettre à jours mes données": "تحديث بياناتي",
      "Mot de passe oublié ?": "هل نسيت كلمة المرور؟",
      "Veuillez entrer votre adresse e-mail":
        "يرجى إدخال عنوان بريدك الإلكتروني",
      "Sauvegarder au brouillon": "حفظ كمسودة",
      Confirmer: "تأكيد",
      "Liste de mes brouillons": "قائمة المسودات",
      "Liste de mes Annexes": "فروعي",
      "Date de création": "تاريخ الإحداث",
      "Sélectionnez la situation de l'Annexe": "يرجى اختيار حالة الفرع",
      "Créer une nouvelle Annexe": "إنشاء فرع جديد",
      "Vous devez disposer d'au moins un EPF pour pouvoir créer une annexe":
        "يجب أن يكون لديك على الأقل مؤسسة تدريب خاصة للتمكن من إنشاء ملحق",
      "EPF de l'Annexe": "المؤسسة الأم للفرع",
      "Date dernière modification": "تاريخ آخر تغيير",
      "Date de soumission": "تاريخ التقديم",
      "Aucune demande.": "ليس لديك مطالب",
      ID: "معرف",
      "Aucune donnée": "لا يوجد معطيات",
      Acceuil: "الإستقبال",
      acceuil: "الإستقبال",
      Profil: "الملف الشخصي",
      "Aucune notification": "لا يوجد اشعارات",
      "Veuillez vérifier votre email et cliquer sur le lien de confirmation pour valider votre compte.\nPensez à vérifier vos spams si nécessaire.":
        "يرجى التحقق من بريدك الإلكتروني والنقر على رابط التفعيل لتأكيد حسابك. لا تنسَ التحقق من مجلد الرسائل غير المرغوب فيها إذا لم تجد الرسالة في البريد الوارد.",
      "Numéro d’identifiant fiscal / RNE invalide":
        "رقم المعرف الجبائي لدى السجل الوطني للمؤسسات غير صالح.",
      "Le numéro RNE doit être au format 7 chiffres suivis d'une lettre majuscule (ex: 1234567A).":
        "يجب أن يكون رقم المعرف الجبائي لدى السجل الوطني للمؤسسات بالصيغة المكونة من 7 أرقام متبوعة بحرف كبير (مثال: 1234567A ).",
      "Veuillez entrer des données valides.": "يرجى إدخال بيانات صحيحة.",
      "Supprimer du brouillon": "حذف من المسودة",
      Supprimer: "حذف",
      "Non renseigné": "لا شيء",
      "Le numéro de téléphone doit être au format +216 xx xxx xxx.":
        "يجب أن يكون رقم الهاتف بالصيغة +216 xx xxx xxx.",
      "Merci d'entrer une adresse mail valide.":
        "يرجى إدخال عنوان بريد إلكتروني صالح.",
      "La valeur ne doit pas être inférieure à 0.": "يجب ألا تقل القيمة عن 0.",
      "Veuillez sélectionner 2 types de formation (Initiale et Continue).":
        "يرجى اختيار نوعين من التكوين (أولي و مستمر).",
      "Êtes-vous sûr de vouloir supprimer ce brouillon ?":
        "هل أنت متأكد أنك تريد حذف هذا المسودة؟",
      "Le numéro de CIN du formateur n'est pas associé à un compte sur la plateforme.":
        "رقم بطاقة الهوية الوطنية للمدرب غير مرتبط بحساب على المنصة.",
      "Le numéro de passeport du formateur n'est pas associé à un compte sur la plateforme.":
        "رقم جواز السفر الخاص بالمدرب غير مرتبط بحساب على المنصة.",
      "Veuillez entrer des données valides pour le personnel d'encadrement et de gestion.":
        "يرجى إدخال بيانات صحيحة للموظفين الإداريين والمشرفين.",
      "Nombre d'années d'expérience supérieure à votre âge":
        "عدد سنوات الخبرة يتجاوز عمرك",
      "Erreur lors de la création de votre compte.": "خطأ أثناء إنشاء حسابكم",
      "Veuillez remplir le champ avant de lancer la recherche":
        "يرجى ملء الخانة قبل بدء عملية البحث",
      "Adresse mail n'existe pas !": "خطأ ! البريد الإلكتروني غير موجود",
      "Les documents requis pour le complément de dossier":
        "المستندات المطلوبة لاستكمال الملف",
      "Envoyer les documents": "إرسال المستندات",
      "Veuillez télécharger un fichier.": "يرجى تحميل ملف.",
      "Téléchargement en cours...": "جاري التحميل...",
      "Erreur de téléchargement des fichiers.": "خطأ في تحميل الملفات.",
      "Tous les fichiers ont été téléchargés avec succès.":
        "تم تحميل جميع الملفات بنجاح.",
      "Complément de dossier": "إتمام الملف.",
      "Vous avez modifié des données sans les valider via l'icône de recherche.":
        "لقد قمت بتعديل البيانات دون التحقق منها عبر أيقونة البحث.",
      "Vous n'êtes pas autorisé à créer un établissement pour cette entreprise.":
        "ليس لديك الصلاحية لإنشاء هذا الهيكل لهذه الشركة.",
      "vous ne disposez pas d’un nom commercial. Pour continuer l’inscription vous êtes invité à prendre attache au RNE pour avoir un nom commercial.":
        "ليس لديك اسم تجاري. لمتابعة التسجيل، يُرجى الاتصال بالسجل الوطني للشركات للحصول على اسم تجاري.",
      "Aucune entreprise n'a été trouvée avec ce matricule fiscal.":
        "لم يتم العثور على أي شركة بهذا المعرف الجبائي.",
      "Aucune entreprise n'a été trouvée avec ce numéro enregistrement.":
        "لم يتم العثور على أي شركة بهذا الرقم التسجيل.",
      "Une copie RNE": "نسخة من السجل التجاري",
      "Une copie de PV changement de dirigeant": "نسخة من محضر تغيير المدير",
      "Un enregistrement existant avec ce couple RNE / Gouvernorat existe déjà. Il s'agit de la création d'une annexe, et non d'un nouvel EPF.":
        "يوجد سجل موجود بالفعل بهذا الزوج من رقم التسجيل الوطني و المعتمدية. هذه العملية تخص إنشاء ملحق وليس إنشاء مؤسسة تعليمية خاصة جديدة.",
      "Créer un EPF existant": "إحداث مؤسسة تكوين مهني خاصة موجودة",
      "Changer l'investisseur d'un EPF":
        "تغيير المستثمر لمؤسسة تكوين مهني خاصة",
      "Télécharger les documents": "تحميل المستندات",
      "Vous avez déjà commencé une demande de modification de ce type. Merci de consulter votre brouillon.":
        "لقد بدأتم سابقا بطلب تعديل من هذا النوع. يرجى الاطلاع على المسودة الخاصة بكم",
      info: "إشعار",
      "Choisissez une option": "اختر ما يناسبك",
      "Le numéro de matricule CNSS doit être composé de 8 chiffres (exemple : 11111111).":
        "من 8 أرقام (على سبيل المثال: 11111111) (CNSS) يجب أن يتكون رقم تسجيل الصندوق الوطني للضمان الاجتماعي",
      "Le numéro de patente doit respecter le format suivant : 7 chiffres, suivis de 3 lettres, puis de 3 chiffres (exemple : 1234567ABC123).":
        " (على سبيل المثال: 1234567ABC123). يجب أن يلتزم رقم البطاقة الجبائية بالتنسيق التالي: 7 أرقام، متبوعة بـ 3 أحرف، ثم 3 أرقام",

      "Vous avez modifié des données sans les valider en utilisant le bouton de recherche.":
        "لقد قمتم بتعديل البيانات دون التحقق منها باستخدام زر البحث.",
      "Le numéro de matricule fiscal ou le numéro IURNE doit respecter le format suivant : 7 chiffres suivis d'une lettre majuscule (exemple : 0000000A).":
        "يجب أن يلتزم رقم المعرّف الجبائي أو رقم IURNE بالتنسيق التالي: 7 أرقام تليها حرف كبير (مثال: 0000000A).",
      "Le numéro d'État secondaire doit être composé uniquement de 3 chiffres (exemple : 111).":
        "يجب أن يتكون رقم الحالة الثانوية من 3 أرقام فقط (مثال: 111).",
      "Ajouter une annexe": "إضافة فرع",
      "Mes annexes": " فروعي",
      "L’adresse de l’annexe doit être dans le même gouvernorat que l’EPF principal.":
        "يجب أن يكون عنوان الفرع في نفس الولاية التي تقع فيها المؤسسة الأم",
      "L’adresse de l’annexe doit être dans le même gouvernorat que l’EPF principal. Veuillez accéder à la rubrique ‘nouvel EPF’ pour enregistrer un établissement dans un gouvernorat différent.":
        " يجب أن يكون عنوان الملحق في نفس الولاية التي تقع فيها المؤسسة الأم. يرجى التوجه إلى قسم ''إضافة مؤسسة جديدة'' لتسجيل مؤسسة في ولاية مختلفة",
        "Matricule Fiscal inexistant":"المعرف الجبائي غير موجود",
        "Étape de l'obtention de la validation finale":"مرحلة الحصول على المصادقة النهائية",
        "Nécessite la validation de la direction régionale concernée et mise à jour du numéro d’enregistrement":"تعديلات تؤثر على وصل الإيداع وتتطلب موافقة إلزامية من الإدارة الجهوية.",
        "Veuillez valider les données en utilisant le bouton de recherche.":"يرجى التحقق من صحة البيانات باستخدام زر البحث.",
        "Vous pouvez télécharger vos documents (Déclaration, Reçu bureau d'ordre) en utilisant l'icône de téléchargement devant chaque demande.":"يمكنكم تحميل مستنداتكم (التصريح، وصل مكتب الضبط، وصل إيداع) باستخدام أيقونة التحميل الموجودة أمام كل طلب .",
        "Vous pouvez télécharger vos documents (Déclaration, Reçu bureau d'ordre, Bordereaux d'enregistrement) en utilisant l'icône de téléchargement devant chaque établissement.":"يمكنكم تحميل مستنداتكم (التصريح، وصل مكتب الضبط، وصل إيداع) باستخدام أيقونة التحميل الموجودة أمام كل مؤسسة.",
        "La réservation n’existe pas, le numéro de réservation est invalide.":"الحجز غير موجود، رقم الحجز غير صالح",
        "Il est impossible de supprimer tous les types de formations, car cela entraînerait la fermeture de l'EPF.":"لا يمكن حذف جميع أنواع التكوينات لأن ذلك سيؤدي إلى إغلاق مركز التكوين المهني الخاص.",
        "La connexion à votre compte E-Houwya a été réussie. Cependant, aucun compte de directeur n'est associé à votre profil sur la plateforme SIGAF !":"تمت عملية تسجيل الدخول إلى حسابك على E-Houwya بنجاح.لكن لا يوجد أي حساب مدير مرتبط بحسابك على منصة SIGAF.",
        "Ajouter un EPF existant":"إضافة مؤسسة تكوين مهني خاصة موجودة",
        "Changer l&#39;investisseur d&#39;un EPF":"تغيير المستثمر لمؤسسة تكوين مهني خاصة"
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("language") || "fr",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
