import React, { useState, useEffect, useContext } from "react";
import {
  Row,
  Card,
  Table,
  Tooltip,
  Tag,
  Space,
  Spin,
  notification,
} from "antd";
import {
  CarryOutOutlined,
  CloudUploadOutlined,
  CommentOutlined,
  EyeOutlined,
  FolderAddOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import SelectionModal from "../Etablissements/SelectionModal/SelectionModal";
import classes from "../Etablissements/Etablissements.module.css";
import { useTranslation } from "react-i18next";
import { getAffaires, getDocByIdAffaire, getSchema } from "../../api/geoprod";
import dayjs from "dayjs";
import GlobalContext from "../../context/GlobalContext";
import { useNavigate } from "react-router-dom";
import { useLoader } from "../../context/LoaderContext";
import axios from "axios";
import { downloadFile } from "../../utils";
import CommentModal from "../Etablissements/CreateEpf/Modals/CommentModal";

const Demandes = () => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const { setBrouillon } = useContext(GlobalContext);
  const [commentaire, setCommentaire] = useState([]);
  const idAffaire = new URLSearchParams(window.location.search).get("id");

  const navigate = useNavigate();
  const {
    setIsLoading,
    setSurveyJson,
    setSurveyData,
    setSurveyJsonValidationProvisoire,
  } = useLoader();
  const [loader, setLoader] = useState(false);
  const columns = [
    {
      title: t("Date de soumission"),
      key: "Date de soumission",
      render: (_, record) =>
        record?.affaire_date_creation
          ? dayjs(record?.affaire_date_creation).format("DD/MM/YYYY HH:MM:ss")
          : "Non renseigné",
    },
    {
      title: t("Dénomination"),
      key: "dénomination",
      render: (_, record) =>
        record?.establishment_data_commercial_name ||
        record?.establishment_data_official_name ||
        record?.legal_entity_official_name,
    },
    {
      title: t("Directeur"),
      dataIndex: "directeur",
      key: "directeur",
      render: (_, record) =>
        record?.establishment_director_data_last_name
          ? record?.establishment_director_data_last_name +
            " " +
            record?.establishment_director_data_first_name
          : "",
    },
    {
      title: t("Gouvernorat"),
      key: "gouvernorat",
      render: (_, record) => record?.establishment_data_governorate,
    },
    {
      title: t("Délégation"),
      dataIndex: "délégation",
      key: "délégation",
      render: (_, record) => record?.establishment_data_delegation,
    },
    {
      title: t("Matricule fiscal"),
      key: "matricule",
      render: (_, record) =>
        record.establishment_data_tax_number ||
        record.legal_entity_tax_number ||
        "non renseigné",
    },
    {
      title: t("Numéro de téléphone"),
      key: "phoneNum",
      render: (_, record) => record?.investor_phone_number,
    },
    {
      title: t("Nature de formation"),
      key: "natureForm",
      render: (_, record) => record?.establishment_data_training_type,
    },
    {
      title: t("Statut"),
      key: "statut",
      render: (_, record) => {
        return <Tag color={record?.etat_background}>{record?.etat}</Tag>;
      },
    },
    {
      title: t("Type"),
      key: "statut",
      render: (_, record) => {
        return (
          <Tag color={record?.etat_background}>
            {record?.affaire_id_contrat ===
            import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE
              ? t("Annexe")
              : record?.affaire_id_contrat ===
                import.meta.env
                  .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO
              ? t("Demande de modification affectant le BO")
              : record?.affaire_id_contrat ===
                import.meta.env
                  .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_SANS_BO
              ? t("Demande de modification n'affectant pas le BO")
              : record?.affaire_id_contrat ===
                import.meta.env.VITE_ID_LIGNE_PRODUIT_EPF_EXISTANTE
              ? t("EPF existant")
              : t("Principale")}
          </Tag>
        );
      },
    },
    {
      title: t("Actions"),
      key: "Actions",
      render: (_, record) => (
        <Space>
          <Tooltip title={t("Consulter")}>
            <EyeOutlined
              onClick={() => {
                setIsLoading(true);
                axios
                  .get(
                    import.meta.env.VITE_APP_GEOPROD_API +
                      "/get_info_specifique/" +
                      record?.affaire_id,
                    {
                      headers: {
                        Idsession: JSON.parse(
                          sessionStorage.getItem("-x-token-user")
                        )?.Acces_Token,
                      },
                    }
                  )
                  .then(async (res) => {
                    setIsLoading(false);
                    setSurveyJson(res?.data?.response?.form);

                    sessionStorage.setItem("demande", true);
                    const { form, data, current_step } = res?.data?.response;
                    const navigateTo = determineNavigationPath(record, form);
                    if (sessionStorage.getItem("role") === "directeur") {
                      sessionStorage.setItem(
                        "user_id_to_get",
                        res?.data?.response?.responsable_EPF
                      );
                    } else {
                      sessionStorage.setItem(
                        "user_id_to_get",
                        res?.data?.response?.id_user
                      );
                    }

                    if (navigateTo === "/etablissements/edit") {
                      setSurveyData(res?.data);
                      navigate(navigateTo);
                    } else {
                      navigate(navigateTo, {
                        state: {
                          id_affaire: record.affaire_id,
                        },
                      });
                      setSurveyJsonValidationProvisoire(
                        res?.data?.response?.form_valid
                      );
                      setSurveyData(data);
                    }

                    // if (res?.data?.response?.data) {
                    //   if (
                    //     record?.affaire_id_contrat ==
                    //     import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE
                    //   ) {
                    //     sessionStorage.setItem(
                    //       "id_affaire_annexe",
                    //       record.affaire_id
                    //     );
                    //     navigate(
                    //       "/annexes/save?affaire_id=" + record?.affaire_parent
                    //     );
                    //   } else if (
                    //     record?.affaire_id_contrat ==
                    //     import.meta.env.VITE_ID_LIGNE_PRODUIT_EPF_EXISTANTE
                    //   ) {
                    //     sessionStorage.setItem(
                    //       "idAffaireEpfExistant",
                    //       record.affaire_id
                    //     );
                    //     navigate("/etablissements/existante/save", {
                    //       state: {
                    //         idAffaireEpfExistant: record.affaire_id,
                    //       },
                    //     });
                    //   } else if (
                    //     record?.affaire_id_contrat ==
                    //       import.meta.env
                    //         .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO &&
                    //     res?.data?.response?.form?.pages?.find(
                    //       (item) => item?.name === "ChnangementInvestisseur"
                    //     )
                    //   ) {
                    //     const changementInvestisseurPage =
                    //       res?.data?.response?.form?.pages?.find(
                    //         (item) => item?.name === "ChnangementInvestisseur"
                    //       );
                    //     setSurveyJson(changementInvestisseurPage);
                    //     sessionStorage.setItem(
                    //       "idAffaireChangementInvestisseur",
                    //       record.affaire_id
                    //     );
                    //     navigate("/investisseur/changer", {
                    //       state: {
                    //         idAffaireChangementInvestisseur: record.affaire_id,
                    //       },
                    //     });
                    //   } else {
                    //     navigate("/etablissements/save", {
                    //       state: {
                    //         id_affaire: record.affaire_id,
                    //         etat_demande: record?.id_etat_dossier,
                    //       },
                    //     });
                    //   }
                    // }
                  })
                  .catch(() => {
                    setIsLoading(false);
                    notification.error({
                      message: t("Erreur"),
                      description:
                        "Une erreur a été rencontré lors de chargement de formulaire de l'epf existant",
                      duration: 0,
                    });
                  });
              }}
            />
          </Tooltip>
          {(record?.id_etat_dossier ===
            parseInt(import.meta.env.VITE_APP_ETAT_COMPLEMENT_DOSSIER) ||
            record?.id_etat_dossier ===
              parseInt(
                import.meta.env.VITE_APP_ETAT_COMPLEMENT_DOSSIER_MODIFICATION
              )) && (
            <Tooltip title={t("Complément de dossier")}>
              <FolderAddOutlined
                onClick={async () => {
                  setLoader(true);
                  const data = await getSchema(record.affaire_id);
                  // if(data?.response)
                  // {
                  navigate("/complement/dossier", {
                    state: {
                      id_affaire: record?.affaire_id,
                      data_complement_dossier:
                        data?.response?.complement_dossier,
                      complement_dossier_uploaded: data?.response?.key_urls,
                      affaire_id_contrat: record?.affaire_id_contrat,
                    },
                  });
                  // }
                  setLoader(false);
                }}
              />
            </Tooltip>
          )}
          {(record?.id_etat_dossier ===
            parseInt(import.meta.env.VITE_APP_ETAT_VALIDATION_PROVISOIRE) ||
            record?.id_etat_dossier ===
              parseInt(
                import.meta.env.VITE_APP_ETAT_COMPLEMENT_VALIDATION_PROVISOIRE
              )) && (
            <Tooltip title={t("Étape de l'obtention de la validation finale")}>
              <CarryOutOutlined
                onClick={async () => {
                  sessionStorage.setItem("consulter", false);
                  setIsLoading(true);
                  const data = await getSchema(record.affaire_id);
                  setSurveyJson(data?.response?.form_valid);
                  sessionStorage.setItem(
                    "contrat_id",
                    data?.response?.contrat_id
                  );
                  await setSurveyData(data?.response?.data);
                  setSurveyJsonValidationProvisoire(data?.response?.form_valid);
                  // if (response?.data?.data)
                  // {
                  navigate("/complement/validation/provisoire", {
                    state: {
                      id_affaire: record?.affaire_id,
                      etat_demande: record?.id_etat_dossier,
                    },
                  });
                  // }
                  setIsLoading(false);
                }}
              />
            </Tooltip>
          )}
          <Tooltip title={t("Télécharger: Déclaration / Décharge / BO")}>
            <CloudUploadOutlined
              onClick={async () => {
                setLoader(true);
                try {
                  const data = await getDocByIdAffaire(record?.affaire_id);
                  const documents = data?.data?.find(
                    (doc) =>
                      doc?.md5_id ===
                      import.meta.env.VITE_APP_ID_DOCUMENTS_RECU_EMAIL
                  );

                  if (documents?.files?.length) {
                    await Promise.all(
                      documents?.files.map((file) =>
                        downloadFile(file?.url, file?.name)
                      )
                    );
                  }
                } catch (error) {
                  console.error("Erreur lors du téléchargement :", error);
                } finally {
                  setLoader(false);
                }
              }}
            />
          </Tooltip>
          <CommentOutlined
            onClick={() => handleGetComment(record?.affaire_id)}
          />
        </Space>
      ),
    },
  ];
  const determineSelectedOption = (affaire_id_contrat) => {
    switch (affaire_id_contrat) {
      case import.meta.env
        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO:
        return "avec_bo_validation";
      case import.meta.env
        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_SANS_VALIDATION_SANS_BO:
        return "sans_bo_sans_validation";
      default:
        return "sans_bo_avec_validation";
    }
  };
  const determineNavigationPath = (record, form) => {
    const { affaire_id, affaire_id_contrat } = record;
    const { pages } = form;

    switch (affaire_id_contrat) {
      case import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE:
        sessionStorage.setItem("id_affaire_annexe", affaire_id);
        return "/annexes/save?affaire_id=" + record?.affaire_parent;
      case import.meta.env.VITE_ID_LIGNE_PRODUIT_EPF_EXISTANTE:
        sessionStorage.setItem("idAffaireEpfExistant", affaire_id);
        return "/etablissements/existante/save";
      case import.meta.env
        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO:
      case import.meta.env
        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_SANS_BO:
      case import.meta.env
        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_SANS_VALIDATION_SANS_BO:
        const pageChangementInvestisseur = pages?.find(
          (item) => item?.name === "ChnangementInvestisseur"
        );
        if (pageChangementInvestisseur) {
          sessionStorage.setItem("idAffaireChangementInvestisseur", affaire_id);
          setSurveyJson(pageChangementInvestisseur);
          return "/investisseur/changer";
        } else {
          const selectedOption = determineSelectedOption(affaire_id_contrat);
          sessionStorage.setItem("selectedOption", selectedOption);
          return `/etablissements/edit`;
        }
      default:
        return "/etablissements/save";
    }
  };
  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAffaires({
          id_user: JSON.parse(sessionStorage.getItem("-x-token-user")).id_user,
          id_entreprise: JSON.parse(sessionStorage.getItem("-x-token-user"))
            .user.entreprise_id,
          filters: {
            contrat: [],
            status: [],
          },
        });
        setDataSource(
          data?.aaData?.filter(
            (elem) =>
              elem?.id_etat_dossier_md5 !==import.meta.env.VITE_APP_ID_STATUS_BROUILLON  &&
              elem?.id_etat_dossier_md5 !==import.meta.env.VITE_APP_ID_STATUS_BROUILLON_EPF_EXISTANT &&
              elem?.id_etat_dossier_md5 !==import.meta.env.VITE_APP_ID_STATUS_VALIDER_EPF &&
              elem?.id_etat_dossier_md5 !== import.meta.env.VITE_APP_ID_STATUS_VALIDER_EPF_EXIST && 
              elem?.id_etat_dossier_md5 !== import.meta.env.VITE_APP_ID_STATUS_BROUILLON_SANS_VALIDATION &&
              elem?.id_etat_dossier_md5 !== import.meta.env.VITE_APP_ID_STATUS_BROUILLON_AVEC_VALIDATION  &&
              elem?.id_etat_dossier_md5 !== import.meta.env.VITE_APP_STATUS_VALIDEE_MODIFICATION_AVEC_VALIDATION
          )
        );
        let brouillon = data?.aaData.filter(
          (elem) =>
            elem.etat === "Brouillon" &&
            elem.affaire_id_contrat === import.meta.env.VITE_ID_LIGNE_PRODUIT
        );
        const brouillinAnnexe = data?.aaData.filter(
          (elem) =>
            elem.etat === "Brouillon" &&
            elem.affaire_id_contrat ===
              import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE
        );
        if (brouillinAnnexe.length > 0) {
          sessionStorage.setItem(
            "id_affaire_annexe",
            brouillinAnnexe[0]?.affaire_id
          );
          setBrouillon(brouillinAnnexe);
          setLoading(false);
        }
        if (brouillon.length > 0) {
          sessionStorage.setItem("id_affaire", brouillon[0]?.affaire_id);
          setBrouillon(brouillon);
          setLoading(false);
        }
        setLoading(false);
      } catch (error) {
        // notification.error({
        //   message: t("Error !"),
        //   description:
        //     error?.response?.data?.message ||
        //     "Une erreur s'est produite lors de la récupération des données",
        // });
      }
    }

    fetchData();

    if (idAffaire) {
      handleGetComment(idAffaire);
    }
  }, []);

  const handleGetComment = (id_affaire) => {
    setIsLoading(true);
    axios
      .get(
        `${
          import.meta.env.VITE_APP_GEOPROD_API
        }/commentaire/get_investisseur_commentaires?entity=A&id_entity=${id_affaire}`,
        {
          headers: {
            Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
              ?.Acces_Token,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.info?.length > 0) {
          setCommentaire(res?.data?.info);
        } else {
          notification.warning({
            message: "Vous ne disposez pas de commentaire pour cette demande !",
          });
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <Spin spinning={loader} tip="" style={{ width: "100%" }}>
      <div>
        <SelectionModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
        <CommentModal
          commentaire={commentaire}
          setCommentaire={setCommentaire}
        />

        {/* <Row className={classes.rowButtonCreate}>
        <Button className={classes.buttonCreate} onClick={openModalSelection}>
          <PlusOutlined />

          {t("Créer un nouvel EPF")}
        </Button>
      </Row> */}

        <Row className={classes.listeRow}>
          <Card
            className={classes.listeCard}
            bordered={false}
            title={
              <div>
                <div>{t("Liste de mes demandes")}</div>
                {dataSource?.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "14px",
                      marginTop: "10px",
                    }}
                  >
                    <InfoCircleOutlined
                      style={{ color: "#1890ff", marginRight: "8px" }}
                    />
                    <span>
                      {t(
                        "Vous pouvez télécharger vos documents (Déclaration, Reçu bureau d'ordre) en utilisant l'icône de téléchargement devant chaque demande."
                      )}
                    </span>
                  </div>
                )}
              </div>
            }
          >
            <Table
              locale={{ emptyText: t("Aucune demande.") }}
              loading={loading}
              columns={columns}
              dataSource={dataSource}
              className="ant-border-space"
              style={{ overflowX: "auto" }}
              pagination={true}
            />
          </Card>
        </Row>
      </div>
    </Spin>
  );
};

export default Demandes;
