/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, useMemo } from "react";
import {
  Row,
  Card,
  Table,
  Tooltip,
  Tag,
  Button,
  Space,
  notification,
  Modal,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import classes from "../Etablissements/Etablissements.module.css";
import { useTranslation } from "react-i18next";
import { getAffaires } from "../../api/geoprod";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import axios from "axios";
import GlobalContext from "../../context/GlobalContext";
import { useLoader } from "../../context/LoaderContext";

const Brouillons = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { brouillon, setBrouillon } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [confirmModal, setConfirmModal] = useState("");
  const [idASupprimer, setIdASupprimer] = useState("");
  const { setIsLoading, setSurveyJson, setSurveyData } = useLoader();

  const determineNavigationPath = (record, form) => {
    const { affaire_id, affaire_id_contrat } = record;
    const { pages } = form;

    switch (affaire_id_contrat) {
      case import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE:
        sessionStorage.setItem("id_affaire_annexe", affaire_id);
        return "/annexes/save?affaire_id=" + record?.affaire_parent;
      case import.meta.env.VITE_ID_LIGNE_PRODUIT_EPF_EXISTANTE:
        sessionStorage.setItem("idAffaireEpfExistant", affaire_id);
        return "/etablissements/existante/save";
      case import.meta.env
        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO:
      case import.meta.env
        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_SANS_BO:
      case import.meta.env
        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_SANS_VALIDATION_SANS_BO:
        const pageChangementInvestisseur = pages?.find(
          (item) => item?.name === "ChnangementInvestisseur"
        );
        if (pageChangementInvestisseur) {
          sessionStorage.setItem("idAffaireChangementInvestisseur", affaire_id);
          setSurveyJson(pageChangementInvestisseur);
          return "/investisseur/changer";
        } else {
          const selectedOption = determineSelectedOption(affaire_id_contrat);
          sessionStorage.setItem("selectedOption", selectedOption);
          return `/etablissements/edit`;
        }
      default:
        return "/etablissements/save";
    }
  };

  const determineSelectedOption = (affaire_id_contrat) => {
    switch (affaire_id_contrat) {
      case import.meta.env
        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO:
        return "avec_bo_validation";
      case import.meta.env
        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_SANS_VALIDATION_SANS_BO:
        return "sans_bo_sans_validation";
      default:
        return "sans_bo_avec_validation";
    }
  };

  const columns = [
    {
      title: t("Date de création"),
      key: "Date de création",
      render: (_, record) =>
        dayjs(record?.affaire_date_creation).format("DD/MM/YYYY"),
    },
    {
      title: t("Date dernière modification"),
      dataIndex: "Date dernière modification",
      key: "Date dernière modification",
      render: (_, record) =>
        record?.last_update
          ? dayjs(record.last_update).format("DD/MM/YYYY HH:mm:ss")
          : t("Non renseigné"),
    },

    {
      title: t("Statut"),
      key: "statut",
      render: (_, record) => {
        return <Tag color={record?.etat_background}>{record?.etat}</Tag>;
      },
    },
    {
      title: t("Type"),
      key: "statut",
      render: (_, record) => {
        return (
          <Tag color={record?.etat_background}>
            {record?.affaire_id_contrat ===
            import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE
              ? t("Annexe")
              : record?.affaire_id_contrat ===
                import.meta.env
                  .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO
              ? t("Demande de modification affectant le BO")
              : record?.affaire_id_contrat ===
                import.meta.env
                  .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_SANS_BO
              ? t("Demande de modification n'affectant pas le BO")
              : record?.affaire_id_contrat ===
                import.meta.env.VITE_ID_LIGNE_PRODUIT_EPF_EXISTANTE
              ? t("EPF existant")
              : record?.affaire_id_contrat ===
                import.meta.env
                  .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_SANS_VALIDATION_SANS_BO
              ? t("Demande de modification ne nécessite pas une validation")
              : t("Principale")}
          </Tag>
        );
      },
    },
    {
      title: t("Actions"),
      key: "Actions",
      render: (_, record) => (
        <Space>
          <Tooltip title={t("Modifier")}>
            <EditOutlined
              onClick={async () => {
                try {
                  setIsLoading(true);
                  const res = await axios.get(
                    import.meta.env.VITE_APP_GEOPROD_API +
                      "/get_info_specifique/" +
                      record?.affaire_id,
                    {
                      headers: {
                        Idsession: JSON.parse(
                          sessionStorage.getItem("-x-token-user")
                        )?.Acces_Token,
                      },
                    }
                  );
                  const { form, data, current_step } = res?.data?.response;
                  const navigateTo = determineNavigationPath(record, form);
                  sessionStorage.setItem("current_step", current_step);
                  if (sessionStorage.getItem("role") === "directeur") {
                    sessionStorage.setItem(
                      "user_id_to_get",

                      res?.data.response?.id_user
                    );
                  } else {
                    sessionStorage.setItem(
                      "user_id_to_get",
                      res?.data.response?.responsable_EPF
                    );
                  }
                  if (navigateTo === "/etablissements/edit") {
                    setSurveyData(res?.data);
                  } else {
                    setSurveyData(data);
                  }
                  setSurveyJson(form);

                  navigate(navigateTo);
                  //   setSurveyJson(res?.data?.response?.form);
                  //   await setSurveyData(res?.data?.response?.data);

                  //   if (res?.data?.response?.data) {
                  //     if (
                  //       record?.affaire_id_contrat ===
                  //       import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE
                  //     ) {
                  //       sessionStorage.setItem(
                  //         "id_affaire_annexe",
                  //         record?.affaire_id
                  //       );
                  //       navigate(
                  //         "/annexes/save?affaire_id=" + record?.affaire_parent
                  //       );
                  //     } else {
                  //       if (
                  //         record?.affaire_id_contrat ==
                  //         import.meta.env.VITE_ID_LIGNE_PRODUIT_EPF_EXISTANTE
                  //       ) {
                  //         sessionStorage.setItem(
                  //           "idAffaireEpfExistant",
                  //           record?.affaire_id
                  //         );
                  //         navigate(`/etablissements/existante/save`, {
                  //           state: {
                  //             idAffaireEpfExistant: record.affaire_id,
                  //           },
                  //         });
                  //       } else if (
                  //         record?.affaire_id_contrat ==
                  //           import.meta.env
                  //             .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO ||
                  //         record?.affaire_id_contrat ==
                  //           import.meta.env
                  //             .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_SANS_BO ||
                  //         import.meta.env
                  //           .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_SANS_VALIDATION_SANS_BO
                  //       ) {
                  //         const pageChangementInvestisseur =
                  //           res?.data?.response?.form?.pages?.find(
                  //             (item) => item?.name === "ChnangementInvestisseur"
                  //           );
                  //         if (pageChangementInvestisseur) {
                  //           sessionStorage.setItem(
                  //             "idAffaireChangementInvestisseur",
                  //             record?.affaire_id
                  //           );
                  //           await setSurveyJson(pageChangementInvestisseur);
                  //           await setSurveyData(res?.data?.response?.data);
                  //           navigate("/investisseur/changer", {
                  //             state: {
                  //               idAffaireChangementInvestisseur:
                  //                 record?.affaire_id,
                  //             },
                  //           });
                  //         } else {

                  //           await setSurveyData(res?.data);
                  //           const selectedOption =
                  //             record?.affaire_id_contrat ===
                  //             import.meta.env
                  //               .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO
                  //               ? "avec_bo_validation"
                  //               : record?.affaire_id_contrat ===
                  //                 import.meta.env
                  //                   .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_SANS_VALIDATION_SANS_BO
                  //               ? "sans_bo_sans_validation"
                  //               : "sans_bo_avec_validation";

                  //               if(selectedOption === "avec_bo_validation"){
                  //                 let form= res?.data?.response?.form
                  //                 form.pages=res?.data?.response?.form.pages.filter((page)=>page.name!=="ChnangementInvestisseur")
                  //                 setSurveyJson(form);
                  //                }

                  //           sessionStorage.setItem(
                  //             "selectedOption",
                  //             selectedOption
                  //           );

                  //           navigate(`/etablissements/edit`, {
                  //             state: {
                  //               id_affaire: record.affaire_id,
                  //               surveyData: res?.data,
                  //               type: "Brouillon",
                  //             },
                  //           });
                  //         }
                  //       } else {
                  //         navigate(`/etablissements/save`);
                  //       }
                  //     }
                  //   }
                } catch {
                  notification.error({
                    message: t("Erreur"),
                    description:
                      "Une erreur a été rencontré lors de chargement de formulaire de l'epf existant",
                    duration: 0,
                  });
                } finally {
                  setIsLoading(false);
                }
              }}
            />
          </Tooltip>
          <Tooltip title={t("Supprimer")}>
            <DeleteOutlined
              onClick={() => {
                setConfirmModal(record?.affaire_id);
                const idAffaire =
                  record?.affaire_id_contrat ===
                  import.meta?.env?.VITE_ID_LIGNE_PRODUIT_EPF_EXISTANTE
                    ? "idAffaireEpfExistant"
                    : record?.affaire_id_contrat ===
                      import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE
                    ? "id_affaire_annexe"
                    : record?.affaire_id_contrat ===
                        import.meta.env
                          .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO &&
                      record.investor_page === null
                    ? "idAffaireChangementInvestisseur"
                    : record?.affaire_id_contrat ===
                      import.meta.env
                        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO
                    ? "id_affaire_edit_avec_bo_validation"
                    : record?.affaire_id_contrat ===
                      import.meta.env
                        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_SANS_BO
                    ? "id_affaire_edit_sans_bo_avec_validation"
                    : record?.affaire_id_contrat ===
                      import.meta.env
                        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_SANS_VALIDATION_SANS_BO
                    ? "id_affaire_edit_sans_bo_sans_validation"
                    : "id_affaire";
                setIdASupprimer(idAffaire);
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const deleteBrouillon = () => {
    setIsLoading(true);
    axios
      .delete(
        `${
          import.meta.env.VITE_APP_GEOPROD_API
        }/delete_affaire/${confirmModal}`,
        {
          headers: {
            Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
              .Acces_Token,
          },
        }
      )
      .then((res) => {
        setBrouillon((prevDataSource) =>
          prevDataSource.filter((item) => item.affaire_id !== confirmModal)
        );
        setConfirmModal("");
        setIsLoading(false);
        // sessionStorage.removeItem("id_affaire");
        sessionStorage.removeItem(idASupprimer);
        notification.success({
          message: "Votre brouillon a été supprimé avec succès",
          duration: 0,
        });
      })
      .catch(() => {
        setConfirmModal("");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAffaires({
          id_user: JSON.parse(sessionStorage.getItem("-x-token-user")).id_user,
          id_entreprise: JSON.parse(sessionStorage.getItem("-x-token-user"))
            .user.entreprise_id,
          filters: {
            contrat: [],
            status: [
              import.meta.env.VITE_APP_ID_STATUS_BROUILLON_SANS_VALIDATION,
              import.meta.env.VITE_APP_ID_STATUS_BROUILLON_AVEC_VALIDATION,
              import.meta.env.VITE_APP_ID_STATUS_BROUILLON_EPF_EXISTANT,
              import.meta.env.VITE_APP_ID_STATUS_BROUILLON,
            ],
          },
        });
        setBrouillon(data?.aaData);
        setLoading(false);
      } catch (error) {
        notification.error({
          message: t("Error !"),
          description:
            error?.response?.data?.message ||
            "Une erreur s'est produite lors de la récupération des données",
          duration: 0,
        });
      }
    }

    fetchData();
  }, []);

  return (
    <div>
      <Modal
        open={confirmModal !== ""}
        footer={null}
        width={600}
        onCancel={() => setConfirmModal("")}
      >
        <h4> {t("Êtes-vous sûr de vouloir supprimer ce brouillon ?")} </h4>
        <Button type="primary" onClick={deleteBrouillon}>
          {t("Oui")}
        </Button>
        <Button
          type="primary"
          style={{ marginLeft: "1rem" }}
          onClick={() => setConfirmModal("")}
        >
          {" "}
          {t("Non")}
        </Button>
      </Modal>

      <Row className={classes.listeRow}>
        <Card
          className={classes.listeCard}
          bordered={false}
          title={t("Liste de mes brouillons")}
        >
          <Table
            locale={{ emptyText: t("Aucune donnée") }}
            loading={loading}
            columns={columns}
            dataSource={brouillon}
            className="ant-border-space"
            style={{ overflowX: "auto" }}
            pagination={{
              pageSize: 5,
            }}
          />
        </Card>
      </Row>
    </div>
  );
};

export default Brouillons;
