import { useEffect } from "react";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import {
  updateActionAffaire,
  updateAffaire,
  uploadDocumentAffaire,
} from "../../../api/geoprod";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomWidgetCollection, Model } from "survey-core";
import DatePikckerComponent from "../../Etablissements/CreateEpf/DatePIkcer";

import { notification } from "antd";
import axios from "axios";
import mime from "mime";
import { t } from "i18next";
import RefreshBarComponent from "../../Etablissements/CreateEpf/RefreshBarComponent";
import { useLoader } from "../../../context/LoaderContext";
import ButtonComponent from "../../Etablissements/CreateEpf/ButtonComponent";
import { settings } from "survey-core";
import { getCommuneByCodeCommune } from "../../../context/FetchLists";
import { themeJson } from "../../Etablissements/CreateEpf/CreateEpfComponents/creationJson";


settings.customIcons["icon-clear"] = "icon-delete";
settings.customIcons["icon-choosefile"] = "icon-modernbooleancheckchecked";

const ValidationProvisoire = () => {
  const params = useLocation();
  const navigate = useNavigate();
  const { getSurveyData, getSurveyJsonValidationProvisoire } = useLoader();
  const contrat_id = sessionStorage.getItem("contrat_id")

  const surveyJson = getSurveyJsonValidationProvisoire();
//const surveyJson = 
// {
//   "completeText": "Soumettre la demande - تقديم المطلب",
//   "editText": "Modifier - تعديل",
//   "logoPosition": "right",
//   "pageNextText": "Suivant - متابعة",
//   "pagePrevText": "Précédant - رجوع",
//   "pages": [
//       {
//           "description": "",
//           "elements": [
//               {
//                   "elements": [
//                       {
//                           "errorLocation": "bottom",
//                           "hideNumber": true,
//                           "isRequired": true,
//                           "maskSettings": {
//                               "pattern": "9999999a"
//                           },
//                           "maskType": "pattern",
//                           "name": "numero_matricule_fiscal_numero_IURNE",
//                           "startWithNewLine": false,
//                           "title": "Matricule fiscal / Numéro IURNE - رقم المعرّف الجبائي",
//                           "type": "text"
//                       },
//                       {
//                           "errorLocation": "bottom",
//                           "hideNumber": true,
//                           "isRequired": true,
//                           "maskSettings": {
//                               "pattern": "999"
//                           },
//                           "maskType": "pattern",
//                           "name": "numero_etablissement_secondaire",
//                           "startWithNewLine": false,
//                           "title": "Numéro établissement secondaire - رقم الفرع الثانوي",
//                           "type": "text"
//                       },
//                       {
//                           "hideNumber": true,
//                           "inputType": "Button",
//                           "maxWidth": "10%",
//                           "name": "data_validation_provisoire_search",
//                           "startWithNewLine": false,
//                           "title": " ",
//                           "type": "text"
//                       },
//                       {
//                           "errorLocation": "bottom",
//                           "hideNumber": true,
//                           "isRequired": true,
//                           "name": "numero_matricule_CNSS",
//                           "readOnly": true,
//                           "startWithNewLine": true,
//                           "title": "Numéro de matricule CNSS - رقم الانخراط في الصندوق الوطني للضمان الاجتماعي",
//                           "type": "text",
//                           "visible": false
//                       },
//                       {
//                           "hideNumber": true,
//                           "isRequired": true,
//                           "name": "denomination_officielle_CNSS",
//                           "readOnly": true,
//                           "startWithNewLine": false,
//                           "title": "Raison CNSS - تسمية المؤسسة لدى الصندوق الوطني للضمان الاجتماعي",
//                           "type": "text",
//                           "visible": false
//                       },
//                       {
//                           "hideNumber": true,
//                           "isRequired": true,
//                           "name": "denomination_officielle_RNE_arabe",
//                           "readOnly": true,
//                           "title": "Dénomination officielle RNE (en arabe) - (بالعربية) التسمية الرسمية",
//                           "type": "text",
//                           "visible": false
//                       },
//                       {
//                           "hideNumber": true,
//                           "isRequired": true,
//                           "name": "denomination_officielle_RNE_latin",
//                           "readOnly": true,
//                           "startWithNewLine": false,
//                           "title": "Dénomination officielle RNE (en français) - (بالفرنسية) التسمية الرسمية",
//                           "type": "text",
//                           "visible": false
//                       },
//                       {
//                           "hideNumber": true,
//                           "isRequired": true,
//                           "name": "denomination_commerciale_RNE_arabe",
//                           "readOnly": true,
//                           "startWithNewLine": true,
//                           "title": "Dénomination commerciale  RNE (en arabe) - (بالعربية) التسمية التجارية",
//                           "type": "text",
//                           "visible": false
//                       },
//                       {
//                           "hideNumber": true,
//                           "isRequired": true,
//                           "name": "denomination_commerciale_RNE_latin",
//                           "readOnly": true,
//                           "startWithNewLine": false,
//                           "title": "Dénomination commerciale RNE (en français) - (بالفرنسية) التسمية التجارية",
//                           "type": "text",
//                           "visible": false
//                       },
//                       {
//                           "hideNumber": true,
//                           "isRequired": true,
//                           "name": "adresse_EPF",
//                           "readOnly": true,
//                           "title": "Adresse EPF - عنوان المؤسسة",
//                           "type": "text",
//                           "visible": false
//                       },
//                       {
//                           "hideNumber": true,
//                           "isRequired": true,
//                           "name": "adresse_epf_DGI",
//                           "readOnly": true,
//                           "startWithNewLine": false,
//                           "title": "Adresse EPF (DGI) - عنوان المؤسسة",
//                           "type": "text",
//                           "visible": false
//                       },
//                       {
//                           "hideNumber": true,
//                           "isRequired": true,
//                           "name": "adresse_epf_RNE",
//                           "readOnly": true,
//                           "startWithNewLine": false,
//                           "title": "Adresse EPF (RNE) - عنوان المؤسسة",
//                           "type": "text",
//                           "visible": false
//                       },
//                       {
//                           "hideNumber": true,
//                           "isRequired": true,
//                           "name": "activite_principale_RNE",
//                           "readOnly": true,
//                           "title": "Activité principale (RNE) - نشاط رئيسي",
//                           "type": "text",
//                           "visible": false
//                       },
//                       {
//                           "hideNumber": true,
//                           "isRequired": true,
//                           "name": "activite_secondaire_RNE",
//                           "readOnly": true,
//                           "startWithNewLine": false,
//                           "title": "Activité secondaire (RNE) - نشاط ثانوي",
//                           "type": "text",
//                           "visible": false
//                       },
//                       {
//                           "hideNumber": true,
//                           "isRequired": true,
//                           "name": "activite_principale_DGI",
//                           "readOnly": true,
//                           "title": "Activité principale (DGI) - نشاط رئيسي",
//                           "type": "text",
//                           "visible": false
//                       },
//                       {
//                           "hideNumber": true,
//                           "isRequired": true,
//                           "name": "activite_secondaire_DGI",
//                           "readOnly": true,
//                           "startWithNewLine": false,
//                           "title": "Activité secondaire (DGI) - نشاط ثانوي",
//                           "type": "text",
//                           "visible": false
//                       },
//                       {
//                           "errorLocation": "bottom",
//                           "hideNumber": true,
//                           "inputType": "date",
//                           "isRequired": true,
//                           "name": "date_expiration_protection_civile",
//                           "title": "Date de l’expiration de l'attestation de la protection civile - تاريخ انتهاء صلاحية شهادة الوقاية",
//                           "type": "text",
//                           "visible": false
//                       },
//                       {
//                           "acceptedTypes": ".jpg,.jpeg,.png,.pdf",
//                           "errorLocation": "bottom",
//                           "filePlaceholder": "Téléchargez une copie du RNE - قم بتحميل نسخة من مضمون السجل الوطني للمؤسسات ",
//                           "hideNumber": true,
//                           "isRequired": true,
//                           "name": "doc_rne",
//                           "storeDataAsText": false,
//                           "title": "RNE - مضمون السجل الوطني للمؤسسات",
//                           "type": "file",
//                           "visible": false
//                       },
//                       {
//                           "acceptedTypes": ".jpg,.jpeg,.png,.pdf",
//                           "errorLocation": "bottom",
//                           "filePlaceholder": "Téléchargez une copie de la Patente - قم بتحميل نسخة من  بطاقة التعريف الجبائي - الباتيندة",
//                           "hideNumber": true,
//                           "isRequired": true,
//                           "name": "doc_patente",
//                           "startWithNewLine": false,
//                           "storeDataAsText": false,
//                           "title": "Patente - بطاقة التعريف الجبائي - الباتيندة",
//                           "type": "file",
//                           "visible": false
//                       },
//                       {
//                           "acceptedTypes": ".jpg,.jpeg,.png,.pdf",
//                           "errorLocation": "bottom",
//                           "filePlaceholder": "Téléchargez une copie de l'affiliation CNSS - قم بتحميل نسخة من شهادة الانخراط بالصندوق الوطني للضمان الاجتماع",
//                           "hideNumber": true,
//                           "isRequired": true,
//                           "name": "doc_affiliation_CNSS",
//                           "startWithNewLine": false,
//                           "storeDataAsText": false,
//                           "title": "Affiliation CNSS - نسخة من شهادة الانخراط بالصندوق الوطني للضمان الاجتماعي",
//                           "type": "file",
//                           "visible": false
//                       },
//                       {
//                           "acceptedTypes": ".jpg,.jpeg,.png,.pdf",
//                           "errorLocation": "bottom",
//                           "filePlaceholder": "Téléchargez une copie de l'attestation de la protection civile - قم بتحميل نسخة من شهادة الوقاية",
//                           "hideNumber": true,
//                           "isRequired": true,
//                           "name": "doc_attestation_protection_civile",
//                           "storeDataAsText": false,
//                           "title": "Attestation de la protection civile - شهادة الوقاية",
//                           "type": "file",
//                           "visible": false
//                       },
//                       {
//                           "acceptedTypes": ".jpg,.jpeg,.png,.pdf",
//                           "errorLocation": "bottom",
//                           "filePlaceholder": "Téléchargez une copie de B3 de l'investisseur - قم بتحميل نسخة من  بطاقة السوابق العدلية عدد 3 للمستثمر",
//                           "hideNumber": true,
//                           "isRequired": true,
//                           "name": "doc_b3_investisseur",
//                           "startWithNewLine": false,
//                           "storeDataAsText": false,
//                           "title": "B3 de l'investisseur - بطاقة السوابق العدلية عدد 3 للمستثمر",
//                           "type": "file",
//                           "visible": false
//                       },
//                       {
//                           "acceptedTypes": ".jpg,.jpeg,.png,.pdf",
//                           "errorLocation": "bottom",
//                           "filePlaceholder": "Téléchargez une copie du B3 du directeur - قم بتحميل نسخة من بطاقة السوابق العدلية عدد 3 للمدير",
//                           "hideNumber": true,
//                           "isRequired": true,
//                           "name": "doc_b3_directeur",
//                           "startWithNewLine": false,
//                           "storeDataAsText": false,
//                           "title": "B3 du directeur - بطاقة السوابق العدلية عدد 3 للمدير",
//                           "type": "file",
//                           "visible": false
//                       },


//                       {
//                         "acceptedTypes": ".jpg,.jpeg,.png,.pdf",
//                         "errorLocation": "bottom",
//                         "filePlaceholder": "Téléchargez une copie du B3 du responsable de l'annexe - قم بتحميل نسخة من بطاقة السوابق العدلية عدد 3 للمسؤول عن الفرع",
//                         "hideNumber": true,
//                         "isRequired": true,
//                         "name": "doc_b3_responsable",
//                         "startWithNewLine": false,
//                         "storeDataAsText": false,
//                         "title": "B3 du responsable de l'annexe - بطاقة السوابق العدلية عدد 3 للمسؤول عن الفرع",
//                         "type": "file",
//                         "visible": false
//                     },


//                       {
//                           "acceptedTypes": ".jpg,.jpeg,.png,.pdf",
//                           "errorLocation": "bottom",
//                           "filePlaceholder": "Téléchargez d'autres documents - قم بتحميل وثائق أخرى ",
//                           "hideNumber": true,
//                           "isRequired": false,
//                           "name": "doc_autres",
//                           "storeDataAsText": false,
//                           "title": "Autres documents - وثائق أخرى",
//                           "type": "file",
//                           "visible": false
//                       },
//                       {
//                           "hideNumber": true,
//                           "inputType": "refreshbar",
//                           "name": "data_spinner",
//                           "startWithNewLine": false,
//                           "title": " ",
//                           "type": "text",
//                           "visible": false
//                       },
//                       {
//                           "name": "validation_provisoire_check",
//                           "type": "text",
//                           "visible": false
//                       }
//                   ],
//                   "name": "question1",
//                   "type": "panel"
//               }
//           ],
//           "name": "page1"
//       }
//   ],
//   "previewText": "Aperçu - عرض",
//   "progressBarShowPageTitles": false,
//   "sendResultOnPageNext": true,
//   "showCompletedPage": false,
//   "showPreviewBeforeComplete": false,
//   "storeOthersAsComment": false
// }
  const survey = new Model(surveyJson);
  survey.applyTheme(themeJson);
  const onUploadFiles = (survey, id_affaire, id_type_doc) => {
    survey.onUploadFiles.add((_, options) => {
      const formData = new FormData();
      options.files.forEach((file) => {
        formData.append("id_affaire", id_affaire);
        formData.append("id_type_doc", id_type_doc);
        formData.append("name_doc", options.name);
        formData.append("file[]", file);
        formData.append("key", options.name);
      });

      uploadDocumentAffaire(formData)
        .then((data) => {
          options.callback(
            options.files.map((file) => {
              return {
                file: file,
                content: data.data.path[0],
              };
            })
          );
        })
        .catch((error) => {
          options.callback(
            [],
            ["Veuillez importer seulement des images et des documents pdf."]
          );
        });
    });
  };

  survey.onAfterRenderQuestion.add(function (sender, options) {   
    const fileInputButton =
      options.htmlElement.querySelector(".sd-file__wrapper");
    if (fileInputButton) {
      const spanElement = fileInputButton.querySelector("span");
      if (spanElement) {
        spanElement.textContent = "Télécharger le fichier - تحميل الملف";
      }
    }
    const fileInputButtonBeforeChoose = options.htmlElement.querySelector(
      ".sd-file__choose-btn"
    );
    if (fileInputButtonBeforeChoose) {
      const spanElement = fileInputButtonBeforeChoose.querySelector("span");
      if (spanElement) {
        spanElement.textContent = "Télécharger le fichier - تحميل الملف";
      }
    }

    const emptyTextDiv = options.htmlElement.querySelector('.sv-list__empty-text');
    if (emptyTextDiv) {
      emptyTextDiv.textContent = "Veuillez d'abord remplir le champ précédent !";
  }

    const placeholderNoFile = options.htmlElement.querySelector(
      ".sd-file__decorator"
    );
    if (placeholderNoFile) {
      const placeholder = placeholderNoFile.querySelector(
        ".sd-file__drag-area-placeholder"
      );
      if (placeholder) {
        const spanElement = placeholder.querySelector(".sv-string-viewer");
        if (spanElement && spanElement.textContent === "No file selected") {
          spanElement.textContent =
            "Aucun fichier téléchargé - لم يتم تحميل أي ملف";
        }
      }
    }
  });

  onUploadFiles(
    survey,
    params?.state?.id_affaire,
    import.meta.env.VITE_APP_ID_TYPE_DOC_COMPLEMENT_VALIDATION_PROVISOIRE
  );
  CustomWidgetCollection.Instance.addCustomWidget({
    name: "datepicker",
    title: "Date picker",
    isFit: function (question) {
      return (
        question.getType() === "text" && question.jsonObj.inputType === "date"
      );
    },
    render: function (question, element) {
      return <DatePikckerComponent question={question} survey={survey} />;
    },
  });
  const onValueChanging = (survey) => {
    const page = survey.getPageByName("page1");
    survey.onValueChanged.add(async function (sender, options) {
      if (
        options.name === "numero_matricule_fiscal_numero_IURNE" ||
        options.name === "numero_etablissement_secondaire"
      ) {
        page.questions.forEach((question) => {
          const valuesVisible = [
            "numero_etablissement_secondaire",
            "numero_matricule_fiscal_numero_IURNE",
            "data_validation_provisoire_search",
          ];
          if (!valuesVisible.includes(question.name)) {
            survey.setValue("validation_provisoire_check", false);
            question.visible = false;
            question.clearValue();
          }
        });
      }
    });
  };

  const getExtraitRegistreEntrepriseParId = async (survey, data) => {
    const value = survey.getValue("numero_matricule_fiscal_numero_IURNE");
    const etat_secondaire = survey.getValue("numero_etablissement_secondaire");
    const inverstor_type = data?.inverstor_type;
    const investor_cin = data?.investor_cin;
    try {
      const response = await axios.get(
        `${
          import.meta.env.VITE_APP_GEOPROD_API
        }/config/RNE_ExtraitRegistreEntrepriseParId?Identifiant_Unique=${value}`
      );
      survey.setValue("adresse_EPF", data?.legal_entity_head_office_address);
      if(response?.data?.message?.code === "404")
      {
         notification.error({
            message: t('Erreur'),
            description: t("La réservation n’existe pas, le numéro de réservation est invalide."),
            duration: 0,
          });
          survey.getQuestionByName("data_spinner").visible = false;
          return false;
  
      }
      else
      {
        const personnePhysique = response?.data?.message?.direction?.find(
          (p) => p?.cin === investor_cin
        );
  
        
        // if (inverstor_type === "شخص معنوي") {
          survey.setValue(
            "denomination_officielle_RNE_arabe",
            response?.data?.message?.nomAssociation
              ? response?.data?.message?.nomAssociation
              : response?.data?.message?.nomSociete
          );
          survey.setValue(
            "denomination_officielle_RNE_latin",
            response?.data?.message?.nomAssociationLatin
              ? response?.data?.message?.nomAssociationLatin
              : response?.data?.message?.nomSocieteLatin
          );

        // } else {
        //   survey.setValue(
        //     "denomination_officielle_RNE_arabe",
        //     personnePhysique?.nomPersonne
        //   );
        //   survey.setValue("denomination_officielle_RNE_latin", "");
        // }
  
        survey.setValue(
          "denomination_commerciale_RNE_arabe",
          response?.data?.message?.nomCommercial
        );
        survey.setValue(
          "denomination_commerciale_RNE_latin",
          response?.data?.message?.nomCommercialLatin
        );
  
        if (etat_secondaire === "000") {
          // let commune = await getCommuneByCodeCommune(response?.data?.message?.rueSociete ? response?.data?.message?.villeSociete : response?.data?.message?.villeSiege);
          survey.setValue(
            "adresse_epf_RNE",
            response?.data?.message?.rueSociete
              ? response?.data?.message?.rueSociete +
                  " " +
                  response?.data?.message?.villeSociete
              : response?.data?.message?.rueSiege +
                  " " +
                  response?.data?.message?.villeSiege
          );
        }
        return  response?.data?.message
      }

      // survey.getQuestionByName("data_spinner").visible = false;
    } catch (error) {
      survey.getQuestionByName("data_spinner").visible = false;
      notification.error({
        message: t("Erreur"),
        description: `Erreur lors de la récupération des données.`,
      });
      throw error;
    }
  };

  const getEmployeurByPatNumPatCL = async (survey) => {
    const value = survey.getValue("numero_matricule_fiscal_numero_IURNE");
    // survey.getQuestionByName("data_spinner").visible = true;
    try {
      const response = await axios.get(
        `${
          import.meta.env.VITE_APP_GEOPROD_API
        }/config/CNSS_EmployeurByPatNumPatCl?patNum=${value.slice(
          0,
          7
        )}&patCle=${value.slice(-1)}`
      );
      // survey.getQuestionByName("data_spinner").visible = false;
      survey.setValue(
        "numero_matricule_CNSS",
        `${response?.data?.message?.id?.empMat}${response?.data?.message?.id?.empCle}`
      );
      survey.setValue(
        "denomination_officielle_CNSS",
        response?.data?.message?.empRais
      );
    } catch (error) {
      survey.getQuestionByName("data_spinner").visible = false;
      notification.error({
        message: t("Erreur"),
        description: `Erreur lors de la récupération des données.`,
      });
      throw error;
    }
  };

  const getFicheEntrepriseDGI = async (survey) => {
    // ok
    const value = survey.getValue("numero_matricule_fiscal_numero_IURNE");
    // survey.getQuestionByName("data_spinner").visible = true;
    try {
      const response = await axios.get(
        `${
          import.meta.env.VITE_APP_GEOPROD_API
        }/config/FicheEntreprise?typeid=${1}&ident=${value}`
      );

      // survey.getQuestionByName("data_spinner").visible = false;
      survey.setValue("adresse_epf_DGI", response?.data?.message?.adresse);
      survey.setValue(
        "activite_principale_DGI",
        response?.data?.message?.activiteP
      );
      survey.setValue(
        "activite_secondaire_DGI",
        response?.data?.message?.activiteS
      );
    } catch (error) {
      survey.getQuestionByName("data_spinner").visible = false;
      notification.error({
        message: t("Erreur"),
        description: `Erreur lors de la récupération des données.`,
      });
      throw error;
    }
  };

  const getListActiviteRNE = async (survey, data) => {
    const value = survey.getValue("numero_matricule_fiscal_numero_IURNE");
    // survey.getQuestionByName("data_spinner").visible = true;
    try {
      const response = await axios.get(
        `${
          import.meta.env.VITE_APP_GEOPROD_API
        }/config/RNE_Listeactivites?Identifiant_Unique=${value}`
      );
      // survey.getQuestionByName("data_spinner").visible = false;

      const firstPrimary = response?.data?.message?.Activities?.activite.find(
        (activity) => activity?.type_activite === "PRIMAIRE"
      );
      const firstSecondary = response?.data?.message?.Activities?.activite.find(
        (activity) => activity?.type_activite === "SECONDAIRE"
      );
      survey.setValue(
        "activite_principale_RNE",
        firstPrimary?.detail_activite_arabe
      );
      survey.setValue(
        "activite_secondaire_RNE",
        firstSecondary?.detail_activite_arabe
      );

      survey.getQuestionByName("numero_matricule_CNSS").visible = true;
      survey.getQuestionByName("denomination_officielle_CNSS").visible = true;
      survey.getQuestionByName(
        "denomination_officielle_RNE_arabe"
      ).visible = true;
      survey.getQuestionByName(
        "denomination_officielle_RNE_latin"
      ).visible = true;
      survey.getQuestionByName(
        "denomination_commerciale_RNE_arabe"
      ).visible = true;
      survey.getQuestionByName(
        "denomination_commerciale_RNE_latin"
      ).visible = true;
      survey.getQuestionByName("adresse_EPF").visible = true;
      survey.getQuestionByName("adresse_epf_DGI").visible = true;
      survey.getQuestionByName("activite_principale_DGI").visible = true;
      survey.getQuestionByName("activite_secondaire_DGI").visible = true;

      survey.getQuestionByName(
        "date_expiration_protection_civile"
      ).visible = true;
      survey.getQuestionByName("doc_rne").visible = true;
      survey.getQuestionByName("doc_patente").visible = true;
      survey.getQuestionByName("doc_affiliation_CNSS").visible = true;
      survey.getQuestionByName(
        "doc_attestation_protection_civile"
      ).visible = true;
      if (data?.investor_nationality === "تونسية") {
        survey.getQuestionByName("doc_b3_investisseur").visible = true;
      }

if (data?.establishment_director_data_identity === "مدير غير المستثمر") {
  const questionName = contrat_id === import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE 
    ? "doc_b3_responsable" 
    : "doc_b3_directeur";
  survey.getQuestionByName(questionName).visible = true;
}
      survey.getQuestionByName("doc_autres").visible = true;

      survey.getQuestionByName("adresse_epf_RNE").visible = true;
      survey.getQuestionByName("activite_principale_RNE").visible = true;
      survey.getQuestionByName("activite_secondaire_RNE").visible = true;
      survey.setValue("validation_provisoire_check", true);
      survey.getQuestionByName("data_spinner").visible = false;
    } catch (error) {
      survey.getQuestionByName("data_spinner").visible = false;
      notification.error({
        message: t("Erreur"),
        description: `Erreur lors de la récupération des données.`,
      });
      throw error;
    }
  };

  const getEtablissementSecondaireDgi = async (survey, data) => {
    const value = survey.getValue("numero_matricule_fiscal_numero_IURNE");
    const etat_secondaire = survey.getValue("numero_etablissement_secondaire");
    survey.getQuestionByName("data_spinner").visible = true;
    try {
      const response = await axios.get(
        `${
          import.meta.env.VITE_APP_GEOPROD_API
        }/config/DGI_EtabSecondaires?matfis=${value}`
      );
      // survey.getQuestionByName("data_spinner").visible = false;
      const etablissement = response?.data?.message?.ListEtab?.find(
        (activity) => activity?.etabNum === etat_secondaire
      );

      if(response?.data?.message?.Code === 0 )
      {

      survey.setValue("adresse_epf_DGI", etablissement?.etabAdr);
      survey.setValue("activite_principale_DGI", etablissement?.etabLibacpr);
      survey.setValue("activite_secondaire_DGI", etablissement?.etabLibacse);

      survey.getQuestionByName("numero_matricule_CNSS").visible = true;
      survey.getQuestionByName("denomination_officielle_CNSS").visible = true;
      survey.getQuestionByName(
        "denomination_officielle_RNE_arabe"
      ).visible = true;
      survey.getQuestionByName(
        "denomination_officielle_RNE_latin"
      ).visible = true;
      survey.getQuestionByName(
        "denomination_commerciale_RNE_arabe"
      ).visible = true;
      survey.getQuestionByName(
        "denomination_commerciale_RNE_latin"
      ).visible = true;
      survey.getQuestionByName("adresse_EPF").visible = true;
      survey.getQuestionByName("adresse_epf_DGI").visible = true;
      survey.getQuestionByName("activite_principale_DGI").visible = true;
      survey.getQuestionByName("activite_secondaire_DGI").visible = true;

      survey.getQuestionByName(
        "date_expiration_protection_civile"
      ).visible = true;
      survey.getQuestionByName("doc_rne").visible = true;
      survey.getQuestionByName("doc_patente").visible = true;
      survey.getQuestionByName("doc_affiliation_CNSS").visible = true;
      survey.getQuestionByName(
        "doc_attestation_protection_civile"
      ).visible = true;
      if (data?.investor_nationality === "تونسية") {
        survey.getQuestionByName("doc_b3_investisseur").visible = true;
      }

      if (data?.establishment_director_data_identity === "مدير غير المستثمر") {
        const questionName = contrat_id === import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE 
          ? "doc_b3_responsable" 
          : "doc_b3_directeur";
        survey.getQuestionByName(questionName).visible = true;
      }   
      survey.getQuestionByName("doc_autres").visible = true;
      survey.setValue("validation_provisoire_check", true);
      survey.getQuestionByName("data_spinner").visible = false;
      }
      else
      {
        survey.getQuestionByName("data_spinner").visible = false;
        notification.error({
          message: t('Erreur'),
          description: t(`${response?.data?.message?.message}`),
          duration: 0,
        });
      }
    } catch (error) {
      survey.getQuestionByName("data_spinner").visible = false;
      notification.error({
        message: t("Erreur"),
        description: `Erreur lors de la récupération des données.`,
      });
      throw error;
    }
  };

  const searchClick = async (survey) => {
    survey.setValue("validation_provisoire_check", false);
    const data = await getSurveyData();
    const matricule = survey.getValue("numero_matricule_fiscal_numero_IURNE");
    const etat_secondaire = survey.getValue("numero_etablissement_secondaire");
    const regex_matricule = /^[0-9]{7}[A-Z]$/;
    const regex_secondaire = /^\d{3}$/;

    if (!matricule || !etat_secondaire) {
      notification.error({
        message: "Erreur",
        description:
          "Veuillez remplir les champs Matricule fiscal / Numéro IURN et Numéro État secondaire.",
      });
    } else if (!regex_matricule.test(matricule)) {
      notification.error({
        message: t("Erreur"),
        description: t(
          "Le numéro de matricule fiscal ou le numéro IURNE doit respecter le format suivant : 7 chiffres suivis d'une lettre majuscule (exemple : 0000000A)."
        ),
      });
    } else if (!regex_secondaire.test(etat_secondaire)) {
      notification.error({
        message: t("Erreur"),
        description: t(
          "Le numéro d'État secondaire doit être composé uniquement de 3 chiffres (exemple : 111)."
        ),
      });
    } else {
      if (survey.data.numero_etablissement_secondaire === "000") {
        survey.getQuestionByName("data_spinner").visible = true;
       const registreRne =  await getExtraitRegistreEntrepriseParId(survey, data);
       if(registreRne)
       {
        await getEmployeurByPatNumPatCL(survey);
        await getFicheEntrepriseDGI(survey);
        await getListActiviteRNE(survey, data);
       }

      } else {
        survey.getQuestionByName("data_spinner").visible = true;
        const registreRne = await getExtraitRegistreEntrepriseParId(survey, data);
        if(registreRne){
          await getEmployeurByPatNumPatCL(survey);
          await getEtablissementSecondaireDgi(survey, data);
        }
      }
    }
  };

  const buttonExpressions = {
    data_validation_provisoire_search: {
      apiCall: (survey_) => searchClick(survey_),
    },
  };

  CustomWidgetCollection.Instance.addCustomWidget({
    name: "Button",
    title: "Button",
    isFit: function (question) {
      return (
        question.getType() === "text" &&
        question?.jsonObj?.inputType?.toLowerCase() === "button"
      );
    },
    render: function (question, element) {
      const expression = buttonExpressions[question.name];
      const handleClick = () => {
        const survey_ = question.survey;
        if (expression && expression.apiCall) {
          return expression.apiCall(survey_);
        }
      };

      return <ButtonComponent question={question} handleClick={handleClick} />;
    },
  });

  CustomWidgetCollection.Instance.addCustomWidget({
    name: "refreshbar",
    title: "Refresh Bar",
    isFit: function (question) {
      return (
        question.getType() === "text" &&
        question.jsonObj.inputType === "refreshbar"
      );
    },
    render: function (question, element) {
      return <RefreshBarComponent question={question} />;
    },
  });
  const onComplete = (survey) => {
    survey.onCompleting.add(function (sender, options) {
      if (!survey.getValue("validation_provisoire_check")) {
        options.allowComplete = false;
        notification.error({
          message: t("Erreur"),
          description: t(
            "Veuillez valider les données en utilisant le bouton de recherche."
          ),
        });
      } else {
        survey.getQuestionByName("data_spinner").visible = true;
        updateActionAffaire({
          id_affaire: params?.state?.id_affaire,
          id_status: import.meta.env
            .VITE_APP_ID_STATUS_COMPLEMENT_VALIDATION_PROVISOIRE,
        });
        const payload = {
          additional_form: "{}",
          json_prod: { ...survey.data },
        };
        if (payload) {
          updateAffaire(params?.state?.id_affaire, payload, "");
        }

        survey.getQuestionByName("data_spinner").visible = false;
        navigate("/demandes");
      }
    });
  };

  const onErrorCustomText = (survey) => {
    survey.onErrorCustomText.add(function (sender, options) {
      if (options.name == "required") {
        options.text = t("Veuillez remplir ce champ.");
      }
    });
  };
  onErrorCustomText(survey);
  onValueChanging(survey);
  onComplete(survey);
  useEffect(() => {
    async function fetchData() {
      const data = await getSurveyData();
      survey.setValue("validation_provisoire_check", false);
      const regex_matricule = /^[0-9]{7}[A-Z]$/;
      if (data?.investor_RNE === "رقم المعرف الجبائي أو ما يعرف بالباتيندا متاح (شركة قائمة)"  && 
        (data?.legal_entity_tax_number && (regex_matricule.test(data?.legal_entity_tax_number))  ||
        data?.establishment_data_tax_number && (regex_matricule.test(data?.establishment_data_tax_number)))
      ) {
        survey.setValue(
          "numero_matricule_fiscal_numero_IURNE",
          data?.legal_entity_tax_number
            ? data?.legal_entity_tax_number
            : data?.establishment_data_tax_number
        );
        survey.getQuestionByName(
          "numero_matricule_fiscal_numero_IURNE"
        ).readOnly = true;
      }
      if (
        sessionStorage.getItem("consulter") === "true" &&
        (params?.state?.etat_demande ===
          parseInt(import.meta.env.VITE_APP_ETAT_EN_COURS_DE_VALIDATION) ||
          params?.state?.etat_demande ===
            parseInt(import.meta.env.VITE_APP_ETAT_VALIDATION_FINALE))
      ) {
        const numeroEtatSecondaire = survey.getValue("numero_etablissement_secondaire");

        if (numeroEtatSecondaire === "000") {
          survey.getQuestionByName("numero_matricule_CNSS").visible = true;
          survey.getQuestionByName(
            "denomination_officielle_CNSS"
          ).visible = true;
          survey.getQuestionByName(
            "denomination_officielle_RNE_arabe"
          ).visible = true;
          survey.getQuestionByName(
            "denomination_officielle_RNE_latin"
          ).visible = true;
          survey.getQuestionByName(
            "denomination_commerciale_RNE_arabe"
          ).visible = true;
          survey.getQuestionByName(
            "denomination_commerciale_RNE_latin"
          ).visible = true;
          survey.getQuestionByName("adresse_EPF").visible = true;
          survey.getQuestionByName("adresse_epf_DGI").visible = true;
          survey.getQuestionByName("activite_principale_DGI").visible = true;
          survey.getQuestionByName("activite_secondaire_DGI").visible = true;

          survey.getQuestionByName(
            "date_expiration_protection_civile"
          ).visible = true;
          survey.getQuestionByName("doc_rne").visible = true;
          survey.getQuestionByName("doc_patente").visible = true;
          survey.getQuestionByName("doc_affiliation_CNSS").visible = true;
          survey.getQuestionByName(
            "doc_attestation_protection_civile"
          ).visible = true;
          if (data?.investor_nationality === "تونسية") {
            survey.getQuestionByName("doc_b3_investisseur").visible = true;
          }

          if (data?.establishment_director_data_identity === "مدير غير المستثمر") {
            const questionName = contrat_id === import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE 
              ? "doc_b3_responsable" 
              : "doc_b3_directeur";
            survey.getQuestionByName(questionName).visible = true;
          }
          survey.getQuestionByName("doc_autres").visible = true;

          survey.getQuestionByName("adresse_epf_RNE").visible = true;
          survey.getQuestionByName("activite_principale_RNE").visible = true;
          survey.getQuestionByName("activite_secondaire_RNE").visible = true;
        } else {
          survey.getAllQuestions().map((question) => {
            question.readOnly = true;
          });

          survey.getQuestionByName("numero_matricule_CNSS").visible = true;
          survey.getQuestionByName(
            "denomination_officielle_CNSS"
          ).visible = true;
          survey.getQuestionByName(
            "denomination_officielle_RNE_arabe"
          ).visible = true;
          survey.getQuestionByName(
            "denomination_officielle_RNE_latin"
          ).visible = true;
          survey.getQuestionByName(
            "denomination_commerciale_RNE_arabe"
          ).visible = true;
          survey.getQuestionByName(
            "denomination_commerciale_RNE_latin"
          ).visible = true;
          survey.getQuestionByName("adresse_EPF").visible = true;
          survey.getQuestionByName("adresse_epf_DGI").visible = true;
          survey.getQuestionByName("activite_principale_DGI").visible = true;
          survey.getQuestionByName("activite_secondaire_DGI").visible = true;
          survey.getQuestionByName(
            "date_expiration_protection_civile"
          ).visible = true;
          survey.getQuestionByName("doc_rne").visible = true;
          survey.getQuestionByName("doc_patente").visible = true;
          survey.getQuestionByName("doc_affiliation_CNSS").visible = true;
          survey.getQuestionByName(
            "doc_attestation_protection_civile"
          ).visible = true;
          if (data?.investor_nationality === "تونسية") {
            survey.getQuestionByName("doc_b3_investisseur").visible = true;
          }

          if (data?.establishment_director_data_identity === "مدير غير المستثمر") {
            const questionName = contrat_id === import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE 
              ? "doc_b3_responsable" 
              : "doc_b3_directeur";
            survey.getQuestionByName(questionName).visible = true;
          }
          survey.getQuestionByName("doc_autres").visible = true;
        }

        survey.showCompleteButton = false;
        survey.showPreviewBeforeComplete = false;
      }

      const currentData = {
        ...survey.data,
        ...data,
      };

      data?.response?.key_urls?.forEach((item) => {
        const fileType = mime.getType(item.name_doc);
        currentData[item.key] = [
          {
            content: item.url,
            name: item.name_doc,
            type: fileType,
          },
        ];
      });
      survey.mergeData(currentData);
      survey.data = currentData;
      survey.render();
    }

    fetchData();
  }, [survey.data]);

  return (
    <div>
      <h1 style={{ fontSize: "24px" }}>
        Étape de l'obtention de la validation finale - مرحلة الحصول على المصادقة
        النهائية
      </h1>

      <Survey model={survey} />
    </div>
  );
};

export default ValidationProvisoire;
