import { Button, Col, Form, Row, Upload, notification, Spin } from "antd";
import { CloudUploadOutlined, UploadOutlined } from "@ant-design/icons";
import classes from "./ComplementDossier.module.css";
import {
  updateActionAffaire,
  uploadDocumentAffaire,
} from "../../../api/geoprod";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import GlobalContext from "../../../context/GlobalContext";
import { useTranslation } from "react-i18next";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";

const ComplementDossier = () => {
  const [loader, setLoader] = useState(false);
  const { form } = useContext(GlobalContext);
  const params = useLocation();
  const data = params?.state?.data_complement_dossier || {};
  const complement_dossier_uploaded =
    params?.state?.complement_dossier_uploaded || {};
  const navigate = useNavigate();
  const { t } = useTranslation();
  const initialFileList = Object?.keys(data)?.reduce((acc, key) => {
    acc[key] = [];
    return acc;
  }, {});

  const [fileList, setFileList] = useState(initialFileList);
  useEffect(() => {
    if (complement_dossier_uploaded) {
      const newFileList = { ...fileList };
      const currentFormValues = form?.getFieldsValue();
      complement_dossier_uploaded?.forEach((file) => {
        currentFormValues[file.key] = file?.url;
        newFileList[file?.key] = [
          {
            uid: file?.id,
            name: file?.name_doc,
            status: "done",
            url: file?.url,
          },
        ];
      });
      form.setFieldsValue(currentFormValues);
      setFileList(newFileList);
    }
  }, []);

  const handleChange = async (info, type) => {
    switch (info?.file?.status) {
      case "uploading":
        setFileList({ ...fileList, [type]: [info?.file] });
        break;
      case "done":
        {
          setFileList({ ...fileList, [type]: [info?.file] });
          setLoader(true);
          const id_type_doc = import.meta.env
            .VITE_APP_ID_TYPE_DOC_COMPLEMENT_DOSSIER;
          const formData = new FormData();
          formData.append("id_affaire", params?.state?.id_affaire);
          formData.append("id_type_doc", id_type_doc);
          formData.append("name_doc", "Complement de dossier");
          formData.append("file[]", info?.file?.originFileObj);
          formData.append("key", type);
          try {
            await uploadDocumentAffaire(formData).then(async () => {
              setLoader(false);
            });
          } catch (error) {
            setLoader(false);
            notification.error({
              message: t("Erreur de téléchargement des fichiers."),
              duration: 0,
            });
          }
        }

        break;
      default:
        setFileList({ ...fileList, [type]: [] });
        form.setFieldsValue({
          ...form.getFieldsValue(),
          [type]: undefined,
        });
        break;
    }
  };

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const change_status = () => {
    setLoader(true);
    updateActionAffaire({
      id_affaire: params?.state?.id_affaire,
      id_status:
        params?.state?.affaire_id_contrat ===
          import.meta.env
            .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO ||
        params?.state?.affaire_id_contrat ===
          import.meta.env
            .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_SANS_BO
          ? import.meta.env.VITE_APP_ID_STATUS_COMPLEMENT_SOUMIS_MODIFICATION
          : import.meta.env.VITE_APP_ID_STATUS_COMPLEMENT_DOSSIER_SOUMISE,
    })
      .then(async () => {
        axios
          .post(
            `${import.meta.env.VITE_APP_GEOPROD_API}/commentaire/add`,
            {
              entity: "A",
              id_entity: params?.state?.id_affaire,
              commentaire: form.getFieldsValue()["commentaire"],
            },
            {
              headers: {
                Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
                  ?.Acces_Token,
              },
            }
          )
          .then(async (response) => {
            setLoader(false);
            notification.success({
              message: t("Tous les fichiers ont été téléchargés avec succès."),
              duration: 0,
            });
            setTimeout(() => {
              navigate("/demandes");
            }, 1000);
          })
          .catch(() => {});
      })
      .catch(() => {
        setLoader(false);
      });
  };

  return (
    <Spin spinning={loader} tip="" style={{ width: "100%" }}>
      <div style={{ opacity: loader ? 0.7 : 1 }}>
        <Form form={form} layout="vertical" onFinish={change_status}>
          <Row justify="center">
            <Col lg={12} md={24} className={classes.Col}>
              <div className={classes.title}>
                {" "}
                {t("Les documents requis pour le complément de dossier")}{" "}
              </div>
              {Object.keys(data).map((type, index) => (
                <Form.Item
                  key={index}
                  className={classes.inputUpload}
                  name={type}
                  label={<label className={classes.label}>{type}</label>}
                  rules={[
                    {
                      required: true,
                      message: t("Veuillez télécharger un fichier."),
                    },
                  ]}
                >
                  <Upload
                    onChange={(e) => handleChange(e, type)}
                    fileList={fileList[type]}
                    customRequest={dummyRequest}
                    maxCount={1}
                    accept="jpg,.jpeg,.png,.pdf"
                  >
                    <Button size="large" className={classes.buttonUpload}>
                      <UploadOutlined className={classes.icon} />
                    </Button>
                  </Upload>
                </Form.Item>
              ))}
              <Form.Item
                className={classes.inputUpload}
                name="commentaire"
                label={<label className={classes.label}>Commentaire</label>}
                rules={[{ required: false }]}
              >
                <TextArea style={{ width: "26rem" }} />
              </Form.Item>
              <Row justify="center">
                <Col>
                  <Button
                    className={classes.buttonCreate}
                    loading={loader}
                    htmlType="submit"
                  >
                    <CloudUploadOutlined />
                    {t("Envoyer les documents")}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    </Spin>
  );
};

export default ComplementDossier;
