import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Radio,
  Row,
} from "antd";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GlobalContext from "../../../context/GlobalContext";
import { useLoader } from "../../../context/LoaderContext";
import classes from "./SelectionModal.module.css";
import axios from "axios";
import { getSchema } from "../../../api/geoprod";

const SelectionModal = (props) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [demandeType, setDemandeType] = useState();
  const [view, setView] = useState("buttons");
  const { isModalVisible, setIsModalVisible } = props;
  const { setIsLoading, setSurveyJson, setSurveyData } = useLoader();
  const { t } = useTranslation();
  const {
    form,
    setPersonneMorale,
    setMatriculeFiscale,
    numeroEnregistrement,
    setNumeroEnregistrement,
  } = useContext(GlobalContext);
  const navigate = useNavigate();

  const handleCancel = () => {
    setIsModalVisible(false);
    setView("buttons");
    form.resetFields();
  };

  const handleButtonClick = (buttonType) => {
    if (buttonType === "createEpf") {
      setDemandeType("createEpf");
    } else if (buttonType === "changeInvestor") {
      setDemandeType("changeInvestor");
      // navigate("/investisseur/changer");
    }
    setView("form");
  };

  const handleOk = () => {
    form.validateFields().then(() => {
      const userData = JSON.parse(sessionStorage.getItem("-x-token-user"));
      const matriculeFiscale =
        form.getFieldValue("identifiantRNE") +
        form.getFieldValue("codeEtablissementSecondaire");
      setMatriculeFiscale(matriculeFiscale);
      setPersonneMorale(form.getFieldValue("EPFType") === "personneMorale");
      setNumeroEnregistrement(form.getFieldValue("numeroEnregistrement"));
      if (JSON.parse(sessionStorage.getItem("-x-token-user"))?.Acces_Token) {
        setIsLoading(true);
        axios
          .get(
            `${import.meta.env.VITE_APP_GEOPROD_API}/admin-bpm/contrat/${
              demandeType === "changeInvestor"
                ? import.meta.env
                    .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO
                : import.meta.env.VITE_ID_LIGNE_PRODUIT_EPF_EXISTANTE
            }`,
            {
              headers: {
                Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
                  ?.Acces_Token,
              },
            }
          )
          .then((res) => {
            let surveuForm = res?.data?.contrat?.form;
            surveuForm.pages =
              demandeType === "changeInvestor"
                ? Array(
                    res?.data?.contrat?.form?.pages?.find((page) => {
                      return page?.name === "ChnangementInvestisseur";
                    })
                  )
                : res?.data?.contrat?.form.pages;

            // const temp = {
            //   ...res?.data?.contrat?.form,
            //   pages: surveuForm,
            //   showProgressBar: "none",
            // }
            setSurveyJson(surveuForm);
            if (demandeType === "changeInvestor") {
              axios
                .get(
                  `${
                    import.meta.env.VITE_APP_GEOPROD_API
                  }/search_by_registration_number?registration_number=${numeroEnregistrement}`
                )
                .then((res) => {
                  sessionStorage.setItem(
                    "idAffaireEpfExistant",
                    res?.data?.data?.id_affaire
                  );
                  if (res?.data?.existe === true) {
                    axios
                      .get(
                        `${
                          import.meta.env.VITE_APP_GEOPROD_API
                        }/config/proxy/registreEntrepriseParId/${matriculeFiscale.substring(
                          0,
                          8
                        )}`
                      )
                      .then(async (response) => {
                        const dirigeants = response.data.direction;
                        if (response?.data?.code === "404") {
                          setIsLoading(false);

                          notification.error({
                            message: t("Erreur"),
                            description: t(
                              "Aucune entreprise n'a été trouvée avec ce matricule fiscal."
                            ),
                            duration: 0,
                          });
                        } else if (
                          // TODO: check if the user is authorized to create an EPF for this entreprise
                          dirigeants.find((dirigeant) => {
                            dirigeant?.cin === userData?.cin;
                          })
                        ) {
                          setIsLoading(false);

                          notification.error({
                            message: t("Erreur"),
                            description: t(
                              "Vous n'êtes pas autorisé à créer un établissement pour cette entreprise."
                            ),
                            duration: 0,
                          });
                        } else {
                          // const data = await getSchema(
                          //   res?.data?.data?.id_affaire
                          // );
                          // if (data) {
                            // setSurveyData(data?.response?.data);
                            form.resetFields();
                            sessionStorage.setItem(
                              "selectedOption",
                              "avec_bo_validation"
                            );
                            setIsLoading(false);

                            navigate("/investisseur/changer", {
                              state: {
                                idAffaireChangerInv:
                                  res?.data?.data?.id_affaire,
                                selectedOption: "avec_bo_validation",
                              },
                            });
                          // }
                        }
                      });
                  } else {
                    setIsLoading(false);
                    notification.error({
                      message: t("Erreur"),
                      description: t(
                        "Aucune entreprise n'a été trouvée avec ce numéro enregistrement."
                      ),
                      duration: 0,
                    });
                  }
                })
                .catch(() => {
                  setIsLoading(false);
                });
            } else {
              axios
                .get(
                  `${
                    import.meta.env.VITE_APP_GEOPROD_API
                  }/search_documents?number=${form.getFieldValue(
                    "numeroEnregistrement"
                  )}`
                )
                .then((res) => {
                  if (!res?.data?.exists) {
                    setIsLoading(false);
                    notification?.error({
                      message: t("Erreur"),
                      description: t(
                        "Aucune entreprise n'a été trouvée avec ce numéro enregistrement."
                      ),
                      duration: 0,
                    });
                  } else {
                    axios
                      .get(
                        `${
                          import.meta.env.VITE_APP_GEOPROD_API
                        }/config/proxy/registreEntrepriseParId/${matriculeFiscale.substring(
                          0,
                          8
                        )}`
                      )
                      .then((response) => {
                        const dirigeants = response.data.direction;
                        if (response?.data?.code === "404") {
                          setIsLoading(false);

                          notification.error({
                            message: t("Erreur"),
                            description: t(
                              "Aucune entreprise n'a été trouvée avec ce matricule fiscal."
                            ),
                            duration: 0,
                          });
                        } else if (
                          dirigeants.find((dirigeant) => {
                            dirigeant?.cin == userData?.cin;
                          })
                        ) {
                          setIsLoading(false);

                          notification.error({
                            message: t("Erreur"),
                            description: t(
                              "Vous n'êtes pas autorisé à créer un établissement pour cette entreprise."
                            ),
                            duration: 0,
                          });
                        } else if (
                          response?.data?.nomCommercial &&
                          form.getFieldValue("EPFType") === "personnePhysique"
                        ) {
                          setIsLoading(false);

                          notification.error({
                            message: t("Erreur"),
                            description: t(
                              "vous ne disposez pas d’un nom commercial. Pour continuer l’inscription vous êtes invité à prendre attache au RNE pour avoir un nom commercial."
                            ),
                            duration: 0,
                          });
                        } else {
                    setIsLoading(false)

                          setIsModalVisible(false);
                          form.resetFields();
                          navigate("/etablissements/existante/save");
                        }
                      })
                      .catch((error) => {
                        setIsModalVisible(false);
                        notification.error({
                          message: t("Erreur"),
                          description:
                            error?.response?.data?.message ||
                            "Une erreur s'est produite lors de la récupération des données.",
                        });
                      });
                  }
                })
                .catch(() => {
                  setIsLoading(false);
                });
            }
          })
          .catch(() => {
            setIsLoading(false);
            notification.error({
              message: t("Erreur"),
              description:
                "Une erreur a été rencontré lors de chargement de formulaire de l'epf existant",
            });
          });
      } else {
        setIsLoading(false);
        notification.error({
          message: t("Erreur"),
          description: "Veuillez connecter s'il vous plait.",
        });
      }
    });
  };

  const direction = localStorage.getItem("language") === "ar" ? "ltr" : "ltr";
  // const buttonWidth = direction === "rtl" ? "15rem" : "10rem";
  return (
    <Modal
      title={
        <div style={{ marginBottom: "25px", textAlign: "center" }}>
          {view === "buttons" ? (
            t("Choisissez une option")
          ) : (
            <>
              {t(
                "Veuillez renseigner les informations concernant votre établissement de formation privée"
              )}
              <br />
              يرجى تعبئة معلومات مؤسسة التكوين المهني الخاصة
            </>
          )}
        </div>
      }
      open={isModalVisible}
      footer={false}
      onCancel={handleCancel}
      style={{ direction: direction, minWidth: "100vh" }}
    >
      {view === "buttons" && (
        <div style={{ display: "flex", justifyContent: "center", gap: "1rem" }}>
          <Button
            type="primary"
            style={{
              backgroundColor: "#214082",
              borderColor: "#214082",
              width: "15rem",
            }}
            onClick={() => handleButtonClick("createEpf")}
          >
            {t("Ajouter un EPF existant")}
          </Button>
          <Button
            style={{
              backgroundColor: "#E2E9F7",
              color: "#214082",
              border: "#9BACCF 1px solid",
              width: "15rem",
            }}
            onClick={() => handleButtonClick("changeInvestor")}
          >
            {t("Changer l'investisseur d'un EPF")}
          </Button>
        </div>
      )}

      {view === "form" && (
        <Form form={form} layout="vertical" style={{ direction: direction }}>
          {demandeType === "createEpf" && (
            <Form.Item
              name="EPFType"
              rules={[
                { required: true, message: t("Veuillez remplir ce champ.") },
              ]}
              label={
                <span className={classes.label}>
                  Type de l'établissement - الصبغة القانونية
                </span>
              }
              required
            >
              <Radio.Group
                onChange={(e) => setSelectedOption(e.target.value)}
                value={selectedOption}
              >
                <Radio value="personnePhysique" style={{ marginRight: "20px" }}>
                  Personne physique - شخص طبيعي
                </Radio>
                <Radio value="personneMorale">
                  Personne morale - شخص معنوي
                </Radio>
              </Radio.Group>
            </Form.Item>
          )}
          <Form.Item
            label={
              <span className={classes.label}>
                Numéro d'enregistrement - رقم التسجيل
              </span>
            }
            name="numeroEnregistrement"
            rules={[
              { required: true, message: t("Veuillez remplir ce champ.") },
              {
                pattern: /^\d{10}$/,
                message: t("Le champ doit contenir 10 chiffres."),
              },
            ]}
          >
            <Input />
          </Form.Item>
          {/* <Form.Item
            label={
              <span className={classes.label}>{t("Matricule fiscal")}</span>
            }
            required
          > */}
          {/* <Row gutter={8}>
              <Col span={15}> */}
          <Form.Item
            label={
              <span className={classes.label}>
                Matricule fiscal / Numéro IURNE - رقم المعرّف الجبائي
              </span>
            }
            name="identifiantRNE"
            rules={[
              {
                required: true,
                message: t("Veuillez remplir ce champ."),
              },
              {
                pattern: /^\d{7}[A-Z]$/,
                message: t(
                  "Le champ doit contenir 7 chiffres et une lettre à la fin."
                ),
              },
            ]}
          >
            <Input maxLength={8} />
          </Form.Item>
          {/* </Col>
              <Col span={9}> */}
          <Form.Item
            label={
              <span className={classes.label}>
                Numéro établissement secondaire - رقم الفرع الثانوي
              </span>
            }
            name="codeEtablissementSecondaire"
            rules={[
              {
                required: true,
                message: t("Veuillez remplir ce champ."),
              },
              {
                pattern: /^\d{3}$/,
                message: t("Le code doit contenir 3 chiffres."),
              },
            ]}
          >
            <Input maxLength={3} />
          </Form.Item>
          {/* </Col>
            </Row> */}
          {/* </Form.Item> */}
          <div style={{ textAlign: "center" }}>
            <Button
              onClick={handleOk}
              type="primary"
              style={{
                backgroundColor: "#214082",
                borderColor: "#214082",
                marginRight: "10px",
                width: "7rem",
              }}
            >
              {t("Valider")}
            </Button>
            <Button
              style={{
                backgroundColor: "#E2E9F7",
                color: "#214082",
                border: "#9BACCF 1px solid",
                marginInline: "0.5rem",
                width: "7rem",
              }}
              onClick={handleCancel}
            >
              {t("Annuler")}
            </Button>
          </div>
        </Form>
      )}
    </Modal>
  );
};

export default SelectionModal;
