import React, { memo } from "react";
import { Spin } from "antd";
const Loader = () => {
  return (
    <Spin
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
        borderRadius:4
      }}
    />
  );
};

export default memo(Loader);
