import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CloseModal from "./CloseModal/CloseModal";
import SelectionModal from "./SelectionModal/SelectionModal";
import { CommentOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import classes from "../Etablissements/Etablissements.module.css";
import { Row, Card, Table, Tooltip, Tag, Button, Space } from "antd";
import { getAffaires } from "../../api/geoprod";
import CommentModal from "../Etablissements/CreateEpf/Modals/CommentModal";
import { useLoader } from "../../context/LoaderContext";
import axios from "axios";

const Etablissements = () => {
  const [stopModal, setStopModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [listDataValidee, setListDataValidee] = useState([]);
  const [commentaire, setCommentaire] = useState([]);
  const { setIsLoading } = useLoader();
  const { t } = useTranslation();
  const idAffaire = new URLSearchParams(window.location.search).get("id");

  const columns = [
    {
      title: t("Dénomination"),
      key: "dénomination",
      render: (_, record) =>
        record?.establishment_data_commercial_name ||
        record?.establishment_data_official_name ||
        record?.legal_entity_official_name,
    },
    {
      title: t("Directeur"),
      dataIndex: "directeur",
      key: "directeur",
      render: (_, record) =>
        record?.establishment_director_data_last_name +
        " " +
        record?.establishment_director_data_first_name,
    },
    {
      title: t("Gouvernorat"),
      key: "gouvernorat",
      render: (_, record) => record?.establishment_data_governorate,
    },
    {
      title: t("Délégation"),
      dataIndex: "délégation",
      key: "délégation",
      render: (_, record) => record?.establishment_data_delegation,
    },
    {
      title: t("Matricule fiscal"),
      key: "matricule",
      render: (_, record) =>
        record?.establishment_data_tax_number ||
        record?.legal_entity_tax_number ||
        "non renseigné",
    },
    {
      title: t("Numéro de téléphone"),
      key: "phoneNum",
      render: (_, record) => record?.investor_phone_number,
    },
    {
      title: t("Nature de formation"),
      key: "natureForm",
      render: (_, record) => record?.establishment_data_training_type,
    },
    {
      title: t("Statut"),
      key: "statut",
      render: (_, record) => {
        return <Tag color={record?.etat_background}>{record?.etat}</Tag>;
      },
    },
    {
      title: t("Actions"),
      key: "Actions",
      render: (_, record) => (
        <Space>
          <Tooltip title={t("Modifier")}>
            <EditOutlined />
          </Tooltip>
          <CommentOutlined
            onClick={() => handleGetComment(record?.affaire_id)}
          />
        </Space>
      ),
    },
  ];

  const [isModalVisible, setIsModalVisible] = useState();

  const openModalSelection = () => {
    setIsModalVisible(listDataValidee);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getAffaires({
          id_user: JSON.parse(sessionStorage.getItem("-x-token-user")).id_user,
          id_entreprise: JSON.parse(sessionStorage.getItem("-x-token-user"))
            .user.entreprise_id,
          filters: {
            contrat: [
              import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE,
              import.meta.env.VITE_ID_LIGNE_PRODUIT,
              import.meta.env.VITE_ID_LIGNE_PRODUIT_EPF_EXISTANTE,
              import.meta.env.VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO,
              import.meta.env.VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_SANS_BO,
              import.meta.env.VITE_APP_LIGNE_PRODUIT_MODIFIACTION_SANS_VALIDATION_SANS_BO,
            ],
            status: [
              import.meta.env.VITE_APP_ID_STATUS_VALIDER_EPF,
              import.meta.env.VITE_APP_ID_STATUS_VALIDER_EPF_EXIST,
              import.meta.env.VITE_APP_ID_STATUS_SOUMISE_SANS_VALIDATION,
              import.meta.env.VITE_APP_STATUS_VALIDEE_MODIFICATION_AVEC_VALIDATION,
            ],
          },
        });

        setData(
          response?.aaData?.filter(
            (elem) =>
              elem?.etat === "Demande validée" &&
              elem.affaire_id_contrat ===
                import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE
          )
        );

        setListDataValidee(
          response?.aaData.filter(
            (elem) =>
              elem?.affaire_id_contrat !==
                import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE
          )
        );
        setLoading(false);
      } catch (error) {}
    }

    fetchData();
    if (idAffaire) {
      handleGetComment(idAffaire);
    }
  }, []);

  const handleGetComment = (id_affaire) => {
    setIsLoading(true);
    axios
      .get(
        `${
          import.meta.env.VITE_APP_GEOPROD_API
        }/commentaire/get_investisseur_commentaires?entity=A&id_entity=${id_affaire}`,
        {
          headers: {
            Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
              ?.Acces_Token,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.info?.length > 0) {
          setCommentaire(res?.data?.info);
        } else {
          notification.warning({
            message: "Vous ne disposez pas de commentaire pour cette demande !",
          });
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <div>
      <CloseModal stopModal={stopModal} setStopModal={setStopModal} />

      <SelectionModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
      <CommentModal commentaire={commentaire} setCommentaire={setCommentaire} />

      <Row className={classes.rowButtonCreate}>
        <Tooltip
          title={
            listDataValidee?.length === 0
              ? t(
                  "Vous devez disposer d'au moins un EPF pour pouvoir créer une annexe"
                )
              : ""
          }
        >
          <Button
            disabled={listDataValidee?.length === 0}
            className={classes.buttonCreate}
            onClick={openModalSelection}
          >
            <PlusOutlined />
            {t("Créer une nouvelle Annexe")}
          </Button>
        </Tooltip>
      </Row>

      <Row className={classes.listeRow}>
        <Card
          className={classes.listeCard}
          bordered={false}
          title={t("Liste de mes Annexes")}
        >
          <Table
            loading={loading}
            locale={{ emptyText: t("En cours de developpement.") }}
            columns={columns}
            dataSource={data}
            className="ant-border-space"
            style={{ overflowX: "auto" }}
            pagination={false}
          />
        </Card>
      </Row>
    </div>
  );
};

export default Etablissements;
