import {
  Row,
  Card,
  Table,
  Tooltip,
  Tag,
  Button,
  Space,
  notification,
} from "antd";
import {
  CloudUploadOutlined,
  EditOutlined,
  InfoCircleOutlined,
  EyeOutlined,
  PlusOutlined,
  CommentOutlined,
} from "@ant-design/icons";
import classes from "./Etablissements.module.css";
import { useState, useEffect } from "react";
import SelectionModal from "./SelectionModal/SelectionModal";
import { useLocation, useNavigate } from "react-router-dom";
import CloseModal from "./CloseModal/CloseModal";
import { useTranslation } from "react-i18next";
import { getAffaires, getDocByIdAffaire, getSchema } from "../../api/geoprod";
import ModalModification from "./CreateEpf/Modals/ModalModification";
import { useLoader } from "../../context/LoaderContext";
import axios from "axios";
import { downloadFile } from "../../utils";
import CommentModal from "./CreateEpf/Modals/CommentModal";

const Etablissements = (props) => {
  const navigate = useNavigate();
  const [stopModal, setStopModal] = useState(false);
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState("");
  const [investisseurDirecteur, setInvestisseurDIrecteur] = useState();
  const [commentaire, setCommentaire] = useState([]);
  // const [surveyData, setSurveyData] = useState({});
  const { setIsLoading, setSurveyJson, setSurveyData } = useLoader();
  const role = sessionStorage.getItem("role");
  // const path = window.location.pathname;
  const idAffaire = new URLSearchParams(window.location.search).get("id");

  const demandeModifictaion = async (id_affaire) => {
    setIsLoading(true);
    sessionStorage.setItem("demande",false)
    const data = await getSchema(id_affaire);
    if (data?.response?.data) {
      setIsLoading(false);

      await setSurveyData(data);

      if (sessionStorage.getItem("role") === "directeur") {
        sessionStorage.setItem(
          "user_id_to_get",

          data?.response?.id_user
        );
      } else {
        sessionStorage.setItem(
          "user_id_to_get",
          data?.response?.responsable_EPF
        );
      }

      const establishment_director_data_identity =
        data?.response?.data?.establishment_director_data_identity;

      const anyPanelIsHomologed =
        data?.response?.data?.training_nature_data?.some(
          (panel) => panel.training_type_data_type === "منظرة"
        );
      sessionStorage.setItem("Modifier_sans_validation", !anyPanelIsHomologed);

      if (
        role !== "directeur" &&
        establishment_director_data_identity === "مدير غير المستثمر"
      ) {
        setInvestisseurDIrecteur("investisseur");
      } else if (
        role !== "directeur" &&
        establishment_director_data_identity === "المستثمر نفسه"
      ) {
        setInvestisseurDIrecteur("investisseur-directeur");
      } else {
        setInvestisseurDIrecteur("directeur");
      }

      setModal(id_affaire);
    } else {
      setIsLoading(false);
    }
  };

  // const determineNavigationPath = (record, form) => {
  //   const { affaire_id, affaire_id_contrat } = record;

  //   switch (affaire_id_contrat) {
  //     case import.meta.env
  //       .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO:
  //     case import.meta.env
  //       .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_SANS_BO:
  //     case import.meta.env
  //       .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_SANS_VALIDATION_SANS_BO:
  //       return `/etablissements/edit`;

  //     default:
  //       return "/etablissements/save";
  //   }
  // };

  const columns = [
    {
      title: t("Dénomination"),
      key: "dénomination",
      render: (_, record) =>
        record?.establishment_data_commercial_name ||
        record?.establishment_data_official_name ||
        record?.legal_entity_official_name,
    },
    {
      title: t("Directeur"),
      dataIndex: "directeur",
      key: "directeur",
      render: (_, record) =>
        record.establishment_director_data_last_name +
        " " +
        record.establishment_director_data_first_name,
    },
    {
      title: t("Gouvernorat"),
      key: "gouvernorat",
      render: (_, record) => record.establishment_data_governorate,
    },
    {
      title: t("Délégation"),
      dataIndex: "délégation",
      key: "délégation",
      render: (_, record) => record.establishment_data_delegation,
    },
    {
      title: t("Matricule fiscal"),
      key: "matricule",
      render: (_, record) =>
        record.establishment_data_tax_number ||
        record.legal_entity_tax_number ||
        "non renseigné",
    },
    {
      title: t("Numéro de téléphone"),
      key: "phoneNum",
      render: (_, record) => record.investor_phone_number,
    },
    {
      title: t("Nature de formation"),
      key: "natureForm",
      render: (_, record) => record.establishment_data_training_type,
    },
    {
      title: t("Statut"),
      key: "statut",
      render: (_, record) => {
        return <Tag color={record?.etat_background}>{record?.etat}</Tag>;
      },
    },
    {
      title: t("Actions"),
      key: "Actions",
      render: (_, record) => (
        <Space>
          <Tooltip title={t("Consulter")}>
            <EyeOutlined
              onClick={async () => {
                setIsLoading(true);
                const res = await getSchema(record.affaire_id);
                if (res?.response) {
                  setIsLoading(false);
                  const { form, data } = res?.response;
                  await setSurveyData(data);
                  await setSurveyJson(form);
                  if (sessionStorage.getItem("role") === "directeur") {
                    sessionStorage.setItem(
                      "user_id_to_get",

                      res?.response?.id_user
                    );
                  } else {
                    sessionStorage.setItem(
                      "user_id_to_get",
                      res?.response?.responsable_EPF
                    );
                  }

                  navigate("/etablissements/save", {
                    state: {
                      id_affaire: record.affaire_id,
                    },
                  });
                }
                setIsLoading(false);
              }}
            />
          </Tooltip>
          <Tooltip title={t("Modifier")}>
            <EditOutlined
              onClick={() => {
                demandeModifictaion(record?.affaire_id);

                // setModal()
              }}
            />
          </Tooltip>
          <Tooltip title={t("Télécharger: Déclaration / Décharge / BO")}>
            <CloudUploadOutlined
              onClick={async () => {
                setIsLoading(true);
                try {
                  const data = await getDocByIdAffaire(record?.affaire_id);
                  const documents = data?.data?.find(
                    (doc) =>
                      doc?.md5_id ===
                      import.meta.env.VITE_APP_ID_DOCUMENTS_RECU_EMAIL
                  );
                  const bo = data?.data?.find(
                    (doc) =>
                      doc?.md5_id === import.meta.env.VITE_APP_ID_DOCUMENTS_BO
                  );

                  if (documents?.files?.length) {
                    await Promise.all(
                      documents?.files.map((file) =>
                        downloadFile(file?.url, file?.name)
                      )
                    );
                  }
                  if (bo?.files?.length) {
                    await Promise.all(
                      bo?.files.map((file) =>
                        downloadFile(file?.url, file?.name)
                      )
                    );
                  }
                } catch (error) {
                  console.error("Erreur lors du téléchargement :", error);
                } finally {
                  setIsLoading(false);
                }
              }}
            />
          </Tooltip>

          <CommentOutlined
            onClick={() => handleGetComment(record?.affaire_id)}
          />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAffaires({
          id_user: JSON.parse(sessionStorage.getItem("-x-token-user")).id_user,
          id_entreprise: JSON.parse(sessionStorage.getItem("-x-token-user"))
            .user.entreprise_id,
          filters: {
            contrat: [
              import.meta.env.VITE_ID_LIGNE_PRODUIT,
              import.meta.env.VITE_ID_LIGNE_PRODUIT_EPF_EXISTANTE,
              import.meta.env.VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO,
              import.meta.env.VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_SANS_BO,
              import.meta.env.VITE_APP_LIGNE_PRODUIT_MODIFIACTION_SANS_VALIDATION_SANS_BO,
            ],
            status: [
              import.meta.env.VITE_APP_ID_STATUS_VALIDER_EPF,
              import.meta.env.VITE_APP_ID_STATUS_VALIDER_EPF_EXIST,
              import.meta.env.VITE_APP_ID_STATUS_SOUMISE_SANS_VALIDATION,
              import.meta.env.VITE_APP_STATUS_VALIDEE_MODIFICATION_AVEC_VALIDATION,
            ],
          },
        });

        setData(
          data?.aaData?.filter(
            (elem) =>
              elem?.affaire_id_contrat ===
                import.meta.env.VITE_ID_LIGNE_PRODUIT ||
              elem?.affaire_id_contrat ===
                import.meta.env.VITE_ID_LIGNE_PRODUIT_EPF_EXISTANTE || elem?.affaire_id_contrat ===
                import.meta.env.VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO || elem?.affaire_id_contrat ===
                import.meta.env.VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_SANS_BO || elem?.affaire_id_contrat ===
                import.meta.env.VITE_APP_LIGNE_PRODUIT_MODIFIACTION_SANS_VALIDATION_SANS_BO
          )
        );

        setLoading(false);
      } catch (error) {}
    }

    fetchData();

    if (idAffaire) {
      handleGetComment(idAffaire);
    }
  }, []);

  const [isModalVisible, setIsModalVisible] = useState();

  const openModalSelection = () => {
    sessionStorage.setItem("demande",false)
    setIsModalVisible(!isModalVisible);
  };

  const handleGetComment = (id_affaire) => {
    setIsLoading(true);
    axios
      .get(
        `${
          import.meta.env.VITE_APP_GEOPROD_API
        }/commentaire/get_investisseur_commentaires?entity=A&id_entity=${id_affaire}`,{
          headers: {
            Idsession: JSON.parse(
              sessionStorage.getItem("-x-token-user")
            )?.Acces_Token,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.info?.length > 0) {
          setCommentaire(res?.data?.info);
        } else {
          notification.warning({
            message: "Vous ne disposez pas de commentaire pour cette demande !",
          });
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  // const survey = new Model(surveyJson);

  return (
    <div>
      <CloseModal stopModal={stopModal} setStopModal={setStopModal} />
      <ModalModification
        modal={modal}
        setModal={setModal}
        role={investisseurDirecteur}
        // surveyData={surveyData}
      />
      <CommentModal commentaire={commentaire} setCommentaire={setCommentaire} />
      <SelectionModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
      {role !== "directeur" && (
        <Row className={classes.rowButtonCreate}>
          <Button
            className={classes.buttonCreate}
            style={{ marginRight: "1rem" }}
            onClick={openModalSelection}
          >
            <PlusOutlined />
            {t("EPF existant")}
          </Button>
          <Button
            className={classes.buttonCreate}
            onClick={() => {
              if (
                JSON.parse(sessionStorage.getItem("-x-token-user"))?.Acces_Token
              ) {
                setIsLoading(true);
                axios
                  .get(
                    `${
                      import.meta.env.VITE_APP_GEOPROD_API
                    }/admin-bpm/contrat/${
                      import.meta.env.VITE_ID_LIGNE_PRODUIT
                    }`,
                    {
                      headers: {
                        Idsession: JSON.parse(
                          sessionStorage.getItem("-x-token-user")
                        )?.Acces_Token,
                      },
                    }
                  )
                  .then((res) => {
                    setIsLoading(false);
                    setSurveyJson(res?.data?.contrat?.form);
                    navigate("/etablissements/save");
                  })
                  .catch((error) => {
                    setIsLoading(false);
                    notification.error({
                      message: t("Erreur"),
                      description:
                        "Une erreur a été rencontré lors de chargement de formulaire de l'epf existant",
                      duration: 0,
                    });
                  });
              } else {
                notification.error({
                  message: t("Erreur"),
                  description: "Veuillez connecter s'il vous plait.",
                  duration: 0,
                });
              }
            }}
          >
            <PlusOutlined />
            {t("Créer un nouvel EPF")}
          </Button>
        </Row>
      )}

      <Row className={classes.listeRow}>
        <Card
          className={classes.listeCard}
          bordered={false}
          title={
            <div>
              <div>{t("Liste de mes établissements")}</div>

              {data.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "14px",
                    marginTop: "10px",
                  }}
                >
                  <InfoCircleOutlined
                    style={{ color: "#1890ff", marginRight: "8px" }}
                  />
                  <span>
                    {t(
                      "Vous pouvez télécharger vos documents (Déclaration, Reçu bureau d'ordre, Bordereaux d'enregistrement) en utilisant l'icône de téléchargement devant chaque établissement."
                    )}
                  </span>
                </div>
              )}
            </div>
          }
        >
          <Table
            locale={{ emptyText: t("Aucune donnée") }}
            loading={loading}
            columns={columns}
            dataSource={data}
            className="ant-border-space"
            style={{ overflowX: "auto" }}
            pagination={false}
          />
        </Card>
      </Row>
    </div>
  );
};

export default Etablissements;
